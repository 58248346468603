import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import SuccessComplaintComponent from '../../../components/Content/Complaint/SuccessComplaintComponent';
import * as ActionComplaint from '../../../core/action/Complaint/ActionComplaint';

interface IProps {
    textComplaintSuccess: any;
    typeComplaint: any;
    actionComplaint: any;
}

class SuccessComplaintContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
  }
  handleBack() {
      if (this.props.typeComplaint === 'addComplaint') {
        this.props.actionComplaint.changeComplaintStep(0);
        this.props.actionComplaint.clearComplaintProcess();
        this.props.actionComplaint.clearSearchFormComplaint();
      } else {
        this.props.actionComplaint.changeComplaintStep(0);
        this.props.actionComplaint.clearFormDetailD();
        this.props.actionComplaint.clearFormUpdate();
        this.props.actionComplaint.clearComplaintProcess();
        this.props.actionComplaint.clearSearchFormComplaint();
        this.props.actionComplaint.clearComplaintForm();
      }
  }
  render() {
    return (
      <React.Fragment>
        <SuccessComplaintComponent handleBack={this.handleBack} textComplaintSuccess={this.props.textComplaintSuccess} />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
});
const mapDispatchToProps = (dispatch: any) => ({
  actionComplaint: bindActionCreators(ActionComplaint, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SuccessComplaintContainer);
