const EnumHeaderTrackingDelivery = (i: any) => {
  if (i.itemStatus === 'Uploaded') {
    return 'Upload Data CSV';
  } else if (i.itemStatus === 'ScanBarcode') {
    return 'Data Berhasil Di Scan';
  } else if (i.itemStatus === 'WaybillPrinted') {
    return 'Manifest Tercetak';
  } else if (i.itemStatus === 'ScanBranch') {
    return 'Paket Sampai Di Posko';
  } else if (i.itemStatus === 'ScanCourier') {
    return 'Paket Di Scan Sesuai Kurir';
  } else if (i.itemStatus === 'DownloadCourier') {
    return (
      'Kurir Mendownload Data Pengiriman' +
      (i.position > 1 ? ' (ke-' + i.position + ')' : '')
    );
  } else if (i.itemStatus === 'CourierDelivering') {
    return (
      'Pengiriman Oleh Kurir' +
      (i.position > 1 ? ' (ke-' + i.position + ')' : '')
    );
  } else if (i.itemStatus === 'WrongRoute') {
    return 'Kesalahan Wilayah Pengiriman';
  } else if (i.itemStatus === 'Received' || i.itemStatus === 'UpdatedManual') {
    if (i.deliveryStatus === 'Return' || i.deliveryStatus === 'OnProcces') {
      return 'Paket Dikembalikan Ke Posko';
    } else {
      return (
        'Paket Telah Sampai Di Tujuan' +
        (i.position > 1 ? ' (ke-' + i.position + ')' : '')
      );
    }
  } else if (i.itemStatus === 'Resend') {
    return (
      'Kirim Ulang Paket' + (i.position > 1 ? ' (ke-' + i.position + ')' : '')
    );
  }
};
const EnumContenTrackingDelivery = (itemStatus: any, isPartner: any) => {
  if (itemStatus.itemStatus === 'Uploaded') {
    return 'Data di upload oleh admin';
  } else if (itemStatus.itemStatus === 'ScanBarcode') {
    return 'Data di scan oleh admin';
  } else if (itemStatus.itemStatus === 'WaybillPrinted') {
    return 'Manifest dicetak oleh admin';
  } else if (itemStatus.itemStatus === 'ScanBranch') {
    return 'Paket telah di scan di posko ' + itemStatus.branchName;
  } else if (itemStatus.itemStatus === 'ScanCourier') {
    if (isPartner === true) {
      return 'Paket dikirim oleh RDS Express';
    } else {
      return (
        'Paket dikirim oleh' +
        ' ' +
        itemStatus.courierName +
        ' ' +
        '(' +
        itemStatus.courierCode +
        ')'
      );
    }
  } else if (itemStatus.itemStatus === 'DownloadCourier') {
    if (isPartner === true) {
      return 'Data telah di download oleh RDS Express';
    } else {
      return (
        'Data telah di download oleh' +
        ' ' +
        itemStatus.courierName +
        ' ' +
        '(' +
        itemStatus.courierCode +
        ')'
      );
    }
  } else if (itemStatus.itemStatus === 'CourierDelivering') {
    if (isPartner === true) {
      return 'Paket sedang dikirim oleh RDS Express';
    } else {
      return (
        'Paket sedang dikirim oleh' +
        ' ' +
        itemStatus.courierName +
        ' ' +
        '(' +
        itemStatus.courierCode +
        ')'
      );
    }
  } else if (itemStatus.itemStatus === 'WrongRoute') {
    return (
      'Paket yang dikirim tidak sesuai dengan wilayah posko' +
      ' ' +
      itemStatus.branchName
    );
  } else if (
    itemStatus.itemStatus === 'Received' ||
    itemStatus.itemStatus === 'UpdatedManual'
  ) {
    if (itemStatus.deliveryStatus === 'Success') {
      return (
        'Paket diterima oleh' +
        ' ' +
        itemStatus.receiverName +
        ' ' +
        '(' +
        itemStatus.receiverTitle +
        ')'
      );
    } else if (
      itemStatus.deliveryStatus === 'OnProcces' ||
      itemStatus.deliveryStatus === 'Return' ||
      itemStatus.deliveryStatus === 'Return3x'
    ) {
      return 'Paket dikembalikan karena ' + itemStatus.returnedReason;
    }
  } else if (itemStatus.itemStatus === 'Resend') {
    if (isPartner === true) {
      return (
        'Paket dikirim ulang oleh RDS Express' +
        ' pengiriman yang ke-' +
        itemStatus.position
      );
    } else {
      return (
        'Paket dikirim ulang oleh' +
        ' ' +
        itemStatus.courierName +
        ' ' +
        '(' +
        itemStatus.courierCode +
        ')' +
        ',' +
        ' ' +
        'pengiriman yang ke-' +
        itemStatus.position
      );
    }
  }
};
export { EnumHeaderTrackingDelivery, EnumContenTrackingDelivery };
