export function changeFirstDate(firstDate:any)
{
    return({
        type:"CHANGE_FIRST_DATE_COMPLAINT_FORM",
        firstDate
    })
}
export function changeLastDate(lastDate:any)
{
    return({
        type:"CHANGE_LAST_DATE_COMPLAINT_FORM",
        lastDate
    })
}
export function changeTypeStatus(typeStatus:any)
{
    return({
        type:"CHANGE_TYPE_STATUS_COMPLAINT_FORM",
        typeStatus
    })
}
export function changeFilterSearch(complaintFilterSearch:any)
{
    return({
        type:"CHANGE_FILTER_SEARCH_COMPLAINT_FORM",
        complaintFilterSearch
    })
}
export function changeSearchValue(searchValue:any)
{
    return({
        type:"CHANGE_SEARCH_VALUE_COMPLAINT_FORM",
        searchValue
    })
}
export function setComplaintList(complaintList:any)
{
    return({
        type:"SET_COMPLAINT_LIST",
        complaintList
    })
}
export function changeComplaintProcess(complaintProcessType:any)
{
    return({
        type:"CHANGE_COMPLAINT_PROCESS_TYPE_FORM",
        complaintProcessType
    })
}
export function changeComplaintStep(complaintStep:any)
{
    return({
        type:"CHANGE_COMPLAINT_STEP",
        complaintStep
    })
}
export function setDocumentList(documentList:any)
{
    return({
        type:"SET_DOCUMENT_LIST",
        documentList
    })
}
export function changeAddFilterSearch(filterSearch:any)
{
    return({
        type:"CHANGE_ADD_FILTER_SEARCH",
        filterSearch
    })
}
export function changeAddSearchValue(searchValue:any)
{
    return({
        type:"CHANGE_ADD_SEARCH_VALUE",
        searchValue
    })
}
export function changeBarcode(barcode:any)
{
    return({
        type:"CHANGE_BARCODE_COMPLAINT",
        barcode
    })
}
export function changeCycleDate(cycleDate:any)
{
    return({
        type:"CHANGE_CYCLE_DATE_COMPLAINT",
        cycleDate
    })
}
export function changeDetailDocument(detailDocument:any)
{
    return({
        type:"CHANGE_DETAIL_DOCUMENT_COMPLAINT",
        detailDocument
    })
}
export function changePhoneNumber(phoneNumber:any)
{
    return({
        type:"CHANGE_PHONE_NUMBER_COMPLAINT",
        phoneNumber
    })
}
export function changeComplaintTitle(complaintTitle:any)
{
    return({
        type:"CHANGE_TITLE_COMPLAINT",
        complaintTitle
    })
}
export function changeCustDetail(complaintCustDetail:any)
{
    return({
        type:"CHANGE_CUST_DETAIL_COMPLAINT",
        complaintCustDetail
    })
}
export function getDocumentList()
{
    return({
        type:"GET_DOCUMENT_LIST"
    })
}
export function clearComplaintProcess()
{
    return({
        type:"CLEAR_COMPLAINT_PROCESS"
    })
}
export function clearComplaintStep()
{
    return({
        type:"CLEAR_COMPLAINT_STEP"
    })
}
export function clearSearchValueComplaintForm()
{
    return({
        type:"CLEAR_SEARCH_VALUE_COMPLAINT"
    })
}
export function clearAllComplaintForm()
{
    return({
        type:"CLEAR_ALL_COMPLAINT_FORM"
    })
}
export function clearComplaintForm()
{
    return({
        type:"CLEAR_COMPLAINT_FORM"
    })
}
export function clearSearchComplaintDU()
{
    return({
        type:"CLEAR_SEARCH_COMPLAINT_DU"
    })
}
export function clearDetailDocumentComplaint()
{
    return({
        type:"CLEAR_DETAIL_DOCUMENT_COMPLAINT"
    })
}
export function clearListComplaint()
{
    return({
        type:"CLEAR_COMPLAINT_LIST"
    })
}
export function clearDocumentListComplaint()
{
    return({
        type:"CLEAR_DOCUMENT_LIST_COMPLAINT"
    })
}
export function clearSearchFormComplaint()
{
    return({
        type:"CLEAR_SEARCH_FORM_COMPLAINT"
    })
}
export function clearAddFormComplaint()
{
    return({
        type:"CLEAR_ADD_FORM_COMPLAINT"
    })
}
export function submitSearchComplaint()
{
    return({
        type:"SUBMIT_SEARCH_COMPLAINT"
    })
}
export function createComplaint()
{
    return({
        type:"CREATE_COMPLAINT"
    })
}
export function submitSearchComplaintWbOrTk()
{
    return({
        type:"SUBMIT_SEARCH_WB_OR_TK_COMPLAINT"
    })
}

// --- action detail --- //
export function changeDetailD(detail:any)
{
    return({
        type:"CHANGE_DETAIL_D",
        detail
    })
}
export function changeCodeTicketD(codeTicket:any)
{
    return({
        type:"CHANGE_CODE_TICKET_D",
        codeTicket
    })
}
export function changeStatusD(status:any)
{
    return({
        type:"CHANGE_STATUS_D",
        status
    })
}
export function changePhoneNumberD(phoneNumber:any)
{
    return({
        type:"CHANGE_PHONE_NUMBER_D",
        phoneNumber
    })
}
export function changeTitleComplaintD(titleComplaint:any)
{
    return({
        type:"CHANGE_TITLE_COMPLAINT_D",
        titleComplaint
    })
}
export function changeHistoryD(history:any)
{
    return({
        type:"CHANGE_HISTORY_D",
        history
    })
}
export function changeImageHistory(image:any)
{
    return({
        type:"CHANGE_IMAGE_HISTORY",
        image
    })
}
export function submitDetailComplaint()
{
    return({
        type:"SUBMIT_DETAIL_COMPLAINT"
    })
}
export function clearFormDetailD()
{
    return({
        type:"CLEAR_FORM_DETAIL_D"
    }) 
}

// --- action update --- //
export function changeModeU(mode:any)
{
    return({
        type:"CHANGE_MODE_U",
        mode
    })
}
export function changeDescU(description:any)
{
    return({
        type:"CHANGE_DESC_U",
        description
    })
}
export function changeFileImageU(fileImage:any)
{
    return({
        type:"CHANGE_FILE_IMAGE_U",
        fileImage
    })
}
export function submitUpdateComplaint()
{
    return({
        type:"SUBMIT_UPDATE_COMPLAINT"
    })
}
export function clearFormUpdate()
{
    return({
        type:"CLEAR_FORM_UPDATE"
    }) 
}
export function changeCountingFirebase(countingFirebase:any)
{
    return({
        type:"CHANGE_COUNTING_FIREBASE",
        countingFirebase,
    })
}
