import { CheckLogin, CheckToken } from "../core/middleware/AuthMiddleware";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import BerandaComponent from "../containers/Content/SearchDocument/SearchDocumentContainer";
import ComplaintContainer from "../containers/Content/Complaint/MainComplaintContainer";
import ParentHocContainer from "../containers/Login/ParentHocLoginContainer";
import ReportComponent from "../containers/Content/Report/ReportContainer";
import WaybillDigital from "../containers/Content/WaybillDigital";
import { TemplateDashboardComponent } from "../core/template/TemplateComposed";
import { compose } from "redux";
import { connect } from "react-redux";
import pagenotfound from "../containers/NoteFound/index";

interface IProps {
  // getAccessHeader:any
}
class Navigation extends Component<IProps> {
  protected beranda: any;
  protected report: any;
  protected complaint: any;
  protected waybillDigital: any;
  protected pagenotfound: any;
  protected ParentHocContainer: any;

  render() {
    this.beranda = CheckToken(TemplateDashboardComponent(BerandaComponent));
    this.report = CheckToken(TemplateDashboardComponent(ReportComponent));
    this.complaint = CheckToken(TemplateDashboardComponent(ComplaintContainer));
    this.waybillDigital = CheckToken(
      TemplateDashboardComponent(WaybillDigital)
    );
    this.pagenotfound = CheckToken(TemplateDashboardComponent(pagenotfound));
    this.ParentHocContainer = CheckLogin(ParentHocContainer);
    // this.pagenotfound = pagenotfound;

    return (
      <Switch>
        <Route exact={true} path="/" component={this.beranda} />
        <Route exact={true} path="/report" component={this.report} />
        <Route exact={true} path="/complaint" component={this.complaint} />
        <Route
          exact={true}
          path="/waybill-digital"
          component={this.waybillDigital}
        />
        <Route exact={true} path="/login" component={this.ParentHocContainer} />
        <Route exact={true} path="/notfound" component={this.pagenotfound} />
      </Switch>
    );
  }
}
const mapStateToProps = (state: any) => ({
  // getAccessHeader: state.Auth.AccessHeader,
});
const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Navigation);
