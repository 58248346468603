import { Action } from 'redux';

const initialState = {
  propertiesSearchResult: {
    WorBSearch: null,
    searchResult: null,
    numberType: null,
    image: null,
    signature: null,
  },
  searchForm: {
    searchType: 'waybill',
    searchValue: null,
    Waybill: null,
    Barcode: null,
  },
  listResultSearch: [],
};

export interface IAuthAction extends Action<string> {}

export interface IChangeSearchType extends IAuthAction {
  searchType: any;
}

export interface IChangeSearchValue extends IAuthAction {
  searchValue: any;
}

export interface IChangeWaybill extends IAuthAction {
  Waybill: any;
}
export interface ISetWorBSearch extends IAuthAction {
  WorBSearch: any;
}

export interface ISetSearchResult extends IAuthAction {
  searchResult: any;
}

export interface ISetNumberType extends IAuthAction {
  numberType: any;
}

export interface ISetImageResult extends IAuthAction {
  image: any;
}

export interface ISetSignatureResult extends IAuthAction {
  signature: any;
}

export interface IChangeBarcode extends IAuthAction {
  Barcode: any;
}

export interface ISetListResultSearch extends IAuthAction {
  listResultSearch: any;
}

export default function AuthReducerLoginForm(
  state = initialState,
  action: IAuthAction
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'SET_SEARCH_RESULT':
      const setSearchResult = action as ISetSearchResult;
      newState.propertiesSearchResult.searchResult =
        setSearchResult.searchResult;

      return { ...newState };

    case 'SET_WORB_SEARCH':
      const setWorBSearch = action as ISetWorBSearch;
      newState.propertiesSearchResult.WorBSearch = setWorBSearch.WorBSearch;

      return { ...newState };

    case 'SET_NUMBER_TYPE':
      const setNumberType = action as ISetNumberType;
      newState.propertiesSearchResult.numberType = setNumberType.numberType;

      return { ...newState };

    case 'SET_IMAGE_RESULT':
      const setImageResult = action as ISetImageResult;
      newState.propertiesSearchResult.image = setImageResult.image;

      return { ...newState };

    case 'SET_SIGNATURE_RESULT':
      const setSignatureResult = action as ISetSignatureResult;
      newState.propertiesSearchResult.signature = setSignatureResult.signature;

      return { ...newState };

    case 'CHANGE_SEARCH_TYPE_FORM':
      const changeSearchType = action as IChangeSearchType;
      newState.searchForm.searchType = changeSearchType.searchType;

      return { ...newState };

    case 'CHANGE_SEARCH_VALUE_FORM':
      const changeSearchValue = action as IChangeSearchValue;
      newState.searchForm.searchValue = changeSearchValue.searchValue;

      return { ...newState };

    case 'CHANGE_WAYBILL_FORM':
      const changeWaybill = action as IChangeWaybill;
      newState.searchForm.Waybill = changeWaybill.Waybill;

      return { ...newState };

    case 'CHANGE_BARCODE_FORM':
      const changeBarcode = action as IChangeBarcode;
      newState.searchForm.Barcode = changeBarcode.Barcode;

      return { ...newState };

    case 'SET_LIST_RESULT_SEARCH':
      const setListResultSearch = action as ISetListResultSearch;
      newState.listResultSearch = setListResultSearch.listResultSearch;
      return { ...newState };

    case 'CLEAR_PROPERTIES_SEARCH_RESULT':
      newState.propertiesSearchResult.searchResult = null;
      newState.propertiesSearchResult.WorBSearch = null;
      newState.propertiesSearchResult.numberType = null;
      newState.propertiesSearchResult.image = null;
      newState.propertiesSearchResult.signature = null;

      return { ...newState };

    case 'CLEAR_SEARCH_TYPE_FORM':
      newState.searchForm.searchType = 'waybill';

      return { ...newState };

    case 'CLEAR_SEARCH_VALUE_FORM':
      newState.searchForm.searchValue = null;

      return { ...newState };

    case 'CLEAR_WAYBILL_FORM':
      newState.searchForm.Waybill = null;

      return { ...newState };

    case 'CLEAR_BARCODE_FORM':
      newState.searchForm.Barcode = null;

      return { ...newState };

    case 'CLEAR_LIST_RESULT_SEARCH':
      newState.listResultSearch = [];

      return { ...newState };
  }
  return state;
}
