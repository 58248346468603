import * as LoginAction from '../../core/action/Login';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import LoginComponent from '../../components/Login/LoginComponent';
import { connect } from 'react-redux';

interface IProps {
  authLoginAction: any;
  getUserName: any;
  getPassword: any;
  // isLoading:any
}
interface IState {
  validateUsername: any;
  validatePassword: any;
}
class LoginContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      validateUsername: null,
      validatePassword: null,
    };
    this.handlePutUsername = this.handlePutUsername.bind(this);
    this.handlePutPassword = this.handlePutPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handlePutUsername(event: any) {
    this.props.authLoginAction.changeUsername(event.target.value);
    if (event.target.value === '') {
      this.setState({
        validateUsername: 'error',
      });
    } else if (event.target.value.length < 7) {
      this.setState({
        validateUsername: 'notMinChar',
      });
    } else {
      this.setState({
        validateUsername: null,
      });
    }
  }
  handlePutPassword(event: any) {
    this.props.authLoginAction.changePassword(event.target.value);
    if (event.target.value === '') {
      this.setState({
        validatePassword: 'error',
      });
    } else {
      this.setState({
        validatePassword: null,
      });
    }
  }
  handleSubmit() {
    this.props.authLoginAction.submitLogin();
  }
  componentDidMount() {
    localStorage.clear();
  }
  render() {
    return (
      <div>
        <LoginComponent
          handlePutUsername={this.handlePutUsername}
          validateUsername={
            this.state.validateUsername === 'error' ||
            this.state.validateUsername === 'notMinChar'
              ? 'error'
              : ''
          }
          helpUsername={
            this.state.validateUsername === 'error'
              ? 'Nama Pengguna Tidak Boleh Kosong!'
              : this.state.validateUsername === 'notMinChar'
              ? 'Nama Pengguna tidak boleh kurang dari 7 karakter !'
              : ''
          }
          handlePutPassword={this.handlePutPassword}
          validatePassword={
            this.state.validatePassword === 'error' ? 'error' : ''
          }
          helpPassword={
            this.state.validatePassword === 'error'
              ? 'Kata sandi Tidak Boleh Kosong!'
              : ''
          }
          handleSubmit={this.handleSubmit}
          getUserName={this.props.getUserName}
          getPassword={this.props.getPassword}
          validateButton={
            this.props.getUserName === '' ||
            this.props.getUserName === null ||
            this.state.validateUsername === 'notMinChar' ||
            this.props.getPassword === '' ||
            this.props.getPassword === null
              ? true
              : false
          }
          // isLoading={this.props.isLoading}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  getUserName: state.Login.loginForm.Username,
  getPassword: state.Login.loginForm.Password,
  // isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  authLoginAction: bindActionCreators(LoginAction, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(LoginContainer);
