import * as ActionLogin from '../../action/Login';
import * as actionComplaint from '../../action/Complaint/ActionComplaint';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../ErrorMessage';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  ref,
  update,
} from 'firebase/database';
import { getDb } from '../../config/FirebaseConfig';

export function* S_getDocumentList() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  
  const filterSearch = state.Complaint.addComplaintState.filterSearch;
  const searchValue = state.Complaint.addComplaintState.searchValue;
  const cycleDate = moment(state.Complaint.addComplaintState.cycleDate)
    .add(7, 'h')
    .toDate();
  const convCycleDate = moment(cycleDate).format('YYYYMMDD');
  const getCustomerId = state.Auth.CustomerId;
  const getUsername = state.Auth.UsernameAuth;
  
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Portal/SelectDataForAddComplaintDeliveryNew/0/1000?Cycle=${convCycleDate}&Value=${searchValue}&CustomerId=${getCustomerId}&Username=${getUsername}&FilterSearchComplaint=${filterSearch}`,
      { headers: config }
    );
    yield put(actionComplaint.setDocumentList(data.data));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefresh());
      yield put(toggle.changeLoadingFalse());
    } else if (error.response.data.Message) {
      Modal.error({
        title: <p className="failedTextNoitf">Gagal mencari dokumen</p>,
        content: (
          <div>
            <p className="contentTextNotif">Data yang dicari tidak ditemukan</p>
          </div>
        ),
        onOk() {},
      });
      yield put(toggle.changeLoadingFalse());
      yield put(actionComplaint.clearDocumentListComplaint());
    } else {
      yield put(toggle.changeLoadingFalse());
      yield put(actionComplaint.clearDocumentListComplaint());
      const propsError = ErrorMassage('Hasil Pencarian Tiket Keluhan');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* S_createComplaint() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const phoneNumber = state.Complaint.addComplaintState.phoneNumber;
  const detailDoc = state.Complaint.addComplaintState.detailDocument;
  const compalintTitle = state.Complaint.addComplaintState.complaintTitle;
  const getUsername = state.Auth.UsernameAuth;
  const complaintCustDetail =
    state.Complaint.addComplaintState.complaintCustDetail;
  const DateNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss'); 
  const waybill = detailDoc.waybillCode;

  try {
    yield put(toggle.changeLoadingTrue());
    
    yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Portal/AddInvestigasiNew`,
      {
        waybill: detailDoc.waybillCode,
        itemComplainProcess: DateNow,
        noTelp: phoneNumber,
        question: compalintTitle,
        questionDetail: complaintCustDetail,
        userCreate: getUsername,
      },
      { headers: config }
    );

    const postData = {
      actor: getUsername + `(${DateNow})`,
    };
    const newPostKey = (ref(getDb), `${process.env.REACT_APP_FIREBASE_PARENT}/${waybill}`);
    const updates:any = {};
    updates[newPostKey] = postData;
    update(ref(getDb), updates);

    yield put(actionComplaint.changeComplaintStep(2));
    yield put(actionComplaint.clearAddFormComplaint());
    yield put(actionComplaint.clearDetailDocumentComplaint());
    yield put(actionComplaint.clearComplaintForm());
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefresh());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            Gagal Membuat Keluhan
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {error?.response?.data?.Message}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_getDocumentList() {
  yield takeLatest('GET_DOCUMENT_LIST', S_getDocumentList);
}
export function* SM_createComplaint() {
  yield takeLatest('CREATE_COMPLAINT', S_createComplaint);
}
