import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import WaybillDigitalComponent from "../../../components/Content/WaybillDigital";

type WaybillDigitalProps = {
  GetUsername: any;
};

function WaybillDigital({ GetUsername }: WaybillDigitalProps) {
  return <WaybillDigitalComponent GetUsername={GetUsername} />;
}

const mapStateToProps = (state: any) => ({
  GetUsername: state.Auth.UsernameAuth,
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(WaybillDigital);
