import {
  // Form,
  // Input,
  Row } from 'antd';
// import {
//   UploadOutlined,
// } from '@ant-design/icons';
import React, { Component } from 'react';
import ButtonComponent from '../../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';

interface IProps {
  getDescription: any;
  getFileImage: any;
  ValidateDescription: any;
  ValidationButton: any;
  helpValidateDescription: any;
  ValidateFileImage: any;
  helpValidateFileImage: any;
  handlePutDescription: any;
  handlePutFileImage: any;
  handleUpdateComplaint: any;
}

export default class UpdateFormComponent extends Component<IProps> {
  render() {
    return (
      <div className={'spacingCardComplaint'}>
        <div className="complaintDescUpdateForm">
          <span>
            <b>Keterangan - Investigasi Ulang atau Selesai</b>
          </span>
          <br />
          <FormInputTextMaterial
            validateType={true}
            value={this.props.getDescription}
            className={''}
            disabled={false}
            addonBefore={''}
            onPressEnter={''}
            placeholder={'Masukan detail keluhan'}
            prefix={''}
            maxLength={400}
            style={{}}
            size=""
            type={'textArea'}
            onChange={this.props.handlePutDescription}
            onBlur={this.props.handlePutDescription}
            validatestatus={this.props.ValidateDescription}
            help={this.props.helpValidateDescription}
          />
        </div>
        {/* <div className="inputFileComplaint">
          <span>
            <b>Unggah Gambar</b>
          </span>
          <br />
          <Form.Item
            validateStatus={this.props.ValidateFileImage}
            help={this.props.helpValidateFileImage}
          >
            <Input
              onChange={this.props.handlePutFileImage}
              value={this.props.getFileImage}
              multiple={true}
              type="file"
              suffix={<UploadOutlined />}
              accept="image/png, image/jpg, image/jpeg"
            />
          </Form.Item>
        </div> */}
        <div className="btnGroupUpdate">
          <Row>
            <ButtonComponent
                disabled={this.props.ValidationButton}
                onClick={() => this.props.handleUpdateComplaint(0)}
                type={'primary'}
                className={'btnFeedbackComplaint'}
              >
                <b>Investigasi Ulang</b>
              </ButtonComponent>
              <ButtonComponent
                disabled={this.props.ValidationButton}
                onClick={() => this.props.handleUpdateComplaint(1)}
                type={'primary'}
                className={'btnDoneComplaint'}
              >
                <b>Selesai</b>
              </ButtonComponent>
          </Row>
        </div>
      </div>
    );
  }
}
