export function changeSearchType(searchType: any) {
  return {
    type: 'CHANGE_SEARCH_TYPE_FORM',
    searchType,
  };
}

export function changeSearchValue(searchValue: any) {
  return {
    type: 'CHANGE_SEARCH_VALUE_FORM',
    searchValue,
  };
}

export function changeWaybill(Waybill: any) {
  return {
    type: 'CHANGE_WAYBILL_FORM',
    Waybill,
  };
}

export function changeBarcode(Barcode: any) {
  return {
    type: 'CHANGE_BARCODE_FORM',
    Barcode,
  };
}

export function clearSearchType() {
  return {
    type: 'CLEAR_SEARCH_TYPE_FORM',
  };
}

export function clearSearchValue() {
  return {
    type: 'CLEAR_SEARCH_VALUE_FORM',
  };
}

export function clearWaybill() {
  return {
    type: 'CLEAR_WAYBILL_FORM',
  };
}

export function clearBarcode() {
  return {
    type: 'CLEAR_BARCODE_FORM',
  };
}

export function submitSearchDocument() {
  return {
    type: 'SEARCH_DOCUMENT',
  };
}
