import { Col, DatePicker, Form, Row, Select, Table } from "antd";
import { EyeTwoTone, EditTwoTone } from "@ant-design/icons";
import React, { Component } from "react";
import ButtonComponent from "../../../materials/ButtonMaterial";
import CardMaterial from "../../../materials/CardMaterial";
import FormInputTextMaterial from "../../../materials/FormInputTextMaterial";
import IndexComplaintContainer from "../../../containers/Content/Complaint/AddComplaint/IndexComplaintContainer";
import IndexDetailContainer from "../../../containers/Content/Complaint/DetailComplaint/IndexDetailContainer";
import IndexUpdateContainer from "../../../containers/Content/Complaint/UpdateComplaint/IndexUpdateContainer";
import SpinMaterial from "../../../materials/SpinMaterial";
import locale from "antd/es/date-picker/locale/id_ID";
import moment from "moment";
import { complaintStatusObj, getObjectKey } from "../../../lib/utils";

interface IProps {
  getComplaintProcess: any;
  getFirstDate: any;
  getLastDate: any;
  getTypeStatus: any;
  getComplaintFilterSearch: any;
  getSearchValue: any;
  getComplaintList: any;
  getValueFirstDate: any;
  getValueLastDate: any;
  isLoading: any;
  page: any;
  // validateFirstDate: any;
  // helpFirstDate: any;
  validateBtnSubmit: any;
  handlePage: any;
  handleChangeFirstDate: any;
  handleValidateStartDate: any;
  handleChangeLastDate: any;
  handleValidateLastDate: any;
  handleChangeTypeStatus: any;
  handleChangeFilterSearch: any;
  handleChangeSearchValue: any;
  handleChangeSubmit: any;
  handleAddComplaint: any;
  handleDetailComplaint: any;
  handleUpdateComplaint: any;
}

const { Option } = Select;

export default class MainComplaintComponent extends Component<IProps> {
  render() {
    const firstDateV = this.props.getFirstDate;
    const validateDate = this.props.getFirstDate === null ? true : false;
    const formatDate = "YYYY-MM-DD";
    const formatDateShow = "DD-MM-YYYY";
    const minDate = (d: any) =>
      !d || d.isAfter(moment(new Date()).add(1, "day").format(formatDate));
    const minDate2 = (d: any) =>
      !d ||
      d.isBefore(
        moment(this.props.getFirstDate).add(0, "day").format(formatDate)
      ) ||
      d.isAfter(
        moment(this.props.getFirstDate).add(30, "day").format(formatDate)
      );
    const prefixSelector = (
      <Select
        defaultValue="No Polis"
        onChange={this.props.handleChangeFilterSearch}
        value={
          this.props.getComplaintFilterSearch === "NoPolis"
            ? "No Polis"
            : this.props.getComplaintFilterSearch
        }
      >
        <Option value="NoPolis">No Polis</Option>
        <Option value="Barcode">Barcode</Option>
        <Option value="Waybill">Waybill</Option>
        <Option value="Ticket">Nomor Tiket</Option>
        <Option value="NoReferensi">Nomor Referensi</Option>
        <Option value="NoRekening">Nomor Rekening</Option>
      </Select>
    );

    const showStatus = (status: any) => {
      // @ts-ignore
      return complaintStatusObj[status] || "";
    };

    const looppage = (this.props.page - 1) * 10 + 1;
    const columns = [
      {
        title: <b>No</b>,
        align: "center" as "center",
        width: 50,
        render: (text: any, record: any, index: any) => (
          <span>{this.props.page > 1 ? index + looppage : index + 1}</span>
        ),
        key: "no",
      },
      {
        title: <b>Tiket</b>,
        align: "center" as "center",
        dataIndex: "tiket",
        key: "tiket",
        render: (tiket: any) => <span style={{ color: "red" }}>{tiket}</span>,
        width: 80,
      },
      {
        title: <b>Pembuat</b>,
        align: "center" as "center",
        dataIndex: "userCreate",
        key: "userCreate",
        width: 80,
      },
      {
        title: <b>Tanggal Pengajuan</b>,
        align: "center" as "center",
        dataIndex: "itemComplainProcess",
        key: "itemComplainProcess",
        render: (itemComplainProcess: any) =>
          itemComplainProcess === null ? (
            "-"
          ) : (
            <span>{moment(itemComplainProcess).format("YYYY-MM-DD")}</span>
          ),
        width: 120,
      },
      {
        title: <b>Barcode</b>,
        align: "center" as "center",
        dataIndex: "barcode",
        key: "barcode",
        width: 80,
      },
      // {
      //   title: <b>No Polis</b>,
      //   align: 'center' as 'center',
      //   dataIndex: 'noPolis',
      //   key: 'noPolis',
      //   width: 80,
      // },
      {
        title: <b>Waybill</b>,
        align: "center" as "center",
        dataIndex: "waybillCode",
        key: "waybillCode",
        width: 80,
      },
      {
        title: <b>Subjek Keluhan</b>,
        align: "center" as "center",
        dataIndex: "question",
        key: "question",
        width: 150,
      },
      {
        title: <b>Status</b>,
        align: "center" as "center",
        dataIndex: "stats",
        key: "stats",
        render: (stats: any) => showStatus(stats),
        width: 80,
      },

      {
        title: <b>Aksi</b>,
        key: "pilih",
        width: 100,
        align: "center" as "center",
        render: (record: any) => (
          <div>
            <Row>
              <Col span={12}>
                <ButtonComponent
                  className="btnActionDetailComplaint"
                  type="default"
                  icon={<EyeTwoTone />}
                  onClick={() => this.props.handleDetailComplaint(record)}
                />
              </Col>
              <Col span={12}>
                <ButtonComponent
                  type="default"
                  className="btnActionEditComplaint"
                  disabled={
                    record.stats ===
                    getObjectKey(
                      complaintStatusObj,
                      complaintStatusObj.KonfirmasiPelanggan
                    )
                      ? false
                      : true
                  }
                  icon={
                    <EditTwoTone
                      twoToneColor={
                        record.stats ===
                        getObjectKey(
                          complaintStatusObj,
                          complaintStatusObj.KonfirmasiPelanggan
                        )
                          ? "#FCC642"
                          : "#b2b2b2"
                      }
                    />
                  }
                  onClick={() => this.props.handleUpdateComplaint(record)}
                />
              </Col>
            </Row>
          </div>
        ),
      },
    ];
    return (
      <React.Fragment>
        {this.props.getComplaintProcess === "addComplaint" && (
          <IndexComplaintContainer />
        )}
        {this.props.getComplaintProcess === "detailComplaint" && (
          <IndexDetailContainer />
        )}
        {this.props.getComplaintProcess === "updateComplaint" && (
          <IndexUpdateContainer />
        )}

        {this.props.getComplaintProcess === null && (
          <SpinMaterial spinning={this.props.isLoading} size={"large"}>
            <div className="centerContent">
              <span className="titleCard">KELUHAN PELANGGAN</span>
              <br />
              <Row>
                <Col span={12}>
                  <div className="firstDateComplaint">
                    <span className="floatLeft">
                      <b>Mulai Tanggal</b>
                    </span>
                    <br />
                    <Form.Item>
                      <DatePicker
                        className="datepickerDefault"
                        locale={locale}
                        format={formatDateShow}
                        placeholder={"Pilih tanggal"}
                        onOpenChange={this.props.handleValidateStartDate}
                        value={this.props.getValueFirstDate}
                        onChange={this.props.handleChangeFirstDate}
                        disabledDate={minDate}
                        disabled={
                          this.props.getComplaintFilterSearch === "Ticket" ||
                          this.props.getComplaintFilterSearch === "Waybill"
                            ? true
                            : false
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="btnAddComplaintDiv">
                    <ButtonComponent
                      disabled={false}
                      onClick={this.props.handleAddComplaint}
                      shape="round"
                      size={"middle"}
                      className={"btnAddComplaint"}
                    >
                      Tambah Keluhan Baru
                    </ButtonComponent>
                  </div>

                  <div className="lastDateComplaint">
                    <span className="floatLeft">
                      <b>Sampai Tanggal</b>
                    </span>
                    <br />
                    <Form.Item>
                      <DatePicker
                        className={"datepickerDefault"}
                        locale={locale}
                        format={formatDateShow}
                        placeholder={"Pilih tanggal"}
                        defaultPickerValue={moment(firstDateV, formatDate)}
                        onOpenChange={this.props.handleValidateLastDate}
                        value={this.props.getValueLastDate}
                        onChange={this.props.handleChangeLastDate}
                        disabledDate={minDate2}
                        disabled={
                          this.props.getComplaintFilterSearch === "Ticket" ||
                          this.props.getComplaintFilterSearch === "Waybill"
                            ? true
                            : validateDate
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="statusType">
              <span className="floatLeft">
                <b>Status Keluhan Pelanggan</b>
              </span>
              <br />
              <Form.Item>
                <Select
                  loading={false}
                  value={this.props.getTypeStatus}
                  disabled={
                    this.props.getComplaintFilterSearch === "Ticket" ||
                    this.props.getComplaintFilterSearch === "Waybill"
                      ? true
                      : false
                  }
                  placeholder="Pilih Status Keluhan Pelanggan"
                  showSearch
                  onChange={this.props.handleChangeTypeStatus}
                >
                  <Option key={""} value={"Semua"}>
                    Semua
                  </Option>
                  <Option key={""} value={"Proses"}>
                    Proses
                  </Option>
                  <Option key={""} value={"DalamAntrian"}>
                    Dalam Antrian
                  </Option>
                  <Option key={""} value={"KonfirmasiPelanggan"}>
                    Konfirmasi Pelanggan
                  </Option>
                  <Option key={""} value={"Selesai"}>
                    Selesai
                  </Option>
                </Select>
              </Form.Item>
            </div>

            <div className={"inputFormSearchValue"}>
              <FormInputTextMaterial
                validateType={true}
                value={this.props.getSearchValue}
                className={""}
                disabled={false}
                addonBefore={prefixSelector}
                onPressEnter={""}
                placeholder={"Masukan Nomor"}
                prefix={""}
                maxLength={25}
                style={{}}
                size=""
                type={""}
                onChange={this.props.handleChangeSearchValue}
                onBlur={""}
                validatestatus={""}
                help={""}
              />
            </div>
            <ButtonComponent
              disabled={this.props.validateBtnSubmit}
              onClick={this.props.handleChangeSubmit}
              type={"primary"}
              shape="round"
              className={"btnSubmitComplaint"}
            >
              <b>Cari</b>
            </ButtonComponent>
            {this.props.getComplaintList.length !== 0 && (
              <CardMaterial
                className={"defaultCard"}
                extra={""}
                title={""}
                style={{}}
                content={
                  <div className={"tblComplaintList"}>
                    <Table
                      bordered={false}
                      pagination={{
                        onChange: (page) => {
                          this.props.handlePage(page);
                        },
                        pageSize: 10,
                      }}
                      dataSource={this.props.getComplaintList}
                      columns={columns}
                    />
                  </div>
                }
              />
            )}
          </SpinMaterial>
        )}
      </React.Fragment>
    );
  }
}
