import React, { Component } from 'react';

import { Card } from 'antd';

interface IProps {
  content: any;
  style: any;
  title: any;
  extra: any;
  className:any;
}
export default class CardMaterial extends Component<IProps> {
  render() {
    return (
      <Card
        {...this.props}
        style={this.props.style}
        title={this.props.title}
        extra={this.props.extra}
      >
        {this.props.content}
      </Card>
    );
  }
}
