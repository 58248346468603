/* eslint-disable jsx-a11y/alt-text */

import '../../styles/LoginStyle/index.css';
import "typeface-roboto";

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import React, { Component } from 'react';

import ButtonMaterial from '../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../materials/FormInputTextMaterial';
import iconCCC from '../../image/iconCCC.png';

interface IProps
{
    handlePutUsername:any
    validateUsername:any
    helpUsername:any
    handlePutPassword:any
    validatePassword:any
    helpPassword:any
    handleSubmit:any
    getUserName:any
    getPassword:any
    validateButton:any
    // isLoading:any
}

export default class LoginComponent extends Component<IProps> {
    render() {
        return (
            <React.Fragment>
                <img style={{width:'70px', height:'100px', paddingTop:'30px', position:'relative', marginLeft:'155px'}} src={iconCCC}/>
                <div style={{textAlign:'center', color:'#2DA4FF', fontFamily:'"Segoe UI",Arial,"Segoe UI"', }}>
                    <span style={{fontSize:'22px'}}>
                        <b>Courier</b>Command<b>Center</b><br/>
                    </span>
                        <b style={{fontSize:'14px'}}>Portal</b>
                </div>
                <div style={{paddingTop:'10px'}}>
                    <FormInputTextMaterial
                        validateType={true}
                        value={this.props.getUserName}
                        className={""}
                        disabled={false}
                        addonBefore={""}
                        onPressEnter={''}
                        placeholder={"Nama pengguna"}
                        prefix={<UserOutlined/>}
                        maxLength={""}
                        style={{borderRadius: "35px"}}
                        size=""
                        type={""}
                        onChange={this.props.handlePutUsername}
                        onBlur={this.props.handlePutUsername}
                        validatestatus={this.props.validateUsername}
                        help={this.props.helpUsername}
                    />
                    </div>
                    <div>
                    <FormInputTextMaterial
                        validateType={true}
                        value={this.props.getPassword}
                        className={""}
                        disabled={false}
                        addonBefore={""}
                        onPressEnter={this.props.handleSubmit}
                        placeholder={"Kata sandi"}
                        prefix={<LockOutlined />}
                        maxLength={""}
                        style={{borderRadius: "35px"}}
                        size=""
                        type={"password"}
                        onChange={this.props.handlePutPassword}
                        onBlur={this.props.handlePutPassword}
                        validatestatus={this.props.validatePassword}
                        help={this.props.helpPassword}
                    />
                </div>
                <div style={{textAlign:'center'}}>
                    <ButtonMaterial
                        disabled={this.props.validateButton}
                        onClick={this.props.handleSubmit}
                        teksButton={"MASUK"}
                        size={"middle"}
                        shape={"round"}
                        className={"button-login"}
                        type={"primary"}
                        style={{paddingTop:'5px'}}
                            >
                        <b>MASUK</b>
                    </ButtonMaterial>
                </div>
                
            </React.Fragment>
        )
    }
}
