/* eslint-disable jsx-a11y/anchor-is-valid */

import 'moment/locale/id';
import '../../../index.css';
import '../../../styles/ReportStyle/index.css';

import { Checkbox, Col, DatePicker, Form, Row, Select } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import React, { Component } from 'react';

import ButtonComponent from '../../../materials/ButtonMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

const { Option } = Select;

interface IProps {
  isLoading: any;
  getCycleDateStart: any;
  getCycleDateFinish: any;
  getDeliveryStatus: any;
  getCustomerProduct: any;
  getReportType: any;
  getChecboxData: any;
  ListCustomerProduct: any;
  ListReportType: any;
  loadingCustomerProduct: any;
  loadingReportType: any;
  handleValidateCycleDateStart: any;
  handleValidateCycleDateFinish: any;
  validateCycleDateStart: any;
  validateCycleDateFinish: any;
  validateDeliveryStatus: any;
  validateCustomerProduct: any;
  validateReportType: any;
  validateButton: any;
  validateButtonReset: any;
  handlePutCycleDateStart: any;
  handlePutCycleDateFinish: any;
  handlePutDeliveryStatus: any;
  handlePutCustomerProduct: any;
  handlePutReportType: any;
  handleGetCustomerProduct: any;
  handleGetReportType: any;
  handleBlurDeliveryStatus: any;
  handleBlurCustomerProduct: any;
  handleBlurReportType: any;
  helpCycleDateStart: any;
  helpCycleDateFinish: any;
  helpDeliveryStatus: any;
  helpValidateCustomerProduct: any;
  helpValidateReportType: any;
  handleDownload: any;
  handleReset: any;
  handleResetCustomerProduct: any;
  handleCheckbox: any;
  handleShowFilter: any;
  getShowFilter: any;
}

export default class ReportComponent extends Component<IProps> {
  render() {
    const formatDate = 'YYYY-MM-DD';
    const minDate2 = (d: any) =>
      !d ||
      d.isBefore(
        moment(this.props.getCycleDateStart).add(0, 'day').format(formatDate)
      ) ||
      moment().add(0, 'days') <= d;
    const filterIcon =
      this.props.getShowFilter === false ? (
        <RightOutlined className="iconFilter" />
      ) : (
        <DownOutlined className="iconFilter" />
      );
    return (
      <React.Fragment>
        <SpinMaterial spinning={this.props.isLoading} size={'large'}>
          <div className="titleLayoutReport">
            <span className="titleReport">
              <b>LAPORAN PELANGGAN</b>
            </span>
          </div>
          <Row>
            <Col span={12} className="paddingReportLeft">
              <div>
                <span>
                  <b>Tanggal Cycle</b>
                </span>
                <Form.Item
                  validateStatus={this.props.validateCycleDateStart}
                  help={this.props.helpCycleDateStart}
                >
                  <DatePicker
                    className="datepickerDefault"
                    locale={locale}
                    format={'DD-MM-YYYY'}
                    placeholder={'Pilih tanggal'}
                    onOpenChange={this.props.handleValidateCycleDateStart}
                    value={this.props.getCycleDateStart}
                    onChange={this.props.handlePutCycleDateStart}
                    disabledDate={(current) => {
                      return (
                        moment().add(0, 'days') <= current ||
                        moment().add(-3, 'month') >= current
                      );
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12} className="paddingReportRight">
              <div>
                <span>
                  <b>Sampai Tanggal</b>
                </span>
                <Form.Item
                  validateStatus={this.props.validateCycleDateFinish}
                  help={this.props.helpCycleDateFinish}
                >
                  <DatePicker
                    className="datepickerDefault"
                    locale={locale}
                    format={'DD-MM-YYYY'}
                    placeholder={'Pilih tanggal'}
                    onOpenChange={this.props.handleValidateCycleDateFinish}
                    value={this.props.getCycleDateFinish}
                    onChange={this.props.handlePutCycleDateFinish}
                    disabledDate={minDate2}
                    disabled={
                      this.props.getCycleDateStart === null ||
                      this.props.getCycleDateStart === ''
                        ? true
                        : false
                    }
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <div onClick={this.props.handleShowFilter} className="showFilter">
              <a>Ubah Filter</a>
              {filterIcon}
            </div>
          </Row>
          <Row>
            <Col span={12} className="paddingReportLeft">
              {this.props.getShowFilter === true ? (
                <div className="borderTop">
                  <span>
                    <b>Status Pengiriman</b>
                  </span>
                  <Form.Item
                    validateStatus={this.props.validateDeliveryStatus}
                    help={this.props.helpDeliveryStatus}
                  >
                    <Select
                      className="widthFix"
                      mode="multiple"
                      disabled={false}
                      onDeselect={this.props.handleBlurDeliveryStatus}
                      onSelect={this.props.handleBlurDeliveryStatus}
                      onBlur={this.props.handleBlurDeliveryStatus}
                      onChange={this.props.handlePutDeliveryStatus}
                      placeholder="Pilih Status Pengiriman"
                      value={this.props.getDeliveryStatus}
                      // allowClear={true}
                    >
                      <Option key={''} value={'Success'}>
                        {'Diterima'}
                      </Option>
                      <Option key={''} value={'Return'}>
                        {'Dikembalikan'}
                      </Option>
                      <Option key={''} value={'OnProcces'}>
                        {'Proses'}
                      </Option>
                    </Select>
                  </Form.Item>
                  <span>
                    <b>Produk</b>
                  </span>
                  <Form.Item
                    validateStatus={this.props.validateCustomerProduct}
                    help={this.props.helpValidateCustomerProduct}
                  >
                    <Select
                      className="widthFix"
                      loading={this.props.loadingCustomerProduct}
                      mode="multiple"
                      value={this.props.getCustomerProduct}
                      // disabled={null}
                      placeholder="Pilih Produk"
                      showSearch
                      allowClear={true}
                      onFocus={this.props.handleGetCustomerProduct}
                      // onDeselect={this.props.handleBlurCustomerProduct}
                      // onSelect={this.props.handlePutCustomerProduct}
                      onBlur={this.props.handleBlurCustomerProduct}
                      onChange={this.props.handlePutCustomerProduct}
                    >
                      {this.props.ListCustomerProduct.length ===
                      this.props.getCustomerProduct.length ? null : (
                        <Option key={''} value={'SetAllProduct'}>
                          {'Pilih Semua Produk'}
                        </Option>
                      )}
                      {this.props.ListCustomerProduct.map(
                        (data: any, index: any) => (
                          <Option key={index} value={data.id}>
                            {data.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </div>
              ) : null}
            </Col>
            <Col span={12} className="paddingReportRight">
              {this.props.getShowFilter === true ? (
                <div className="borderTop">
                  <span>
                    <b>Jenis Laporan</b>
                  </span>
                  <Form.Item
                    validateStatus={this.props.validateReportType}
                    help={this.props.helpValidateReportType}
                  >
                    <Select
                      className="widthFix"
                      loading={this.props.loadingReportType}
                      value={
                        this.props.getReportType === 0 ? (
                          <span>
                            Standar
                          </span>
                        ) : (
                          this.props.getReportType
                        )
                      }
                      placeholder="Pilih Jenis Laporan"
                      showSearch
                      onFocus={this.props.handleGetReportType}
                      onDeselect={this.props.handleBlurReportType}
                      onBlur={this.props.handleBlurReportType}
                      onChange={this.props.handlePutReportType}
                    >
                      {this.props.ListReportType === [] ? null : (
                        <Option key={''} value={0}>
                          {'Standar'}
                        </Option>
                      )}

                      {this.props.ListReportType.map(
                        (data: any, index: any) => (
                          <Option key={index} value={data.id}>
                            {data.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                  <Checkbox
                    onChange={this.props.handleCheckbox}
                    className="checkboxReport"
                    checked={this.props.getChecboxData}
                  >
                    Data Lengkap
                  </Checkbox>
                </div>
              ) : null}
            </Col>
          </Row>
          <Row className="containerButtonReport">
            <div className="marginLeft">
              <ButtonComponent
                disabled={this.props.validateButtonReset}
                onClick={this.props.handleReset}
                type={'primary'}
                shape="round"
                size={'middle'}
                className={
                  this.props.validateButtonReset === true
                    ? 'btnDisableResetReport'
                    : 'btnEnableResetReport'
                }
              >
                <b>Reset</b>
              </ButtonComponent>
            </div>
            <div className="marginLeft">
              <ButtonComponent
                disabled={this.props.validateButton}
                onClick={this.props.handleDownload}
                type={'primary'}
                shape="round"
                size={'middle'}
                className="btnDownloadReport"
              >
                <b>Download</b>
              </ButtonComponent>
            </div>
          </Row>
        </SpinMaterial>
      </React.Fragment>
    );
  }
}
