import * as ActionComplaint from '../../../../core/action/Complaint/ActionComplaint';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import SearchDocForComplaintComponent from '../../../../components/Content/Complaint/AddComplaint/SearchDocForComplaintComponent';
import { connect } from 'react-redux';

interface IProps {
  actionComplaint: any;
  getFilterSearch: any;
  getSearchValue: any;
  getBarcode: any;
  getCycleDate: any;
  getDocumentList: any;
}
interface IState {
  page: any;
}

class SearchDocForComplainContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: null,
    };
    this.handleChangeFilterSearch = this.handleChangeFilterSearch.bind(this);
    this.handlePutBarcode = this.handlePutBarcode.bind(this);
    this.handlePutSearchValue = this.handlePutSearchValue.bind(this);
    this.handlePutCycleDate = this.handlePutCycleDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
  }
  handlePage(event: any) {
    this.setState({
      page: event,
    });
  }
  handleChangeFilterSearch(event: any) {
    this.props.actionComplaint.changeAddFilterSearch(event);
    if (event === 'Waybill') {
      this.props.actionComplaint.changeCycleDate(null);
    }
  }
  handlePutBarcode(event: any) {
    this.props.actionComplaint.changeBarcode(event.target.value);
  }
  handlePutSearchValue(event: any) {
    this.props.actionComplaint.changeAddSearchValue(event.target.value);
  }
  handlePutCycleDate(event: any) {
    this.props.actionComplaint.changeCycleDate(event);
  }
  handleSubmit() {
    this.props.actionComplaint.getDocumentList();
  }
  render() {
    return (
      <React.Fragment>
        <SearchDocForComplaintComponent
          page={this.state.page}
          handleChangeFilterSearch={this.handleChangeFilterSearch}
          getFilterSearch={this.props.getFilterSearch}
          handlePutBarcode={this.handlePutBarcode}
          getBarcode={this.props.getBarcode}
          getSearchValue={this.props.getSearchValue}
          handlePutCycleDate={this.handlePutCycleDate}
          handlePutSearchValue={this.handlePutSearchValue}
          getCycleDate={this.props.getCycleDate}
          handleSubmit={this.handleSubmit}
          getDocumentList={this.props.getDocumentList}
          handlePage={this.handlePage}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  getFilterSearch: state.Complaint.addComplaintState.filterSearch,
  getSearchValue: state.Complaint.addComplaintState.searchValue,
  getBarcode: state.Complaint.addComplaintState.barcode,
  getCycleDate: state.Complaint.addComplaintState.cycleDate,
  getDocumentList: state.Complaint.addComplaintState.documentList,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionComplaint: bindActionCreators(ActionComplaint, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SearchDocForComplainContainer);
