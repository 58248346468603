import React from 'react';
import { Spin } from 'antd';

const SpinComponent=(props:any)=> {
    return (
        <div>
            <Spin
                {...props}
            />
        </div>
    )
}
export default SpinComponent