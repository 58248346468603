import React, { Component } from 'react';

export default class FooterComponent extends Component {
  render() {
    return (
      <div>
        <span style={{ color: '#969696' }}>
          ©CopyRight 2020, All right reserved
        </span>
        {/* <span style={{color:'#969696', float:'right'}}>Design by Budi Kuple</span> */}
      </div>
    );
  }
}
