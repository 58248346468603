import { ArrowUpOutlined } from "@ant-design/icons";
import { BackTop, Steps } from "antd";
import React, { Component } from "react";
import SuccessComplaintContainer from "../../../../containers/Content/Complaint/SuccessComplaintContainer";
import DataWaybillContainer from "../../../../containers/Content/Complaint/DataWaybillContainer";
import HistoryComplaintContainer from "../../../../containers/Content/Complaint/HistoryComplaintContainer";
import DetailFormContainer from "../../../../containers/Content/Complaint/DetailComplaint/DetailFormContainer";
import UpdateFormContainer from "../../../../containers/Content/Complaint/UpdateComplaint/UpdateFormContainer";
import ButtonComponent from "../../../../materials/ButtonMaterial";
import SpinComponent from "../../../../materials/SpinMaterial";

const { Step } = Steps;

interface IProps {
  getComplaintProcess: any;
  getDetail: any;
  getHistory: any;
  getMode: any;
  handleBack: any;
  isLoading: any;
}

export default class IndexUpdateComponent extends Component<IProps> {
  render() {
    const showContent = () => {
      if (this.props.getComplaintProcess === "updateComplaint") {
        return (
          <>
            <DataWaybillContainer getDetailDocument={this.props.getDetail} />
            <DetailFormContainer />
            <UpdateFormContainer />
            {this.props.getHistory.length !== 0 && (
              <div className="leftContentHistoryUpdateComplaint">
                <p className="titleCardComplaint">RIWAYAT KELUHAN</p>
              </div>
            )}
            <Steps
              current={this.props.getHistory.length}
              progressDot
              direction="vertical"
              className="stepHistoryComplaint"
            >
              {this.props.getHistory.map((data: any) => (
                <Step
                  description={
                    <HistoryComplaintContainer
                      getDateTime={data.date}
                      getDesc={data.description}
                      getUser={data.userActor}
                      getImage={data.image}
                      getSignature={data.signature}
                      getLocation={{
                        latitude: data.latitude || null,
                        longitude: data.longitude || null,
                      }}
                    />
                  }
                />
              ))}
            </Steps>
          </>
        );
      } else if (this.props.getComplaintProcess === "doneUpdateComplaint") {
        return (
          <SuccessComplaintContainer
            typeComplaint={"updateComplaint"}
            textComplaintSuccess={
              this.props.getMode === true
                ? "Data berhasil diinput, ticket keluhan telah selesai"
                : "Data berhasil diinput, status telah diupdate menjadi proses"
            }
          />
        );
      }
    };
    const showBackButton = () => {
      return (
        <ButtonComponent
          onClick={this.props.handleBack}
          type="link"
          className={"btnBackComplaintLink"}
        >
          {"< Kembali"}
        </ButtonComponent>
      );
    };
    return (
      <div>
        <SpinComponent spinning={this.props.isLoading} size={"large"}>
          {showBackButton()}
          <div className="centerContentAddComplaint">
            <p className="titleCardComplaint">EDIT KELUHAN</p>
          </div>
          {showContent()}
          <BackTop>
            <ButtonComponent shape="circle" icon={<ArrowUpOutlined />} />
          </BackTop>
        </SpinComponent>
      </div>
    );
  }
}
