import * as ActionLogin from '../../action/Login';
import * as actionComplaint from '../../action/Complaint/ActionComplaint';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../ErrorMessage';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_searchComplaintTicket() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const firstDate = state.Complaint.formSearchComplaint.firstDate;
  const lastDate = state.Complaint.formSearchComplaint.lastDate;
  const typeStatus = state.Complaint.formSearchComplaint.typeStatus;
  const complaintFilterSearch =
    state.Complaint.formSearchComplaint.complaintFilterSearch;
  const searchValue = state.Complaint.formSearchComplaint.searchValue;
  const searchValuePost =
    searchValue === null || searchValue === '' ? null : searchValue;
  const convFirstDate = moment(firstDate).format('YYYYMMDD');
  const convLastDate = moment(lastDate).format('YYYYMMDD');
  const typeStatusPost = typeStatus === 'Semua' ? null : typeStatus;
  const getCustomerId = state.Auth.CustomerId;
  const getUsername = state.Auth.UsernameAuth;
  let apiLinkValue = '';
  const apiLink = () => {
    if (
      complaintFilterSearch === 'Waybill' ||
      complaintFilterSearch === 'Ticket'
    ) {
      apiLinkValue = `/Portal/SelectComplaintByFilterNew/0/100?FilterSearchComplaint=${complaintFilterSearch}&Value=${searchValue}&CustomerId=${getCustomerId}&Username=${getUsername}`;
    } else {
      if (typeStatusPost !== null && searchValuePost !== null) {
        apiLinkValue = `/Portal/SelectComplaintByFilterNew/0/100?ComplaintType=${typeStatusPost}&DateFrom=${convFirstDate}&DateTo=${convLastDate}&FilterSearchComplaint=${complaintFilterSearch}&Value=${searchValue}&CustomerId=${getCustomerId}&Username=${getUsername}`;
      } else if (typeStatusPost === null && searchValuePost === null) {
        apiLinkValue = `/Portal/SelectComplaintByFilterNew/0/10000?DateFrom=${convFirstDate}&DateTo=${convLastDate}&FilterSearchComplaint=${complaintFilterSearch}&CustomerId=${getCustomerId}&Username=${getUsername}`;
      } else if (typeStatusPost === null && searchValuePost !== null) {
        apiLinkValue = `/Portal/SelectComplaintByFilterNew/0/10000?DateFrom=${convFirstDate}&DateTo=${convLastDate}&FilterSearchComplaint=${complaintFilterSearch}&Value=${searchValue}&CustomerId=${getCustomerId}&Username=${getUsername}`;
      } else if (typeStatusPost !== null && searchValuePost === null) {
        apiLinkValue = `/Portal/SelectComplaintByFilterNew/0/10000?ComplaintType=${typeStatusPost}&DateFrom=${convFirstDate}&DateTo=${convLastDate}&FilterSearchComplaint=${complaintFilterSearch}&CustomerId=${getCustomerId}&Username=${getUsername}`;
      }
    }
  };
  yield put(actionComplaint.setComplaintList([]));
  try {
    apiLink();
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + apiLinkValue,
      { headers: config }
    );
    yield put(actionComplaint.setComplaintList(data.data));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefresh());
      yield put(toggle.changeLoadingFalse());
    } else if (error.response.data.Message) {
      Modal.error({
        title: <p style={{ color: '#FF0000' }}>Gagal mencari dokumen</p>,
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>Data yang dicari tidak ditemukan</p>
          </div>
        ),
        onOk() {},
      });
      yield put(actionComplaint.setComplaintList([]));
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      yield put(actionComplaint.setComplaintList([]));
      const propsError = ErrorMassage('Hasil Pencarian Tiket Keluhan');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* S_searchComplaintWbOrTk() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const getUsername = state.Auth.UsernameAuth;
  const getCustomerId = state.Auth.CustomerId;
  const complaintFilterSearch =
    state.Complaint.formSearchComplaint.complaintFilterSearch;
  const searchValue = state.Complaint.formSearchComplaint.searchValue;
  const valuePost =
    complaintFilterSearch === 'Waybill'
      ? `/Complaint/GetDataTiketPortalByDetail/0/1000?Waybill=${searchValue}&CustomerId=${getCustomerId}&Username=${getUsername}`
      : `/Complaint/GetDataTiketPortalByDetail/0/1000?Tiket=${searchValue}&CustomerId=${getCustomerId}&Username=${getUsername}`;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + valuePost,
      { headers: config }
    );
    yield put(actionComplaint.setComplaintList(data.data));

    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefresh());
      yield put(toggle.changeLoadingFalse());
    } else if (error.response.data.Message) {
      Modal.error({
        title: <p style={{ color: '#FF0000' }}>Gagal mencari dokumen</p>,
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>Data yang dicari tidak ditemukan</p>
          </div>
        ),
        onOk() {},
      });
      yield put(actionComplaint.clearSearchComplaintDU());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      yield put(actionComplaint.clearSearchComplaintDU());
      const propsError = ErrorMassage('Hasil Pencarian Tiket Keluhan');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_searchComplaintTicket() {
  yield takeLatest('SUBMIT_SEARCH_COMPLAINT', S_searchComplaintTicket);
}

export function* SM_searchComplaintWbOrTk() {
  yield takeLatest('SUBMIT_SEARCH_WB_OR_TK_COMPLAINT', S_searchComplaintWbOrTk);
}
