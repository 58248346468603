import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import DetailFormComponent from "../../../../components/Content/Complaint/DetailComplaint/DetailFormComponent";

interface IProps {
  getCodeTicket: any;
  getStatus: any;
  getNoTelp: any;
  getTitleComplaint: any;
}

class DetailFormContainer extends Component<IProps> {
  render() {
    const showStatus = (status: any) => {
      switch (status) {
        case 0: {
          return "Proses";
        }
        case 1: {
          return "Selesai";
        }
        case 2: {
          return "Dalam Antrian";
        }
        case 3: {
          return "Konfirmasi Pelanggan";
        }
        case 4: {
          return "Kosong";
        }
      }
      return null;
    };
    return (
      <React.Fragment>
        <DetailFormComponent
          getCodeTicket={this.props.getCodeTicket}
          getStatus={showStatus(this.props.getStatus)}
          getNoTelp={this.props.getNoTelp}
          getTitleComplaint={this.props.getTitleComplaint}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  getCodeTicket: state.Complaint.detailComplaint.codeTicket,
  getStatus: state.Complaint.detailComplaint.status,
  getNoTelp: state.Complaint.detailComplaint.phoneNumber,
  getTitleComplaint: state.Complaint.detailComplaint.titleComplaint,
});
const mapDispatchToProps = (dispatch: any) => ({});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(DetailFormContainer);
