/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import LoginLogo from '../../image/LoginLogo.png';

export default class BerandaComponent extends Component {
    render() {
        return (
            <React.Fragment>
                <div style={{alignItems:'center', justifyContent:'center',display:'flex', height:'100px'}}>
                <img style={{width:'320px', height:'520px', paddingTop:'300px'}} src={LoginLogo}/>
                </div>
            </React.Fragment>
        )
    }
}
