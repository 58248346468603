import React, { Component } from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import NoteFound from '../../components/NoteFound/index';


interface IProps{
    
}
interface IState{
    isVisible: any;
}
class NoteFoundContainer extends Component<IProps, IState> {
    constructor(props:any)
    {
        super(props)
        this.state = {
            isVisible: false,
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState({ isVisible: true }), 2000);
    }

    render() {
        return (
            <div>
                <NoteFound
                    isVisible={this.state.isVisible}
                />
            </div>
        )
    }
}
const mapStateToProps = (state:any) => ({
})

const mapDispatchToProps = (dispatch:any) => ({
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(
    withConnect
)(NoteFoundContainer)
