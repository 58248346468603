import { Action } from 'redux';

const initialState = {
  UsernameAuth: null,
  CustomerId: null,
  AccessHeader: [],
  AccessContent: [],
  Token: null,
  TokenRefresh: null,
};

export interface IAuthAction extends Action<string> {}

export interface IChangeUsernameAuth extends IAuthAction {
  UsernameAuth: any;
}

export interface IChangeCustomerId extends IAuthAction {
  CustomerId: any;
}

export interface IChangeAccessHeader extends IAuthAction {
  AccessHeader: any;
}
export interface IChangeAccessContent extends IAuthAction {
  AccessContent: any;
}

export interface IChangeToken extends IAuthAction {
  Token: any;
}

export interface IChangeTokenRefresh extends IAuthAction {
  TokenRefresh: any;
}

export default function AuthReducerLoginForm(
  state = initialState,
  action: IAuthAction
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_USERNAME_AUTH':
      const changeUsernameAuth = action as IChangeUsernameAuth;
      newState.UsernameAuth = changeUsernameAuth.UsernameAuth;

      return { ...newState };

    case 'CHANGE_CUSTOMER_ID':
      const changeCustomerId = action as IChangeCustomerId;
      newState.CustomerId = changeCustomerId.CustomerId;

      return { ...newState };

    case 'CHANGE_ACCESS_HEADER':
      const changeAccessHeader = action as IChangeAccessHeader;
      newState.AccessHeader = changeAccessHeader.AccessHeader;

      return { ...newState };

    case 'CHANGE_ACCESS_CONTENT':
      const changeAccessContent = action as IChangeAccessContent;
      newState.AccessContent = changeAccessContent.AccessContent;

      return { ...newState };

    case 'CHANGE_TOKEN':
      const changeToken = action as IChangeToken;
      newState.Token = changeToken.Token;

      return { ...newState };

    case 'CHANGE_TOKEN_REFRESH':
      const changeTokenRefresh = action as IChangeTokenRefresh;
      newState.TokenRefresh = changeTokenRefresh.TokenRefresh;

      return { ...newState };

    case 'CLEAR_TOKEN_REFRESH':
      newState.TokenRefresh = null;

      return { ...newState };

    case 'CLEAR_TOKEN':
      newState.Token = null;

      return { ...newState };

    case 'CLEAR_USERNAME_AUTH':
      newState.UsernameAuth = null;

      return { ...newState };

    case 'CLEAR_CUSTOMER_ID':
      newState.CustomerId = null;

      return { ...newState };

    case 'CLEAR_ACCESS_CONTROL':
      newState.AccessHeader = [];
      newState.AccessContent = [];
      
      return { ...newState };
  }
  return state;
}
