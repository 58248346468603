import React from "react";
import { Modal } from "antd";

interface ModalStatusProps {
  status: "success" | "error" | "warning" | "info";
  title: string;
  content?: any;
  isReaload?: boolean;
  handleOke?: () => void;
}

const ModalStatus = ({
  status,
  title,
  content,
  isReaload = false,
  handleOke = () => undefined,
}: ModalStatusProps) => {
  return Modal[status]({
    title: (
      <p
        style={
          status === "success" ? { color: "#31E116" } : { color: "#FF0000" }
        }
      >
        {title}
      </p>
    ),
    content: <div>{content}</div>,
    onOk() {
      handleOke();
      if (isReaload) {
        window.location.reload();
      }
    },
  });
};

export default ModalStatus;
