import * as ActionComplaint from '../../../core/action/Complaint/ActionComplaint';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import MainComplaintComponent from '../../../components/Content/Complaint/MainComplaintComponent';
import { connect } from 'react-redux';
import moment from 'moment';

interface IProps {
  getComplaintProcess: any;
  getFirstDate: any;
  getLastDate: any;
  getTypeStatus: any;
  getComplaintFilterSearch: any;
  getSearchValue: any;
  getComplaintList: any;
  isLoading: any;
  actionComplaint: any;
}
interface IState {
  page: any;
  valueFirstDate: any;
  valueLastDate: any;
  validateFirstDate: any;
  validateFirstDate2: any;
  validateLastDate: any;
}
class MainComplaintContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: 0,
      valueFirstDate: null,
      validateFirstDate2: null,
      valueLastDate: null,
      validateFirstDate: null,
      validateLastDate: null,
    };
    this.handleChangeFirstDate = this.handleChangeFirstDate.bind(this);
    this.handleValidateStartDate = this.handleValidateStartDate.bind(this);
    this.handleChangeLastDate = this.handleChangeLastDate.bind(this);
    this.handleValidateLastDate = this.handleValidateLastDate.bind(this);
    this.handleChangeTypeStatus = this.handleChangeTypeStatus.bind(this);
    this.handleChangeFilterSearch = this.handleChangeFilterSearch.bind(this);
    this.handleChangeSearchValue = this.handleChangeSearchValue.bind(this);
    this.handleAddComplaint = this.handleAddComplaint.bind(this);
    this.handleDetailComplaint = this.handleDetailComplaint.bind(this);
    this.handleUpdateComplaint = this.handleUpdateComplaint.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleAddComplaint() {
    this.setState({
      valueFirstDate: null,
      validateFirstDate2: null,
      valueLastDate: null,
      validateFirstDate: null,
      validateLastDate: null,
    })
    this.props.actionComplaint.changeComplaintProcess('addComplaint')
    this.props.actionComplaint.clearAllComplaintForm()
  }
  handlePage(event: any) {
    this.setState({
      page: event,
    });
  }

  handleChangeFirstDate(event: any) {
    this.setState({
      valueFirstDate: event,
    });
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      const dateValueConvert = moment(dateValue).format('YYYYMMDD');
      const finishDateValueConvert = moment(this.props.getLastDate).format(
        'YYYYMMDD'
      );
      this.props.actionComplaint.changeFirstDate(dateValue);
      if (dateValueConvert > finishDateValueConvert) {
        this.setState({
          valueLastDate: event,
        });
        this.props.actionComplaint.changeLastDate(dateValue);
      } else if (dateValueConvert === finishDateValueConvert) {
        this.setState({
          valueLastDate: event,
        });
      } else {
        this.setState({
          valueLastDate: null,
        });
        this.props.actionComplaint.changeLastDate(null);
      }
      this.setState({
        valueLastDate: null,
      });
    } else {
      this.props.actionComplaint.changeFirstDate(null);
      this.props.actionComplaint.changeLastDate(null);
      this.setState({
        validateFirstDate: 'error',
        validateLastDate: null,
        valueLastDate: null,
      });
    }
  }
  handleValidateStartDate(event: any) {
    this.setState({
      validateFirstDate2: event,
    });
    if (this.state.validateFirstDate2 === true) {
      if (
        this.props.getFirstDate === null ||
        this.props.getFirstDate === undefined ||
        this.props.getFirstDate === 'Invalid date'
      ) {
        this.setState({
          validateFirstDate: 'error',
        });
      } else {
        this.setState({
          validateFirstDate: null,
        });
      }
    }
  }
  handleChangeLastDate(event: any) {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      this.props.actionComplaint.changeLastDate(dateValue);
    } else {
      this.props.actionComplaint.changeLastDate(null);
    }
    this.setState({
      valueLastDate: event,
    });
    if (event === '') {
      this.setState({
        validateLastDate: 'error',
      });
    } else {
      this.setState({
        validateLastDate: '',
      });
    }
  }
  handleValidateLastDate(event: any) {
    if (event === true) {
      if (
        this.props.getLastDate === null ||
        this.props.getLastDate === undefined ||
        this.props.getLastDate === 'Invalid date'
      ) {
        this.setState({
          validateLastDate: 'error',
        });
      } else {
        this.setState({
          validateLastDate: '',
        });
      }
    }
  }
  handleChangeTypeStatus(event: any) {
    this.props.actionComplaint.changeTypeStatus(event);
  }
  handleChangeFilterSearch(event: any) {
    this.props.actionComplaint.changeFilterSearch(event);
    if (event === 'Waybill' || event === 'Ticket') {
      this.props.actionComplaint.clearComplaintForm();
      this.props.actionComplaint.changeTypeStatus('Semua');
      this.setState({
        valueFirstDate: null,
        validateFirstDate2: null,
        valueLastDate: null,
        validateFirstDate: null,
        validateLastDate: null,
      });
    }else{
      this.props.actionComplaint.clearSearchValueComplaintForm()
      this.props.actionComplaint.changeTypeStatus(null);
    }
  }
  handleChangeSearchValue(event: any) {
    this.props.actionComplaint.changeSearchValue(event.target.value);
  }
  handleSubmit() {
    this.props.actionComplaint.submitSearchComplaint();
    this.setState({
      page: 0,
    });
  }
  handleDetailComplaint(event:any) {
    this.props.actionComplaint.changeDetailD(event.id);
    this.props.actionComplaint.changeComplaintProcess('detailComplaint')
    this.props.actionComplaint.submitDetailComplaint();
    this.setState({
      valueFirstDate: null,
      validateFirstDate2: null,
      valueLastDate: null,
      validateFirstDate: null,
      validateLastDate: null,
    });
  }
  handleUpdateComplaint(event:any) {
    this.props.actionComplaint.changeDetailD(event.id);
    this.props.actionComplaint.changeComplaintProcess('updateComplaint')
    this.props.actionComplaint.clearSearchComplaintDU();
    this.props.actionComplaint.submitDetailComplaint();
    this.setState({
      valueFirstDate: null,
      validateFirstDate2: null,
      valueLastDate: null,
      validateFirstDate: null,
      validateLastDate: null,
    });
  }
  render() {
    return (
      <React.Fragment>
        <MainComplaintComponent
          getComplaintProcess={this.props.getComplaintProcess}
          getFirstDate={this.props.getFirstDate}
          getLastDate={this.props.getLastDate}
          getTypeStatus={this.props.getTypeStatus}
          getComplaintFilterSearch={this.props.getComplaintFilterSearch}
          getSearchValue={this.props.getSearchValue}
          getComplaintList={this.props.getComplaintList}
          getValueFirstDate={this.state.valueFirstDate}
          // validateFirstDate={
          //   this.state.validateFirstDate === 'error' ? 'error' : ''
          // }
          // helpFirstDate={
          //   this.state.validateFirstDate === 'error'
          //     ? 'Tanggal wajib diisi'
          //     : ''
          // }
          getValueLastDate={this.state.valueLastDate}
          validateBtnSubmit={
            this.props.getComplaintFilterSearch === 'Waybill' ||
            this.props.getComplaintFilterSearch === 'Ticket'
              ? this.props.getSearchValue === null ||
                this.props.getSearchValue === '' ||
                this.props.getTypeStatus === null 
                ? true
                : false
              : this.props.getFirstDate === null ||
                this.props.getFirstDate === undefined ||
                this.props.getFirstDate === 'Invalid date' ||
                this.props.getLastDate === null ||
                this.props.getLastDate === undefined ||
                this.props.getLastDate === 'Invalid date'||
                this.props.getTypeStatus === null 
              ? true
              : false
          }
          isLoading={this.props.isLoading}
          page={this.state.page}
          handlePage={this.handlePage}
          handleChangeFirstDate={this.handleChangeFirstDate}
          handleValidateStartDate={this.handleValidateStartDate}
          handleChangeLastDate={this.handleChangeLastDate}
          handleValidateLastDate={this.handleValidateLastDate}
          handleChangeTypeStatus={this.handleChangeTypeStatus}
          handleChangeFilterSearch={this.handleChangeFilterSearch}
          handleChangeSearchValue={this.handleChangeSearchValue}
          handleAddComplaint={this.handleAddComplaint}
          handleChangeSubmit={this.handleSubmit}
          handleDetailComplaint={this.handleDetailComplaint}
          handleUpdateComplaint={this.handleUpdateComplaint}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  getComplaintProcess: state.Complaint.propertiesComplaint.complaintProcessType,
  getFirstDate: state.Complaint.formSearchComplaint.firstDate,
  getLastDate: state.Complaint.formSearchComplaint.lastDate,
  getTypeStatus: state.Complaint.formSearchComplaint.typeStatus,
  getComplaintFilterSearch:
    state.Complaint.formSearchComplaint.complaintFilterSearch,
  getSearchValue: state.Complaint.formSearchComplaint.searchValue,
  getComplaintList: state.Complaint.complaintList,
  isLoading: state.Toggle.isLoading,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionComplaint: bindActionCreators(ActionComplaint, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(MainComplaintContainer);
