import * as ActionToggle from '../../../../core/action/toggle/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import ModalDetailDocComponent from '../../../../components/Content/Complaint/AddComplaint/ModalDetailDocComponent';
import { connect } from 'react-redux';

interface IProps {
  showModal: any;
  typeToggle: any;
  actionToggle: any;
  getDetailDocument:any;
  getComplaintCustDetail:any;
}

class ModalDetailDocContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.state = {};
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  handleCloseModal() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle(null);
  }
  render() {
    return (
      <React.Fragment>
        <ModalDetailDocComponent
          showModal={
            this.props.showModal === true &&
            this.props.typeToggle === 'detailDocComplaint'
              ? true
              : false
          }
          typeToggle={this.props.typeToggle}
          handleCloseModal={this.handleCloseModal}
          getDetailDocument={this.props.getDetailDocument}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  getDetailDocument: state.Complaint.addComplaintState.detailDocument,
  getComplaintCustDetail: state.Complaint.addComplaintState.complaintCustDetail,
  showModal: state.Toggle.modal,
  typeToggle: state.Toggle.jenisToggle,
  isLoading: state.Toggle.isLoading,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ModalDetailDocContainer);
