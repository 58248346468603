import axios from "axios";
import { store } from "../core/store/ConfigStore";

export const getAllCustomerProduct = async () => {
  const token = store.getState().Auth.Token;
  const getCustomerId = store.getState().Auth.CustomerId;
  const getUsername = store.getState().Auth.UsernameAuth;

  const config = {
    Authorization: `bearer ${token}`,
    "Content-type": "application/json",
  };

  try {
    return await axios.get(
      process.env.REACT_APP_URL +
        `/Customer/portal/customerproductportal/${getUsername}/${getCustomerId}`,
      {
        headers: config,
      }
    );
  } catch (error) {
    return error;
  }
};

export const downloadWaybillDigital = async (
  data: any,
  onDownloadProgress: any
) => {
  const token = store.getState().Auth.Token;

  const config = {
    Authorization: `bearer ${token}`,
    "Content-type": "application/json",
  };

  try {
    return await axios.post(
      process.env.REACT_APP_URL + `/Delivery/Portal/DownloadWaybillDigital`,
      data,
      {
        headers: config,
        responseType: "arraybuffer",
        onDownloadProgress,
      }
    );
  } catch (error) {
    return error;
  }
};
