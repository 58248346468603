import * as Complaint from '../../core/action/Complaint/ActionComplaint';
import * as ListResultSearch from '../../core/action/SearchDocument/ActionListResultSearch';
import * as PropertiesSearchResult from '../../core/action/SearchDocument/ActionPropertiesSearchResult';
import * as Report from '../../core/action/Report/index';
import * as SearchDocument from '../../core/action/SearchDocument/ActionSearchDocumentForm';

import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import history from '../utils/History';

interface IProps {
  token: any;
  router: any;
  actionSearchDocument: any;
  actionListResultSearch: any;
  actionPropertiesSearchResult: any;
  actionComplaint: any;
  actionReport: any;
}

function redirectLogin() {
  history.push('/login');
}
function redirectDashboard() {
  history.push('/');
}
export function CheckToken(ComposeComponent: any) {
  class ComposedCheckToken extends Component<IProps> {
    public componentDidMount() {
      this._checkAndRedirect();
    }

    public componentDidUpdate() {
      this._checkAndRedirect();
    }

    public _checkAndRedirect() {
      const { token } = this.props;
      if (token === null) {
        redirectLogin();
      }
      this.props.actionSearchDocument.clearSearchType();
      this.props.actionSearchDocument.clearSearchValue();
      this.props.actionSearchDocument.clearWaybill();
      this.props.actionSearchDocument.clearBarcode();
      this.props.actionListResultSearch.clearListResultSearch();
      this.props.actionPropertiesSearchResult.clearSearchResultDocument();
      this.props.actionReport.clearCustomerReportForm();
      this.props.actionComplaint.clearAllComplaintForm();
      this.props.actionComplaint.clearSearchFormComplaint();
      this.props.actionComplaint.clearComplaintProcess();
      this.props.actionComplaint.clearFormDetailD();
      this.props.actionComplaint.clearFormUpdate();
    }
    render() {
      return <ComposeComponent />;
    }
  }
  const mapStateToProps = (state: any) => ({
    token: state.Auth.Token,
    router: state.router.location.pathname,
  });

  const mapDispatchToProps = (dispatch: any) => ({
    actionSearchDocument: bindActionCreators(SearchDocument, dispatch),
    actionListResultSearch: bindActionCreators(ListResultSearch, dispatch),
    actionReport: bindActionCreators(Report, dispatch),
    actionPropertiesSearchResult: bindActionCreators(
      PropertiesSearchResult,
      dispatch
    ),
    actionComplaint: bindActionCreators(Complaint, dispatch),
  });

  return connect(mapStateToProps, mapDispatchToProps)(ComposedCheckToken);
}

export function CheckLogin(ComposeComponent: any) {
  class ComposedCheckLogin extends Component<IProps> {
    public componentDidMount() {
      this._checkAndRedirect();
    }

    public componentDidUpdate() {
      this._checkAndRedirect();
    }

    public _checkAndRedirect() {
      const { token } = this.props;
      if (token === null) {
      } else {
        redirectDashboard();
      }
    }
    render() {
      return <ComposeComponent />;
    }
  }
  const mapStateToProps = (state: any) => ({
    token: state.Auth.Token,
    router: state.router.location.pathname,
  });

  const mapDispatchToProps = (dispatch: any) => ({});
  return connect(mapStateToProps, mapDispatchToProps)(ComposedCheckLogin);
}
