import { Action } from 'redux';

const initialState = {
  propertiesComplaint: {
    complaintProcessType: null,
    complaintStep: 0,
  },
  formSearchComplaint: {
    firstDate: null,
    lastDate: null,
    typeStatus: null,
    complaintFilterSearch: 'NoPolis',
    searchValue: null,
  },
  addComplaintState: {
    filterSearch: 'NoPolis',
    searchValue: null,
    barcode: null,
    cycleDate: null,
    detailDocument: null,
    phoneNumber: null,
    complaintTitle: null,
    complaintCustDetail: null,
    documentList: [],
    countingFirebase: 0,
  },
  detailComplaint: {
    detail: [],
    codeTicket: null,
    status: null,
    phoneNumber: null,
    titleComplaint: null,
  },
  updateComplaint: {
    mode: null,
    description: null,
    fileImage: null,
  },
  complaintList: [],
  history: [],
  image: null,
};

export interface IAuthAction extends Action<string> {}

export interface IChangeComplaintProcessType extends IAuthAction {
  complaintProcessType: any;
}
export interface IChangeComplaintStep extends IAuthAction {
  complaintStep: any;
}
export interface IChangeAddFilterSearchComplaint extends IAuthAction {
  filterSearch: any;
}
export interface IChangeAddSearchValueComplaint extends IAuthAction {
  searchValue: any;
}
export interface IChangeBarcodeComplaint extends IAuthAction {
  barcode: any;
}
export interface IChangeCycleDateComplaint extends IAuthAction {
  cycleDate: any;
}
export interface IChangeDetailDocComplaint extends IAuthAction {
  detailDocument: any;
}
export interface IChangePhoneNumberComplaint extends IAuthAction {
  phoneNumber: any;
}
export interface IChangeComplaintTitle extends IAuthAction {
  complaintTitle: any;
}
export interface IChangeComplaintCustDetail extends IAuthAction {
  complaintCustDetail: any;
}
export interface ISetDocumentList extends IAuthAction {
  documentList: any;
}

export interface IChangeFirstDate extends IAuthAction {
  firstDate: any;
}

export interface IChangeLastDate extends IAuthAction {
  lastDate: any;
}

export interface IChangeTypeStatus extends IAuthAction {
  typeStatus: any;
}

export interface IChangeComplaintFilterSearch extends IAuthAction {
  complaintFilterSearch: any;
}

export interface IChangeSearchValue extends IAuthAction {
  searchValue: any;
}

export interface ISetComplaintList extends IAuthAction {
  complaintList: any;
}

export interface ISetDetailD extends IAuthAction {
  detail: any;
}

export interface ISetCodeTicketD extends IAuthAction {
  codeTicket: any;
}

export interface ISetStatusD extends IAuthAction {
  status: any;
}

export interface ISetPhoneNumberD extends IAuthAction {
  phoneNumber: any;
}

export interface ISetTitleComplaintD extends IAuthAction {
  titleComplaint: any;
}

export interface ISetModeU extends IAuthAction {
  mode: any;
}

export interface ISetDescU extends IAuthAction {
  description: any;
}

export interface ISetFileImageU extends IAuthAction {
  fileImage: any;
}

export interface ISetHistoryD extends IAuthAction {
  history: any;
}

export interface ISetImageHistory extends IAuthAction {
  image: any;
}

export interface ISetCountingFirebase extends IAuthAction {
  countingFirebase: any;
}

export default function AuthReducerLoginForm(
  state = initialState,
  action: IAuthAction
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_COMPLAINT_PROCESS_TYPE_FORM':
      const changeComplaintProcessType = action as IChangeComplaintProcessType;
      newState.propertiesComplaint.complaintProcessType =
        changeComplaintProcessType.complaintProcessType;

      return { ...newState };

    case 'CHANGE_COMPLAINT_STEP':
      const changeComplaintStep = action as IChangeComplaintStep;
      newState.propertiesComplaint.complaintStep =
        changeComplaintStep.complaintStep;

      return { ...newState };

    case 'CHANGE_FIRST_DATE_COMPLAINT_FORM':
      const changeFirstDate = action as IChangeFirstDate;
      newState.formSearchComplaint.firstDate = changeFirstDate.firstDate;

      return { ...newState };

    case 'CHANGE_LAST_DATE_COMPLAINT_FORM':
      const changeLastDate = action as IChangeLastDate;
      newState.formSearchComplaint.lastDate = changeLastDate.lastDate;

      return { ...newState };

    case 'CHANGE_TYPE_STATUS_COMPLAINT_FORM':
      const changeTypeStatus = action as IChangeTypeStatus;
      newState.formSearchComplaint.typeStatus = changeTypeStatus.typeStatus;

      return { ...newState };

    case 'CHANGE_FILTER_SEARCH_COMPLAINT_FORM':
      const changeComplaintFilterSearch =
        action as IChangeComplaintFilterSearch;
      newState.formSearchComplaint.complaintFilterSearch =
        changeComplaintFilterSearch.complaintFilterSearch;

      return { ...newState };

    case 'CHANGE_SEARCH_VALUE_COMPLAINT_FORM':
      const changeSearchValue = action as IChangeSearchValue;
      newState.formSearchComplaint.searchValue = changeSearchValue.searchValue;

      return { ...newState };

    case 'SET_COMPLAINT_LIST':
      const setListResultSearch = action as ISetComplaintList;
      newState.complaintList = setListResultSearch.complaintList;
      return { ...newState };

    case 'CLEAR_ALL_COMPLAINT_FORM':
      newState.formSearchComplaint.firstDate = null;
      newState.formSearchComplaint.lastDate = null;
      newState.formSearchComplaint.typeStatus = null;
      newState.formSearchComplaint.complaintFilterSearch = 'NoPolis';
      newState.formSearchComplaint.searchValue = null;
      newState.complaintList = [];
      return { ...newState };

    case 'CLEAR_COMPLAINT_FORM':
      newState.formSearchComplaint.firstDate = null;
      newState.formSearchComplaint.lastDate = null;
      newState.formSearchComplaint.typeStatus = null;
      newState.formSearchComplaint.searchValue = null;
      newState.complaintList = [];
      return { ...newState };

    case 'CLEAR_SEARCH_COMPLAINT_DU':
      newState.formSearchComplaint.firstDate = null;
      newState.formSearchComplaint.lastDate = null;
      newState.formSearchComplaint.typeStatus = null;
      newState.formSearchComplaint.complaintFilterSearch = 'NoPolis';
      newState.formSearchComplaint.searchValue = null;
      newState.complaintList = [];
      return { ...newState };

    case 'CLEAR_COMPLAINT_LIST':
      newState.complaintList = [];

      return { ...newState };
    case 'CLEAR_SEARCH_VALUE_COMPLAINT':
      newState.formSearchComplaint.searchValue = null;

      return { ...newState };

    case 'CLEAR_COMPLAINT_PROCESS':
      newState.propertiesComplaint.complaintProcessType = null;

      return { ...newState };

    case 'CLEAR_COMPLAINT_STEP':
      newState.propertiesComplaint.complaintStep = 0;

      return { ...newState };

    case 'CLEAR_DETAIL_DOCUMENT_COMPLAINT':
      newState.addComplaintState.detailDocument = null;

      return { ...newState };
    case 'CLEAR_DOCUMENT_LIST_COMPLAINT':
      newState.addComplaintState.documentList = [];

      return { ...newState };
    case 'CLEAR_SEARCH_FORM_COMPLAINT':
      newState.propertiesComplaint.complaintStep = 0;
      newState.addComplaintState.filterSearch = 'NoPolis';
      newState.addComplaintState.searchValue = null;
      newState.addComplaintState.barcode = null;
      newState.addComplaintState.cycleDate = null;
      newState.addComplaintState.documentList = [];
      newState.addComplaintState.countingFirebase = 0;
      return { ...newState };

    case 'CLEAR_ADD_FORM_COMPLAINT':
      newState.addComplaintState.phoneNumber = null;
      newState.addComplaintState.complaintTitle = null;
      newState.addComplaintState.complaintCustDetail = null;
      return { ...newState };

    case 'SET_DOCUMENT_LIST':
      const setDocumentList = action as ISetDocumentList;
      newState.addComplaintState.documentList = setDocumentList.documentList;
      return { ...newState };

    case 'CHANGE_COUNTING_FIREBASE':
      const setCountingFirebase = action as ISetCountingFirebase;
      newState.addComplaintState.countingFirebase = setCountingFirebase.countingFirebase;
      return { ...newState };

    case 'CHANGE_ADD_FILTER_SEARCH':
      const changeAddFilterSearch = action as IChangeAddFilterSearchComplaint;
      newState.addComplaintState.filterSearch = changeAddFilterSearch.filterSearch;
      return { ...newState };

    case 'CHANGE_ADD_SEARCH_VALUE':
      const changeAddSearchValue = action as IChangeAddSearchValueComplaint;
      newState.addComplaintState.searchValue = changeAddSearchValue.searchValue;
      return { ...newState };

    case 'CHANGE_BARCODE_COMPLAINT':
      const changeBarcodeComplaint = action as IChangeBarcodeComplaint;
      newState.addComplaintState.barcode = changeBarcodeComplaint.barcode;
      return { ...newState };

    case 'CHANGE_CYCLE_DATE_COMPLAINT':
      const changeCycleDateComplaint = action as IChangeCycleDateComplaint;
      newState.addComplaintState.cycleDate = changeCycleDateComplaint.cycleDate;
      return { ...newState };

    case 'CHANGE_DETAIL_DOCUMENT_COMPLAINT':
      const changeDetailDocumentComplaint = action as IChangeDetailDocComplaint;
      newState.addComplaintState.detailDocument =
        changeDetailDocumentComplaint.detailDocument;
      return { ...newState };

    case 'CHANGE_PHONE_NUMBER_COMPLAINT':
      const changePhoneNumberComplaint = action as IChangePhoneNumberComplaint;
      newState.addComplaintState.phoneNumber =
        changePhoneNumberComplaint.phoneNumber;
      return { ...newState };

    case 'CHANGE_TITLE_COMPLAINT':
      const changeTitleComplaint = action as IChangeComplaintTitle;
      newState.addComplaintState.complaintTitle =
        changeTitleComplaint.complaintTitle;
      return { ...newState };

    case 'CHANGE_CUST_DETAIL_COMPLAINT':
      const changeCustDetailComplaint = action as IChangeComplaintCustDetail;
      newState.addComplaintState.complaintCustDetail =
        changeCustDetailComplaint.complaintCustDetail;
      return { ...newState };

    case 'CHANGE_DETAIL_D':
      const changeDetailD = action as ISetDetailD;
      newState.detailComplaint.detail =
        changeDetailD.detail;
      return { ...newState };

    case 'CHANGE_CODE_TICKET_D':
      const changeCodeTicketD = action as ISetCodeTicketD;
      newState.detailComplaint.codeTicket =
        changeCodeTicketD.codeTicket;
      return { ...newState };

    case 'CHANGE_STATUS_D':
      const changeStatusD = action as ISetStatusD;
      newState.detailComplaint.status =
        changeStatusD.status;
      return { ...newState };

    case 'CHANGE_PHONE_NUMBER_D':
      const changePhoneNumberD = action as ISetPhoneNumberD;
      newState.detailComplaint.phoneNumber =
        changePhoneNumberD.phoneNumber;
      return { ...newState };

    case 'CHANGE_TITLE_COMPLAINT_D':
      const changeTitleComplaintD = action as ISetTitleComplaintD;
      newState.detailComplaint.titleComplaint =
        changeTitleComplaintD.titleComplaint;
      return { ...newState };

    case 'CHANGE_HISTORY_D':
      const changehistoryD = action as ISetHistoryD;
      newState.history =
        changehistoryD.history;
      return { ...newState };

    case 'CHANGE_IMAGE_HISTORY':
      const changeImageHistory = action as ISetImageHistory;
      newState.image =
        changeImageHistory.image;
      return { ...newState };

    case 'CLEAR_FORM_DETAIL_D':
      newState.detailComplaint.detail = [];
      newState.detailComplaint.codeTicket = null;
      newState.detailComplaint.status = null;
      newState.detailComplaint.phoneNumber = null;
      newState.detailComplaint.titleComplaint = null;
      newState.history = [];
      return { ...newState };

    case 'CHANGE_MODE_U':
      const changeModeU = action as ISetModeU;
      newState.updateComplaint.mode =
        changeModeU.mode;
      return { ...newState };

    case 'CHANGE_DESC_U':
      const changeDescU = action as ISetDescU;
      newState.updateComplaint.description =
      changeDescU.description;
      return { ...newState };

    case 'CHANGE_FILE_IMAGE_U':
      const changeFileImageU = action as ISetFileImageU;
      newState.updateComplaint.fileImage =
      changeFileImageU.fileImage;
      return { ...newState };

    case 'CLEAR_FORM_UPDATE':
      newState.updateComplaint.mode = null;
      newState.updateComplaint.description = null;
      newState.updateComplaint.fileImage = null;
      return { ...newState };

  }
  return state;
}
