import { Col, Row } from 'antd';
import React, { Component } from 'react';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';

interface IProps {
    getCodeTicket: any;
    getStatus: any;
    getNoTelp: any;
    getTitleComplaint: any;
}

export default class DetailFormComponent extends Component<IProps> {
  render() {
    return (
    <div className={"spacingCardComplaint"}>
        <div className="noTelpFormComplaint">
            <Row>
                <Col span={12}>
                    <div style={{ marginRight: 20 }}>
                        <span>
                            <b>Kode Tiket</b>
                        </span>
                        <br />
                        <FormInputTextMaterial
                            validateType={true}
                            value={this.props.getCodeTicket === null ? '-' : this.props.getCodeTicket}
                            className={'formDetailDefault'}
                            disabled={false}
                            addonBefore={''}
                            onPressEnter={''}
                            placeholder={'Masukan judul keluhan'}
                            prefix={''}
                            maxLength={200}
                            style={{}}
                            size=""
                            type={''}
                            onChange={''}
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{ marginLeft: 20 }}>
                        <span>
                            <b>Status</b>
                        </span>
                        <br />
                        <FormInputTextMaterial
                            validateType={true}
                            value={this.props.getStatus === null ? '-' : this.props.getStatus}
                            className={'formDetailDefault'}
                            disabled={false}
                            addonBefore={''}
                            onPressEnter={''}
                            placeholder={'Masukan judul keluhan'}
                            prefix={''}
                            maxLength={200}
                            style={{}}
                            size=""
                            type={''}
                            onChange={''}
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                        />
                    </div>
                </Col>
            </Row>
        </div>
        <div>
          <span>
            <b>No telp yang bisa dihubungi</b>
          </span>
          <br />
          <FormInputTextMaterial
            validateType={true}
            value={this.props.getNoTelp === null ? '-' : this.props.getNoTelp}
            className={'formDetailDefault'}
            disabled={true}
            addonBefore={'+62'}
            onPressEnter={''}
            placeholder={'Masukan no telp'}
            prefix={''}
            maxLength={15}
            style={{}}
            size=""
            type={''}
            onChange={''}
            onBlur={''}
            validatestatus={''}
            help={''}
          />
        </div>
        <div className="complaintTitleForm">
          <span>
            <b>Judul keluhan</b>
          </span>
          <br />
          <FormInputTextMaterial
            validateType={true}
            value={this.props.getTitleComplaint === null ? '-' : this.props.getTitleComplaint}
            className={'formDetailDefault'}
            disabled={false}
            addonBefore={''}
            onPressEnter={''}
            placeholder={'Masukan judul keluhan'}
            prefix={''}
            maxLength={200}
            style={{}}
            size=""
            type={''}
            onChange={''}
            onBlur={''}
            validatestatus={''}
            help={''}
          />
        </div>
    </div>
    );
  }
}