import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import IndexDetailComponent from '../../../../components/Content/Complaint/DetailComplaint/IndexDetailComponent';
import * as ActionComplaint from '../../../../core/action/Complaint/ActionComplaint';

interface IProps {
    getHistory: any;
    getDetail: any;
    actionComplaint: any;
    isLoading: any;
}

class IndexDetailContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
  }
  handleBack() {
    this.props.actionComplaint.clearSearchFormComplaint();
    this.props.actionComplaint.clearFormDetailD();
    this.props.actionComplaint.clearComplaintProcess();
    this.props.actionComplaint.changeFirstDate(null);
    this.props.actionComplaint.changeLastDate(null);
  }
  render() {
    return (
      <React.Fragment>
        <IndexDetailComponent
            getDetail={this.props.getDetail}
            getHistory={this.props.getHistory}
            handleBack={this.handleBack}
            isLoading={this.props.isLoading}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
    getDetail: state.Complaint.detailComplaint.detail,
    getHistory: state.Complaint.history,
  isLoading: state.Toggle.isLoading,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionComplaint: bindActionCreators(ActionComplaint,dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(IndexDetailContainer);