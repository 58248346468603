import '../../styles/LoginStyle/index.css';

import { Col, Row } from 'antd';
import React, { Component } from 'react';

import CardMaterial from '../../materials/CardMaterial';
import LoginContainer from '../../containers/Login/LoginContainer';
import LoginImageComponent from '../../components/Login/LoginImageComponent';
import SpinMaterial from '../../materials/SpinMaterial';

interface IProps
{
    isLoading:any
}

export default class BerandaComponent extends Component <IProps> {
    render() {
        return (
            <div className="BackgroundLogin">
                <SpinMaterial
                spinning={this.props.isLoading}
                size={'large'}
                >
                <div style={{display:'flex', justifyContent:'center', position: 'relative', paddingTop:'10%'}}>
                <CardMaterial
                className={''}
                    extra={""}
                    title={""}
                    style={{borderRadius:'16px', background:'white',
                    height:'405px', width:'852px'}}
                    content={
                        <div>
                            <Row>
                                    <Col span={12} style={{paddingRight:'20px'}}>
                                    <LoginImageComponent/>
                                    </Col>

                                    <Col span={12} style={{paddingLeft:'20px'}}>
                                        <LoginContainer/>
                                    </Col>
                            </Row>
                        </div>
                    }
                />
                </div>
                <Row>
                    <Col span={24} style={{textAlign:'center', paddingRight:'250px'}}>
                        <span style={{color:'#FAFAFA',position:'fixed', bottom:'10px'}}>©CopyRight 2020, Design by QSI</span>
                    </Col>
                </Row>
                </SpinMaterial>
            </div>
        )
    }
}
