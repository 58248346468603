/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import "../../../index.css";
import "../../../styles/SearchDocumentStyle/index.css";
import "moment/locale/id";

import { Card, Col, Collapse, Row, Tag, Button, Tabs } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Form, Field } from "react-final-form";

import {
  EnumContenTrackingDelivery,
  EnumHeaderTrackingDelivery,
} from "../../../containers/Content/SearchDocument/EnumTrackingDelivery";
import React from "react";

import ButtonComponent from "../../../materials/ButtonMaterial";
import CardMaterial from "../../../materials/CardMaterial";
import FormInputTextMaterial from "../../../materials/FormInputTextMaterial";
import ModalMaterial from "../../../materials/ModalMaterial";
import SearchImage from "../../../image/SearchImage.png";
import SpinMaterial from "../../../materials/SpinMaterial";
import moment from "moment";
import SelectField from "../../../materials/SelectField";
import { optionsFilterDelivery } from "../../../lib/constant";
import { EnumAccess } from "../../../core/enumerable/General";
import InputDatePicker from "../../../materials/InputDatePicker";
import { SearchValueType } from "../../../containers/Content/SearchDocument/SearchDocumentContainer";

moment.locale("id");
const { TabPane } = Tabs;

interface IProps {
  handleModalPhoto: any;
  handleModalSignature: any;
  handleCloseModal: any;
  getImage: any;
  getSignature: any;
  getAccessContent: any;
  visible: any;
  typeToggle: any;
  isLoading: any;
  isLoadingDownload: boolean;
  onClickDownloadCustom: ({
    id,
    waybill,
  }: {
    id: number;
    waybill: string;
  }) => void;
  onClickDownloadWaybill: (waybill: string) => void;
  initialValue: SearchValueType;
  handleChangeField: (name: string, value: any) => void;
  handleSubmit: any;
  listResultSearch: any[] | null;
  notFoundValue: string;
}
const { Panel } = Collapse;

export default function SeacrhDocumentComponent({
  initialValue,
  handleChangeField,
  handleSubmit,
  isLoading,
  isLoadingDownload,
  listResultSearch,
  getAccessContent,
  handleModalPhoto,
  handleModalSignature,
  onClickDownloadWaybill,
  onClickDownloadCustom,
  typeToggle,
  visible,
  handleCloseModal,
  getSignature,
  getImage,
  notFoundValue,
}: IProps) {
  const placeHolderSearch = `input hingga 10 ${
    optionsFilterDelivery.find(
      (option) => option.value === initialValue.typeSearch
    )?.label
  }:\nrds1234express\nrds2345express\n....`;

  const showImgValue = (img: any) => {
    if (img !== null) {
      const showImgValue =
        img.substring(0, 4) !== "http" ? `data:image/jpeg;base64,${img}` : img;
      return showImgValue;
    } else {
      return img;
    }
  };
  const styles = {
    cardBody: {
      maxHeight: "420px",
      overflow: "auto",
    },
  };

  const showFilterLabel = (isi: any) => {
    const label = isi.split(/(?=[A-Z])/).join(" ");
    return label;
  };

  return (
    <React.Fragment>
      <SpinMaterial spinning={isLoading} size={"large"}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            paddingTop: "4%",
            alignItems: "center",
            gap: 32,
          }}
        >
          <Form
            validate={(values) => {
              const errors = {} as any;
              const RegexMinChar = /^.{7,}$/;
              const selectedType = optionsFilterDelivery.find(
                (option) => option.value === values.typeSearch
              );
              const linesValueSearch =
                values.valueSearch[values.typeSearch].split("\n");
              const isValid = linesValueSearch.every((line: string) =>
                RegexMinChar.test(line)
              );

              if (!values.valueSearch[values.typeSearch]) {
                errors.valueSearch = `${selectedType?.label} wajib diisi`;
              }
              if (linesValueSearch.length > 10) {
                errors.valueSearch = `Maksimal pencarian 10 ${selectedType?.label}`;
              }
              if (
                values.typeSearch === "waybill" &&
                values.valueSearch[values.typeSearch] &&
                !isValid
              ) {
                errors.valueSearch = "Waybill kurang dari 7";
              }

              if (values.typeSearch !== "waybill") {
                if (
                  !!values.datePeriode[values.typeSearch] &&
                  values.datePeriode[values.typeSearch].length !== 0 &&
                  (!values.datePeriode[values.typeSearch][0] ||
                    !values.datePeriode[values.typeSearch][1])
                ) {
                  errors.datePeriode = "Periode Bulan wajib diisi";
                }
              }
              return errors;
            }}
            initialValues={{ ...initialValue }}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, valid }) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "40%",
                  alignItems: "center",
                  gap: 32,
                }}
              >
                <img
                  style={{
                    width: "320px",
                    position: "relative",
                  }}
                  src={SearchImage}
                />

                <Field name="typeSearch">
                  {() => (
                    <Tabs
                      defaultActiveKey={initialValue.typeSearch}
                      onChange={(value) =>
                        handleChangeField("typeSearch", value)
                      }
                    >
                      {optionsFilterDelivery.map((option) => (
                        <TabPane
                          tab={option.label}
                          key={option.value}
                        ></TabPane>
                      ))}
                    </Tabs>
                  )}
                </Field>

                {initialValue.typeSearch !== "waybill" && (
                  <div
                    className="group-addon-fefore"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <Field name="datePeriode">
                      {({ input, meta }) => (
                        <InputDatePicker
                          firstPlaceholder="Dari bulan"
                          secondPlaceholder="Sampai bulan"
                          type="range"
                          format="DD MMMM YYYY"
                          value={
                            initialValue.datePeriode[initialValue.typeSearch]
                          }
                          onChange={(value) =>
                            handleChangeField("datePeriode", value)
                          }
                          onCalendarChange={(value) =>
                            handleChangeField("datePeriode", value)
                          }
                          disabledDate={(current: any) => {
                            const maxBackdate = moment().subtract(3, "months");
                            const maxRange = 31;

                            // Disable dates that are earlier than 3 months ago
                            if (
                              current &&
                              current < maxBackdate.startOf("day")
                            ) {
                              return true;
                            }

                            // Disable dates after 31 days from the start date
                            if (
                              initialValue.datePeriode[
                                initialValue.typeSearch
                              ] &&
                              current
                            ) {
                              const tooLate =
                                initialValue.datePeriode[
                                  initialValue.typeSearch
                                ][0] &&
                                current.diff(
                                  initialValue.datePeriode[
                                    initialValue.typeSearch
                                  ][0],
                                  "days"
                                ) >
                                  maxRange - 1;
                              return tooLate;
                            }

                            return false;
                          }}
                          addonBefore={
                            <Field name="filterDateType">
                              {() => (
                                <SelectField
                                  data={[
                                    {
                                      value: "Process",
                                      label: "Pickup",
                                    },
                                    {
                                      value: "Cycle",
                                      label: "Cycle",
                                    },
                                  ]}
                                  value={initialValue.filterDateType}
                                  onChange={(value) =>
                                    handleChangeField("filterDateType", value)
                                  }
                                  style={{ width: 100 }}
                                />
                              )}
                            </Field>
                          }
                          onBlur={input.onBlur}
                          error={meta.error && meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </div>
                )}

                <Field name="valueSearch">
                  {({ input, meta }) => (
                    <FormInputTextMaterial
                      validateType={true}
                      value={initialValue.valueSearch[initialValue.typeSearch]}
                      placeholder={placeHolderSearch}
                      formStyle={{ margin: 0, width: "100%" }}
                      onChange={(e: any) =>
                        handleChangeField("valueSearch", e.target.value)
                      }
                      onBlur={input.onBlur}
                      validatestatus={meta.error && meta.touched ? "error" : ""}
                      help={meta.error && meta.touched && meta.error}
                      type="textArea"
                      rows={4}
                    />
                  )}
                </Field>
                <ButtonComponent
                  disabled={!valid}
                  onClick={handleSubmit}
                  type={"primary"}
                  shape="round"
                  style={{
                    width: 120,
                    borderRadius: 50,
                    padding: "8px 24px 8px 24px",
                    height: "auto",
                  }}
                >
                  <b>Cari</b>
                </ButtonComponent>
              </div>
            )}
          </Form>
          <Row style={{ textAlign: "center", width: "100%" }}>
            <Col span={24}>
              {listResultSearch && listResultSearch.length > 0 ? (
                <div>
                  {listResultSearch.map((data: any, index: any) => (
                    <div
                      style={{
                        paddingLeft: "200px",
                        paddingRight: "200px",
                        paddingTop: "10px",
                      }}
                      key={index}
                    >
                      <Collapse
                        style={{
                          borderRadius: "30px",
                          marginBottom: "30px",
                        }}
                      >
                        <Panel
                          header={
                            <span
                              style={{
                                color:
                                  data.deliveryStatus === "Success"
                                    ? "#32CD32"
                                    : data.deliveryStatus === "Return"
                                    ? "red"
                                    : data.deliveryStatus === "OnHold" ||
                                      data.deliveryStatus === "OnProcces"
                                    ? "#11BEFF"
                                    : "grey",
                              }}
                            >
                              <b>
                                {data?.keySearch +
                                  " - " +
                                  data?.customerProductName +
                                  " - " +
                                  data.cycle}
                              </b>
                            </span>
                          }
                          key="1"
                        >
                          {/* List data pengiriman */}
                          <Row style={{ textAlign: "left" }}>
                            <Col span={16} style={{ paddingRight: 12 }}>
                              <Row>
                                <Col span={6}>
                                  <p>Waybill</p>
                                </Col>
                                <Col span={2}>
                                  <p>:</p>
                                </Col>
                                <Col span={16}>
                                  <p>
                                    <b>{data.waybillCode}</b>
                                  </p>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={6}>
                                  <p>Nama Pemilik</p>
                                </Col>
                                <Col span={2}>
                                  <p>:</p>
                                </Col>
                                <Col span={16}>
                                  <p>
                                    <b>{data.shipToName}</b>
                                  </p>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={6}>
                                  <p>No Tlpn</p>
                                </Col>
                                <Col span={2}>
                                  <p>:</p>
                                </Col>
                                <Col span={16}>
                                  <p>
                                    <b>
                                      {data.shipToPhone === null ||
                                      data.shipToPhone === "" ||
                                      data.shipToPhone === undefined
                                        ? "-"
                                        : data.shipToPhone}
                                    </b>
                                  </p>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={6}>
                                  <p>Alamat</p>
                                </Col>
                                <Col span={2}>
                                  <p>:</p>
                                </Col>
                                <Col span={16}>
                                  <p
                                    style={{
                                      wordWrap: "break-word",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {data.shipToAddress.substring(0, 1) === "{"
                                      ? `${Object.values(
                                          JSON.parse(data.shipToAddress)
                                        ).join(" ")}`
                                      : data.shipToAddress}
                                  </p>
                                </Col>
                              </Row>

                              {data.filterType === null ||
                              data.filterType === "Empty" ? (
                                ""
                              ) : (
                                <Row>
                                  <Col span={6}>
                                    <p>{showFilterLabel(data.filterType)}</p>
                                  </Col>
                                  <Col span={2}>
                                    <p>:</p>
                                  </Col>
                                  <Col span={16}>
                                    <p>
                                      <b>
                                        {data.valueFilter === null ||
                                        data.valueFilter === "" ||
                                        data.valueFilter === undefined
                                          ? "-"
                                          : data.valueFilter}
                                      </b>
                                    </p>
                                  </Col>
                                </Row>
                              )}
                              <Row>
                                <Col span={6}>
                                  <p>Status</p>
                                </Col>
                                <Col span={2}>
                                  <p>:</p>
                                </Col>
                                <Col span={16}>
                                  <p>
                                    <b>
                                      <Tag
                                        color={
                                          data.deliveryStatus === "Success"
                                            ? "green"
                                            : data.deliveryStatus === "Return"
                                            ? "red"
                                            : data.deliveryStatus === "Return3x"
                                            ? "red"
                                            : "gray"
                                        }
                                      >
                                        {data.deliveryStatus === "Return" ? (
                                          <span style={{ fontSize: "14px" }}>
                                            Dikembalikan
                                          </span>
                                        ) : data.deliveryStatus ===
                                          "Return3x" ? (
                                          <span style={{ fontSize: "14px" }}>
                                            Dikembalikan
                                          </span>
                                        ) : data.deliveryStatus ===
                                          "Success" ? (
                                          <span style={{ fontSize: "14px" }}>
                                            Diterima
                                          </span>
                                        ) : data.deliveryStatus === "OnHold" ? (
                                          <span style={{ fontSize: "14px" }}>
                                            On Hold
                                          </span>
                                        ) : (
                                          <span style={{ fontSize: "14px" }}>
                                            Dalam Proses
                                          </span>
                                        )}
                                      </Tag>
                                    </b>
                                    <b>
                                      {data.deliveryStatus === "Return3x"
                                        ? "ke-3"
                                        : ""}
                                    </b>
                                  </p>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={6}>
                                  <p>Lama Pengiriman</p>
                                </Col>
                                <Col span={2}>
                                  <p>:</p>
                                </Col>
                                <Col span={16}>
                                  <p>
                                    <b>
                                      {data.estimatedDelivery + " Hari Kerja"}
                                    </b>
                                  </p>
                                </Col>
                              </Row>

                              {(data.deliveryStatus === "Success" ||
                                data.deliveryStatus === "Return") && (
                                <Row>
                                  <Col span={6}>
                                    {data.deliveryStatus === "Success" ? (
                                      <p>Nama Penerima</p>
                                    ) : data.deliveryStatus === "Return" ? (
                                      <p>Alasan Dikembalikan</p>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                  <Col span={2}>
                                    <p>:</p>
                                  </Col>
                                  <Col span={16}>
                                    <b>
                                      {data.deliveryStatus === "Success" ? (
                                        <p>
                                          {data.receiverName} ({" "}
                                          {data.receiverTitle} )
                                        </p>
                                      ) : data.deliveryStatus === "Return" ? (
                                        <p>{data.returnedReason}</p>
                                      ) : (
                                        ""
                                      )}
                                    </b>
                                  </Col>
                                </Row>
                              )}

                              {(data.deliveryStatus === "Success" ||
                                data.deliveryStatus === "Return") &&
                                getAccessContent.map(
                                  (value: any) =>
                                    value === 11 && (
                                      <Row>
                                        <Col span={6}>
                                          <p>Foto</p>
                                        </Col>
                                        <Col span={2}>
                                          <p>:</p>
                                        </Col>
                                        <Col span={16}>
                                          {data.image === null ||
                                          data.image === "" ||
                                          data.image === undefined ? (
                                            <b>{"-"}</b>
                                          ) : (
                                            <a
                                              onClick={() =>
                                                handleModalPhoto(
                                                  data.image,
                                                  "imageResult"
                                                )
                                              }
                                            >
                                              Lihat Foto
                                            </a>
                                          )}
                                        </Col>
                                      </Row>
                                    )
                                )}

                              {data.deliveryStatus === "Success" &&
                                getAccessContent.map(
                                  (value: any) =>
                                    value === 12 && (
                                      <Row>
                                        <Col span={6}>
                                          <p>Tanda Tangan</p>
                                        </Col>
                                        <Col span={2}>
                                          <p>:</p>
                                        </Col>
                                        <Col span={16}>
                                          {data.signature === null ||
                                          data.signature === "" ||
                                          data.signature === undefined ? (
                                            <b>{"-"}</b>
                                          ) : (
                                            <a
                                              onClick={() =>
                                                handleModalSignature(
                                                  data.signature,
                                                  "signatureResult"
                                                )
                                              }
                                            >
                                              Lihat Tanda Tangan
                                            </a>
                                          )}
                                        </Col>
                                      </Row>
                                    )
                                )}

                              <Row>
                                <Col span={6}>
                                  <p>Kurir</p>
                                </Col>
                                <Col span={2}>
                                  <p>:</p>
                                </Col>
                                <Col span={16}>
                                  <p>
                                    <b>
                                      {data.isPartner === true
                                        ? "RDS Express"
                                        : data.courierName === null &&
                                          data.courierCode === null
                                        ? ""
                                        : data.courierName !== null &&
                                          data.courierCode === null
                                        ? `${data.courierName} ( - )`
                                        : data.courierName !== null &&
                                          data.courierCode !== null
                                        ? `${data.courierName} ( ${data.courierCode} )`
                                        : ""}
                                    </b>
                                  </p>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={6}>
                                  <p>Area</p>
                                </Col>
                                <Col span={2}>
                                  <p>:</p>
                                </Col>
                                <Col span={16}>
                                  <p>
                                    <b>
                                      {data.district === null ||
                                      data.district === 0 ||
                                      data.district === "0"
                                        ? "-"
                                        : data.district}
                                    </b>
                                  </p>
                                </Col>
                              </Row>

                              {getAccessContent.includes(
                                EnumAccess[13].value
                              ) && (
                                <Row gutter={16}>
                                  <Col span={7}>
                                    <SelectField
                                      placeholder="Pilih Download"
                                      value="walbillCustomer"
                                      data={[
                                        {
                                          value: "walbillCustomer",
                                          label: "Waybill Pelanggan",
                                        },
                                      ]}
                                    />
                                  </Col>

                                  <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<DownloadOutlined />}
                                    style={{
                                      background: "#7956EE",
                                      border: "solid 1px #7956EE",
                                    }}
                                    loading={isLoadingDownload}
                                    onClick={() => {
                                      if (
                                        initialValue.typeSearch === "waybill"
                                      ) {
                                        onClickDownloadWaybill(
                                          data.waybillCode
                                        );
                                      } else {
                                        onClickDownloadCustom({
                                          id: data.id,
                                          waybill: data.waybillCode,
                                        });
                                      }
                                    }}
                                  />
                                </Row>
                              )}
                            </Col>

                            {/* List riwayat pengiriman */}
                            <Col
                              span={8}
                              style={{
                                borderLeftWidth: "1px",
                                borderLeftColor: "#D3D3D3",
                                borderLeftStyle: "solid",
                              }}
                            >
                              <Card
                                bodyStyle={styles.cardBody}
                                style={{
                                  borderRadius: "10px",
                                  // height: '520px',
                                  maxHeight: "100%",
                                  marginLeft: "16px",
                                }}
                              >
                                {data.history === null ? (
                                  <div
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                      display: "flex",
                                      height: "100%",
                                    }}
                                  >
                                    <p style={{ color: "#969696" }}>
                                      Riwayat tidak ada
                                    </p>
                                  </div>
                                ) : (
                                  data.history.map((i: any, index: any) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Row
                                        className={
                                          data.history.length - 1 === index
                                            ? ""
                                            : "line"
                                        }
                                      >
                                        <Col span={5}>
                                          <div
                                            style={{
                                              borderRadius: "4px",
                                              background:
                                                i.position === 2 && index !== 0
                                                  ? "#fb8c00"
                                                  : i.position > 2 &&
                                                    index !== 0
                                                  ? "#FF5858"
                                                  : index === 0
                                                  ? "#F5F5F5"
                                                  : "#7956EE",
                                              textAlign: "center",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 13,
                                                color:
                                                  index === 0 &&
                                                  i.itemStatus === "Resend" &&
                                                  i.position > 2
                                                    ? "#000000"
                                                    : index === 0 &&
                                                      i.itemStatus ===
                                                        "Resend" &&
                                                      i.position <= 2
                                                    ? "#000000"
                                                    : index === 0 &&
                                                      i.itemStatus !== "Resend"
                                                    ? "#000000"
                                                    : "#FFFFFF",
                                              }}
                                            >
                                              <div>
                                                {moment(
                                                  i.date === null ||
                                                    i.date === undefined
                                                    ? i.timeStamp
                                                    : i.date
                                                ).format("DD-MMM")}
                                              </div>
                                              <div>
                                                {moment(
                                                  i.date === null ||
                                                    i.date === undefined
                                                    ? i.timeStamp
                                                    : i.date
                                                ).format("HH:mm")}
                                              </div>
                                            </span>
                                          </div>
                                        </Col>
                                        <Col span={19}>
                                          <div
                                            style={{
                                              paddingBottom: "15px",
                                              marginLeft: "15px",
                                            }}
                                          >
                                            <div>
                                              <div>
                                                <b
                                                  style={{
                                                    color: "#969696",
                                                  }}
                                                >
                                                  {EnumHeaderTrackingDelivery(
                                                    i
                                                  )}
                                                </b>
                                              </div>
                                              <div>
                                                <Row>
                                                  <Col span={24}>
                                                    <div
                                                      style={{
                                                        color: "black",
                                                      }}
                                                    >
                                                      {EnumContenTrackingDelivery(
                                                        i,
                                                        data.isPartner
                                                      )}
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                              <div>
                                                {i.signature !== null &&
                                                  getAccessContent.map(
                                                    (value: any) =>
                                                      value === 12 && (
                                                        <a
                                                          style={{
                                                            paddingRight:
                                                              "10px",
                                                          }}
                                                          onClick={() =>
                                                            handleModalSignature(
                                                              i.signature,
                                                              "signatureHistory"
                                                            )
                                                          }
                                                        >
                                                          Lihat TTD
                                                        </a>
                                                      )
                                                  )}
                                                {i.image !== null &&
                                                  getAccessContent.map(
                                                    (value: any) =>
                                                      value === 11 && (
                                                        <a
                                                          onClick={() =>
                                                            handleModalPhoto(
                                                              i.image,
                                                              "imageHistory"
                                                            )
                                                          }
                                                        >
                                                          Lihat Foto
                                                        </a>
                                                      )
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))
                                )}
                              </Card>
                            </Col>

                            <ModalMaterial
                              className={""}
                              width={"40%"}
                              style={{ paddingTop: "20px" }}
                              teksTitle={"FOTO"}
                              visible={
                                typeToggle === "imageResult"
                                  ? visible === true &&
                                    typeToggle === "imageResult"
                                    ? true
                                    : false
                                  : typeToggle === "imageHistory"
                                  ? visible === true &&
                                    typeToggle === "imageHistory"
                                    ? true
                                    : false
                                  : ""
                              }
                              onCancel={handleCloseModal}
                              content={
                                <div>
                                  <img
                                    className="image-gallery-slide"
                                    alt="imagePhoto"
                                    src={showImgValue(getImage)}
                                  />
                                </div>
                              }
                            />

                            <ModalMaterial
                              className={""}
                              width={"40%"}
                              style={{ paddingTop: "20px" }}
                              teksTitle={"TANDA TANGAN"}
                              visible={
                                typeToggle === "signatureResult"
                                  ? visible === true &&
                                    typeToggle === "signatureResult"
                                    ? true
                                    : false
                                  : typeToggle === "signatureHistory"
                                  ? visible === true &&
                                    typeToggle === "signatureHistory"
                                    ? true
                                    : false
                                  : ""
                              }
                              onCancel={handleCloseModal}
                              content={
                                <div>
                                  <img
                                    className="image-gallery-slide"
                                    src={`data:image/jpeg;base64,${getSignature}`}
                                  />
                                </div>
                              }
                            />
                          </Row>
                        </Panel>
                      </Collapse>
                    </div>
                  ))}
                </div>
              ) : (
                !isLoading &&
                notFoundValue && (
                  <div>
                    <div
                      style={{
                        paddingLeft: "200px",
                        paddingRight: "200px",
                        paddingTop: "10px",
                      }}
                    >
                      <CardMaterial
                        className={""}
                        extra={""}
                        title={""}
                        style={{
                          borderRadius: "16px",
                          background: "white",
                          marginBottom: "30px",
                        }}
                        content={
                          <p style={{ color: "grey", margin: 0 }}>
                            <b>{notFoundValue} - Tidak Ditemukan</b>
                          </p>
                        }
                      />
                    </div>
                  </div>
                )
              )}
            </Col>
          </Row>
        </div>
      </SpinMaterial>
    </React.Fragment>
  );
}
