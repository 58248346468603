export const complaintStatusObj = {
  Proses: "Proses", //0
  Selesai: "Selesai", //1
  DalamAntrian: "Dalam Antrian", //2
  KonfirmasiPelanggan: "Konfirmasi Pelanggan", //3
  Empty: "Empty", //4
  Review: "Review", //5
};

export const getObjectKey = (obj: any, value: string) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const downloadBlob = (
  data: any,
  fileName: string,
  extension = "pdf"
) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  const file_name = `${fileName}.${extension}`;
  link.href = url;
  link.setAttribute("download", file_name);
  document.body.appendChild(link);
  link.click();
};

export const processRequest =
  (setLoadingProgress: any) => (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setLoadingProgress(percentCompleted);
  };
