import axios from "axios";
import { store } from "../core/store/ConfigStore";

export const downloadPackageCustomSearch = async ({
  id,
  waybill,
}: {
  id: number;
  waybill: string;
}) => {
  const token = store.getState().Auth.Token;

  const config = {
    Authorization: `bearer ${token}`,
    "Content-type": "application/json",
  };

  try {
    const response = await axios.post(
      process.env.REACT_APP_URL +
        `/Delivery/DownloadAirWaybillPdf/CustomSearch?Id=${id}`,
      {},
      {
        headers: config,
        responseType: "arraybuffer",
      }
    );

    return {
      ...response,
      waybill,
    };
  } catch (error) {
    return error;
  }
};

export const downloadPackageWaybill = async (waybill: string) => {
  const token = store.getState().Auth.Token;

  const config = {
    Authorization: `bearer ${token}`,
    "Content-type": "application/json",
  };

  try {
    const response = await axios.post(
      process.env.REACT_APP_URL +
        `/Delivery/Portal/DownloadAirWaybillPdf?waybill=${waybill}`,
      {},
      {
        headers: config,
        responseType: "arraybuffer",
      }
    );

    return {
      ...response,
      waybill,
    };
  } catch (error) {
    return error;
  }
};
