import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import DataWaybillComponent from '../../../components/Content/Complaint/DataWaybillComponent';

interface IProps {
    getDetailDocument: any;
    getComplaintProcess: any;
}

class DataWaybillContainer extends Component<IProps> {
  render() {
    return (
      <React.Fragment>
        <DataWaybillComponent
            getDetailDocument={this.props.getDetailDocument}
            getComplaintProcess={this.props.getComplaintProcess}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
    getComplaintProcess: state.Complaint.propertiesComplaint.complaintProcessType,
});
const mapDispatchToProps = (dispatch: any) => ({
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(DataWaybillContainer);