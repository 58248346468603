import { Col, DatePicker, Form, Row, Select, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';
import React, { Component } from 'react';
import ButtonComplaintContainer from '../../../../containers/Content/Complaint/AddComplaint/ButtonComplaintContainer';
import ModalDetailDocContainer from '../../../../containers/Content/Complaint/AddComplaint/ModalDetailDocContainer';
import ButtonComponent from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';

const { Option } = Select;

interface IProps {
  handleChangeFilterSearch: any;
  handlePutBarcode: any;
  handlePutSearchValue: any;
  getBarcode: any;
  getSearchValue: any;
  handlePutCycleDate: any;
  getCycleDate: any;
  getFilterSearch: any;
  handleSubmit: any;
  getDocumentList: any;
  handlePage: any;
  page: any;
}

export default class SearchDocForComplaintComponent extends Component<IProps> {
  render() {
    const formatDateShow = 'DD-MM-YYYY';
    const prefixSelector = (
      <Select
        defaultValue="No Polis"
        onChange={this.props.handleChangeFilterSearch}
        value={
          this.props.getFilterSearch === 'NoPolis'
            ? 'No Polis'
            : this.props.getFilterSearch
        }
      >
        <Option value="NoPolis">No Polis</Option>
        <Option value="Barcode">Barcode</Option>
        <Option value="Waybill">Waybill</Option>
        <Option value="NoReferensi">Nomor Referensi</Option>
        <Option value="NoRekening">Nomor Rekening</Option>
      </Select>
    );
    const validateBtn =
      this.props.getFilterSearch === 'Waybill'
        ? this.props.getSearchValue === null || this.props.getSearchValue === ''
          ? true
          : false
        : this.props.getSearchValue === null ||
          this.props.getSearchValue === '' ||
          this.props.getCycleDate === null ||
          this.props.getCycleDate === undefined ||
          this.props.getCycleDate === 'Invalid date'
        ? true
        : false;

    const looppage = (this.props.page - 1) * 10 + 1;
    const columns = [
      {
        title: <b>No</b>,
        align: 'center' as 'center',
        width: 50,
        render: (text: any, record: any, index: any) => (
          <span>{this.props.page > 1 ? index + looppage : index + 1}</span>
        ),
        key: 'no',
      },
      {
        title: <b>Tanggal Cycle</b>,
        align: 'center' as 'center',
        dataIndex: 'cycle',
        key: 'cycle',
        render: (cycle: any) => (
          <span style={{ color: 'red' }}>
            {moment(cycle).format('YYYYMMDD')}
          </span>
        ),
        width: 120,
      },
      {
        title: <b>Barcode</b>,
        align: 'center' as 'center',
        dataIndex: 'barcode',
        key: 'barcode',
        width: 80,
      },
      // {
      //   title: <b>No Polis</b>,
      //   align: 'center' as 'center',
      //   dataIndex: 'noPolis',
      //   key: 'noPolis',
      //   width: 100,
      // },
      {
        title: <b>Nama Pemilik</b>,
        align: 'center' as 'center',
        dataIndex: 'shipToName',
        key: 'shipToName',
        width: 100,
      },
      {
        title: <b>Aksi</b>,
        key: 'pilih',
        width: 90,
        align: 'center' as 'center',
        render: (record: any) => (
          <React.Fragment>
            <Row>
              <Col span={12}>
                <div className="spacingBtnDetailComplaint">
                  <ButtonComplaintContainer
                    typeButton={'detail'}
                    detailData={record}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div className="spacingBtnChooseComplaint">
                  <ButtonComplaintContainer
                    typeButton={'ceklist'}
                    detailData={record}
                  />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        ),
      },
    ];
    return (
      <React.Fragment>
        <div className="centerContent">
          <Row>
            <Col span={12}>
              <div className="barcodeFormAddComplaint">
                <span className="floatLeft">
                  <b>Tanggal Cycle</b>
                </span>
                <br />
                <Form.Item>
                  <DatePicker
                    className="datepickerDefault"
                    locale={locale}
                    format={formatDateShow}
                    placeholder={'Pilih tanggal'}
                    value={this.props.getCycleDate}
                    onChange={this.props.handlePutCycleDate}
                    disabled={
                      this.props.getFilterSearch === 'Waybill' ? true : false
                    }
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="cycleDateAddComplaint">
                <span className="floatLeft">
                  <b></b>
                </span>
                <br />
                <FormInputTextMaterial
                  validateType={true}
                  value={this.props.getSearchValue}
                  className={''}
                  disabled={false}
                  addonBefore={prefixSelector}
                  onPressEnter={''}
                  placeholder={'Masukan nomor'}
                  prefix={''}
                  maxLength={30}
                  style={{}}
                  size=""
                  type={''}
                  onChange={this.props.handlePutSearchValue}
                  onBlur={''}
                  validatestatus={''}
                  help={''}
                />
              </div>
              <ButtonComponent
                disabled={validateBtn}
                onClick={this.props.handleSubmit}
                shape="round"
                type="primary"
                size={'middle'}
                className={'btnSearchDocList'}
              >
                Cari
              </ButtonComponent>
            </Col>
          </Row>
          <CardMaterial
            className={'defaultCard'}
            extra={''}
            title={''}
            style={{}}
            content={
              <div className={'tblComplaintList'}>
                <Table
                  bordered={false}
                  pagination={{
                    onChange: (page) => {
                      this.props.handlePage(page);
                    },
                    pageSize: 10,
                  }}
                  dataSource={this.props.getDocumentList}
                  columns={columns}
                />
              </div>
            }
          />
        </div>
        <ModalDetailDocContainer />
      </React.Fragment>
    );
  }
}
