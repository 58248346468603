import React, { Component } from 'react';
import DataWaybillContainer from '../../../../containers/Content/Complaint/DataWaybillContainer';
import ButtonComponent from '../../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';

interface IProps {
  getDetailDocument: any;
  handleSubmit: any;
  getPhoneNumber: any;
  handPutPhoneNumber: any;
  getComplaintTitle: any;
  handPutComplaintTitle: any;
  getComplaintCustDetail: any;
  handPutComplaintDetail: any;
  validateBtn: any;
}

export default class CreateComplaintComponent extends Component<IProps> {
  render() {
    return (
      <div className="spacingCardComplaint">
        <DataWaybillContainer
            getDetailDocument={this.props.getDetailDocument}
        />
        <div className="noTelpFormComplaint">
          <span>
            <b>No telp yang bisa dihubungi</b>
          </span>
          <br />
          <FormInputTextMaterial
            validateType={true}
            value={this.props.getPhoneNumber}
            className={''}
            disabled={false}
            addonBefore={'+62'}
            onPressEnter={''}
            placeholder={'Masukan no telp'}
            prefix={''}
            maxLength={15}
            style={{}}
            size=""
            type={''}
            onChange={this.props.handPutPhoneNumber}
            onBlur={''}
            validatestatus={''}
            help={''}
          />
        </div>
        <div className="complaintTitleForm">
          <span>
            <b>Judul keluhan</b>
          </span>
          <br />
          <FormInputTextMaterial
            validateType={true}
            value={this.props.getComplaintTitle}
            className={''}
            disabled={false}
            addonBefore={''}
            onPressEnter={''}
            placeholder={'Masukan judul keluhan'}
            prefix={''}
            maxLength={200}
            style={{}}
            size=""
            type={''}
            onChange={this.props.handPutComplaintTitle}
            onBlur={''}
            validatestatus={''}
            help={''}
          />
        </div>
        <div>
          <span>
            <b>Detail keluhan pelanggan</b>
          </span>
          <br />
          <FormInputTextMaterial
            validateType={true}
            value={this.props.getComplaintCustDetail}
            className={''}
            disabled={false}
            addonBefore={''}
            onPressEnter={''}
            placeholder={'Masukan detail keluhan'}
            prefix={''}
            maxLength={700}
            style={{}}
            size=""
            type={'textArea'}
            onChange={this.props.handPutComplaintDetail}
            onBlur={''}
            validatestatus={''}
            help={''}
          />
        </div>
        <ButtonComponent
          disabled={this.props.validateBtn}
          onClick={this.props.handleSubmit}
          shape="round"
          type="primary"
          size={'middle'}
          className={'btnSubmitCreateComplaint'}
        >
          Simpan
        </ButtonComponent>
      </div>
    );
  }
}
