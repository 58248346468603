import { Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import UpdateFormComponent from '../../../../components/Content/Complaint/UpdateComplaint/UpdateFormComponent';
import * as ActionComplaint from '../../../../core/action/Complaint/ActionComplaint';

interface IProps {
  getDescription: any;
  getFileImage: any;
  actionComplaint: any;
}

interface IState {
  file: any;
  fileName: any;
  base64URL: any;
  ValidateDescription: any;
  ValidateFileImage: any;
}

class UpdateFormContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      file: null,
      fileName: '',
      base64URL: null,
      ValidateDescription: null,
      ValidateFileImage: null,
    };
    this.handlePutDescription = this.handlePutDescription.bind(this);
    this.handlePutFileImage = this.handlePutFileImage.bind(this);
    this.handleUpdateComplaint = this.handleUpdateComplaint.bind(this);
  }

  handlePutDescription(event: any) {
    this.props.actionComplaint.changeDescU(event.target.value);

    if (event.target.value === '') {
      this.setState({
        ValidateDescription: 'error',
      });
    } else {
      this.setState({
        ValidateDescription: '',
      });
    }
  }

  getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  handlePutFileImage(e: any) {
    this.setState({
      fileName: e.target.value,
    });
    let { file } = this.state;
    file = e.target.files[0];
    this.getBase64(file)
      .then((result: any) => {
        file['base64'] = result.toString().split(',')[1];
        this.props.actionComplaint.changeFileImageU(result.split(',')[1]);
        this.setState({
          base64URL: result.split(',')[1],
          file,
        });
      })
      .catch((err) => {
      });

    if (e.target.files[0] !== undefined) {
      const name = e.target.files[0].name;
      const fileSize = e.target.files[0].size / 1024 / 1024;
      const lastDot = name.lastIndexOf('.');
      const ext = name.substring(lastDot + 1);
      if ((ext === 'jpg' || ext === 'png' || ext === 'jpeg' || ext === 'JPG' || ext === 'JPEG' || ext === 'PNG') && fileSize < 1 ) {
        this.setState({
          file: e.target.files[0],
          ValidateFileImage: null,
        });
      } else if (fileSize > 1) {
        this.setState({
          file: null,
          ValidateFileImage: 'size',
        });
      } else {
        this.setState({
          file: null,
          ValidateFileImage: 'error',
        });
      }
    } else {
      this.setState({
        file: null,
        ValidateFileImage: null,
      });
      this.props.actionComplaint.changeFileImageU(null);
    }
  }

  handleUpdateComplaint(value:any) {
    Modal.confirm ({
      centered: true,
      title: 'Apakah anda yakin ?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk: () => {if (value===0) {
        this.props.actionComplaint.changeModeU(false);
        this.props.actionComplaint.submitUpdateComplaint();
      }else {
        this.props.actionComplaint.changeModeU(true);
        this.props.actionComplaint.submitUpdateComplaint();
      }},
      onCancel() {},
    });
  }

  componentDidMount() {
    this.setState({
      file: null,
      fileName: '',
      base64URL: null,
      ValidateDescription: null,
      ValidateFileImage: null,
    });
  }

  render() {
    return (
      <React.Fragment>
        <UpdateFormComponent
          getDescription={this.props.getDescription}
          getFileImage={this.state.fileName}
          ValidateDescription={
            this.state.ValidateDescription === 'error' ? 'error' : ''
          }
          helpValidateDescription={
            this.state.ValidateDescription === 'error'
              ? 'Keterangan wajib diisi !!!'
              : ''
          }
          ValidationButton={
            this.props.getDescription === null ||
            this.props.getDescription === '' ||
            this.state.ValidateFileImage === 'error' ||
            this.state.ValidateFileImage === 'size'
              ? true
              : false
          }
          ValidateFileImage={
            this.state.ValidateFileImage === 'error'
              ? 'error'
              : '' || this.state.ValidateFileImage === 'size'
              ? 'error'
              : ''
          }
          helpValidateFileImage={
            this.state.ValidateFileImage === 'error'
              ? 'Format yang bisa di unggah hanya .png, .jpg, .jpeg'
              : '' || this.state.ValidateFileImage === 'size'
              ? 'Ukuran file maksimal 1 MB'
              : ''
          }
          handlePutDescription={this.handlePutDescription}
          handlePutFileImage={this.handlePutFileImage}
          handleUpdateComplaint={this.handleUpdateComplaint}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  getDescription: state.Complaint.updateComplaint.description,
  getFileImage: state.Complaint.updateComplaint.fileImage,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionComplaint: bindActionCreators(ActionComplaint, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(UpdateFormContainer);
