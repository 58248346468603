export function setListResultSearch(listResultSearch:any)
{
    return({
        type:"SET_LIST_RESULT_SEARCH",
        listResultSearch
    })
}
export function clearListResultSearch()
{
    return({
        type:"CLEAR_LIST_RESULT_SEARCH"
    })
}