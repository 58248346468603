import * as ActionLogin from "../../action/Login";
import * as ActionReport from "../../action/Report";
import * as actionToggle from "../../action/toggle";
import { formatReports } from "../../../lib/constant";

import { Modal, message } from "antd";
import { call, put, select, takeLatest } from "redux-saga/effects";

import axios from "axios";
import moment from "moment";

export function* S_report() {
  const state = yield select();
  const key = "download";
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    "Content-type": "application/json",
  };
  const getCustomerId = state.Auth.CustomerId;
  const username = state.Auth.UsernameAuth;
  const CycleDateStart = moment(
    state.Report.CustomerReportForm.CycleDateStart
  ).format("YYYYMMDD");
  const CycleDateFinish = moment(
    state.Report.CustomerReportForm.CycleDateFinish
  ).format("YYYYMMDD");
  const ReportType =
    state.Report.CustomerReportForm.ReportTypeId === null
      ? 0
      : state.Report.CustomerReportForm.ReportTypeId;
  const DeliveryStatus = state.Report.CustomerReportForm.DeliveryStatus;
  const CheckboxData = state.Report.CustomerReportForm.ChecboxData;
  const TypeReports = state.Report.PropertiesCustomerReportForm.ListReportType;
  const selectedReports =
    TypeReports.find((report: any) => report.id === ReportType) || null;
  const selectedFormat =
    formatReports.find((value) => value.id === selectedReports?.format)
      ?.format || "xlsx";

  const productId =
    state.Report.PropertiesCustomerReportForm.ListCustomerProduct.map(
      (data: any) => data.id
    );
  const CustomerProduct =
    state.Report.CustomerReportForm.CustomerProductId.length === 0
      ? productId
      : state.Report.CustomerReportForm.CustomerProductId;
  const file_name2 = `Laporan ${CycleDateStart}-${CycleDateFinish}.${selectedFormat}`;

  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    const file_name = file_name2;
    link.href = url;
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
  }
  try {
    yield put(actionToggle.changeLoadingTrue());
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Delivery/DownloadReportCustomer`,
      {
        cycleDateFrom: CycleDateStart,
        cycleDateTo: CycleDateFinish,
        customerId: getCustomerId,
        customerProducts: CustomerProduct,
        customReportFileId: ReportType,
        deliveryStats: DeliveryStatus,
        username: username,
        filterDateType: "Cycle",
        deliveryType: ["reguler"],
        fileString: "",
        groupFileName: false,
        deliveryDetail: CheckboxData,
        today: true,
        month: false,
        trueOrFalse: false,
      },
      {
        responseType: "blob",
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          if (percentProgress < 100) {
            message.loading({
              content: "Loading " + percentProgress + "%",
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: "Download Berhasil",
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: "Download Gagal",
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    yield put(actionToggle.changeLoadingFalse());
  } catch (error) {
    yield put(actionToggle.changeLoadingFalse());
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefresh());
    } else {
      if (error.message === "Network Error") {
        message.error({
          content: "Gagal Di Download",
          key,
          duration: 3,
        });
      } else if (
        error.response.data.Message === null ||
        error.response.data.Message === undefined
      ) {
        message.warning({
          content: "Laporan Tidak Tersedia",
          key,
          duration: 3,
        });
      } else {
        message.error({
          content: "Gagal Di Download",
          key,
          duration: 3,
        });
      }
    }
  }
}

export function* S_getCustomerProduct() {
  const state = yield select();
  const token = state.Auth.Token;
  const username = state.Auth.UsernameAuth;
  const CustomerId = state.Auth.CustomerId;
  const config = {
    Authorization: `bearer ${token}`,
    "Content-type": "application/json",
  };

  try {
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        "/Customer/portal/customerproductportal/" +
        username +
        "/" +
        CustomerId,
      { headers: config }
    );
    yield put(ActionReport.setLoadingCustomerProduct());
    yield put(ActionReport.changeListCustomerProduct(data.data));
    yield put(ActionReport.clearLoadingCustomerProduct());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefresh());
    } else {
    }
  }
}

export function* S_getReportType() {
  const state = yield select();
  const token = state.Auth.Token;
  const CustomerId = state.Auth.CustomerId;
  const config = {
    Authorization: `bearer ${token}`,
    "Content-type": "application/json",
  };

  try {
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        "/Setting/SelectCustomReportFilePortal/" +
        CustomerId,
      { headers: config }
    );
    yield put(ActionReport.setLoadingReportType());
    yield put(ActionReport.changeListReportFile(data.data));
    yield put(ActionReport.clearLoadingReportType());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefresh());
    } else {
    }
  }
}

export function* SM_report() {
  yield takeLatest("DOWNLOAD_CUSTOMER_REPORT", S_report);
}

export function* SM_getCustomerProduct() {
  yield takeLatest("GET_CUSTOMER_PRODUCT", S_getCustomerProduct);
}

export function* SM_getReportType() {
  yield takeLatest("GET_REPORT_TYPE", S_getReportType);
}
