import React, { Component } from 'react';

import ButtonComponent from '../../../materials/ButtonMaterial';
import CardMaterial from '../../../materials/CardMaterial';
import { CheckCircleTwoTone } from '@ant-design/icons';

interface IProps {
    textComplaintSuccess: any;
    handleBack: any;
}

export default class SuccessComplaintComponent extends Component<IProps> {
  render() {
    return (
      <div className="spacingCardComplainedSuccess">
        <CardMaterial
          className={'cardComplainedSuccess'}
          extra={''}
          title={''}
          style={{}}
          content={
            <React.Fragment>
              <CheckCircleTwoTone
                className="checkCircleComplaint"
                twoToneColor="#52c41a"
              />
              <p className="textComplainedSuccess">
                {this.props.textComplaintSuccess}
              </p>
            </React.Fragment>
          }
        />
        <ButtonComponent
          onClick={this.props.handleBack}
          shape="round"
          type="primary"
          size={'middle'}
          className={'btnBackComplaint'}
        >
          Kembali
        </ButtonComponent>
      </div>
    );
  }
}
