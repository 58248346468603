import * as _ from 'lodash';

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

import Auth from '../../core/reducer/Auth';
import Complaint from '../../core/reducer/Complaint/ReducerComplaint';
import Header from '../../core/reducer/Header/ReducerHeader';
import Login from '../../core/reducer/Login';
import Report from '../../core/reducer/Report/ReducerReportForm';
import Saga from '../saga/SagaMiddleware';
import SearchDocument from '../../core/reducer/SearchDocument/ReducerSearchDocumentForm';
import Toggle from '../../core/reducer/toggle';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import history from '../../core/utils/History';
import storage from 'redux-persist/lib/storage';

const sagaMiddleware = createSagaMiddleware();
const combinedReducers = combineReducers({
  Auth,
  Login,
  Toggle,
  SearchDocument,
  Report,
  Header,
  Complaint,
  router: connectRouter(history),
});

const composeEnhacers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  _.has(window, '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__')
    ? composeWithDevTools({ trace: true, traceLimit: 1000 })
    : compose;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['Auth', 'Header'],
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);
export const store = createStore(
  persistedReducer,
  composeEnhacers(applyMiddleware(sagaMiddleware))
);

export const persistor = persistStore(store);
sagaMiddleware.run(Saga);
