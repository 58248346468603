import React from "react";
import { DatePicker, Form } from "antd";
import locale from "antd/es/date-picker/locale/id_ID";
import cx from "classnames";

const { RangePicker } = DatePicker;

type InputDatePickerProps = {
  label?: string;
  addtionalLabel?: string;
  classNameLabel?: any;
  formStyle?: any;
  error?: "" | "error" | "success" | "warning" | "validating" | undefined;
  onOpenChange?: (value: any) => void;
  onChange?: (value: any) => void;
  onCalendarChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  value?: any;
  disabledDate?: any;
  placeholder?: string;
  disabled?: boolean;
  mode?: "time" | "date" | "month" | "year" | "week" | "quarter" | undefined;
  defaultPickerValue?: any;
  format?: string;
  type?: "simple" | "range";
  firstPlaceholder?: string;
  secondPlaceholder?: string;
  addonBefore?: any;
};

export default function InputDatePicker({
  label,
  addtionalLabel,
  classNameLabel,
  error,
  formStyle,
  onOpenChange,
  onChange = () => undefined,
  onCalendarChange = () => undefined,
  onBlur,
  value,
  disabledDate,
  placeholder,
  disabled,
  mode = "date",
  defaultPickerValue,
  format,
  type = "simple",
  firstPlaceholder = "Start date",
  secondPlaceholder = "End date",
  addonBefore,
}: InputDatePickerProps) {
  const labelTitle = label ? (
    <p style={{ marginBottom: addonBefore ? 0 : 4 }}>
      <b className={`capital ${classNameLabel}`}>{label}</b>
      <span style={{ color: "#BFBFBF" }}>
        {addtionalLabel ? ` ${addtionalLabel}` : null}
      </span>
    </p>
  ) : null;

  return (
    <div style={{ width: "100%" }}>
      {labelTitle && labelTitle}
      <Form.Item
        validateStatus={error ? "error" : ""}
        help={error || ""}
        colon={false}
        style={{
          marginBottom: 0,
          width: "100%",
          ...formStyle,
          flexDirection: "column",
        }}
      >
        <div
          className={cx(addonBefore && "group-addon-before")}
          style={{ display: "flex", alignItems: "center" }}
        >
          {addonBefore && <div>{addonBefore}</div>}
          {type === "simple" ? (
            <>
              {mode === "month" ? (
                <DatePicker.MonthPicker
                  className={"datePickerDefault"}
                  locale={locale}
                  format={"YYYY-MM"}
                  placeholder={placeholder}
                  onOpenChange={onOpenChange}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabledDate={disabledDate}
                  disabled={disabled}
                />
              ) : (
                <DatePicker
                  className={"datePickerDefault"}
                  locale={locale}
                  format={format || "YYYY-MM-DD"}
                  placeholder={placeholder}
                  onOpenChange={onOpenChange}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabledDate={disabledDate}
                  disabled={disabled}
                  mode={mode}
                  defaultPickerValue={defaultPickerValue}
                />
              )}
            </>
          ) : (
            <RangePicker
              locale={locale}
              placeholder={[firstPlaceholder, secondPlaceholder]}
              format={format || "MMMM YYYY"}
              picker={mode}
              value={value}
              disabledDate={disabledDate}
              disabled={disabled}
              onCalendarChange={(value) => onCalendarChange(value)}
              onBlur={onBlur}
              style={{ width: "100%" }}
              onChange={(value) => onChange(value)}
            />
          )}
        </div>
      </Form.Item>
    </div>
  );
}
