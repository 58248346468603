import React, { Component } from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import ParentHocLogin from '../../components/Login/ParentHocLogin';

interface IProps{
    isLoading:any
}
interface IState{
}
class LoginContainer extends Component<IProps,IState> {
    
    render() {
        return (
            <div>
                <ParentHocLogin
                    isLoading={this.props.isLoading}
                />
            </div>
        )
    }
}
const mapStateToProps = (state:any) => ({
    isLoading: state.Toggle.isLoading,
})

const mapDispatchToProps = (dispatch:any) => ({
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(
    withConnect
)(LoginContainer)
