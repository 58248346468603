import * as ActionLogin from '../../action/Login';
import * as actionComplaint from '../../action/Complaint/ActionComplaint';
import * as toggle from '../../action/toggle/index';

import {
    call,
    put,
    select,
    takeLatest
} from 'redux-saga/effects';

import axios from 'axios';

export function* S_detailComplaint(action:any)
{
    const state = yield select()
        const idComplaint = state.Complaint.detailComplaint.detail;
        const token = state.Auth.Token
        const config ={
            Authorization: `bearer ${token}`,
            'Content-type': 'application/json',
        }
    try
    {
        yield put(toggle.changeLoadingTrue())
        const {data} = yield call(
            axios.get,process.env.REACT_APP_URL+'/Portal/GetDataDetailComplaint/'+idComplaint,
            {headers:config}
        )
        yield put(actionComplaint.changeDetailD(data))
        yield put(actionComplaint.changeCodeTicketD(data?.tiket))
        yield put(actionComplaint.changeStatusD(data?.complaintType))
        yield put(actionComplaint.changePhoneNumberD(data?.shipToPhoneAccount))
        yield put(actionComplaint.changeTitleComplaintD(data?.question))
        yield put(actionComplaint.changeHistoryD(data?.history))
        yield put(toggle.changeLoadingFalse())
    } catch (error) {
        if (error.response.status === 401) {
            yield put(ActionLogin.submitTokenRefresh());
            yield put(toggle.changeLoadingFalse());
          }
          yield put(toggle.changeLoadingFalse());
    }
}

export function* SM_detailComplaint()
{
    yield takeLatest("SUBMIT_DETAIL_COMPLAINT", S_detailComplaint)
}