import { Col, Row } from "antd";
import React, { Component } from "react";

import ModalMaterial from "../../../../materials/ModalMaterial";

interface IProps {
  showModal: any;
  typeToggle: any;
  handleCloseModal: any;
  getDetailDocument: any;
}

export default class ModalDetailDocComponent extends Component<IProps> {
  render() {
    const detailDocumentValue =
      this.props.getDetailDocument === null ? "" : this.props.getDetailDocument;

    const address =
      detailDocumentValue.shipToAddress === null ||
      detailDocumentValue.shipToAddress === undefined
        ? ""
        : `${Object.values(JSON.parse(detailDocumentValue.shipToAddress)).join(
            " "
          )}`;
    const renderData = (label: any, content: any) => {
      return (
        <>
          <Col span={6}>
            <div className="detailLabelDataComplaint">
              <span>{label}</span>
            </div>
          </Col>
          <Col span={18}>
            <div className="detailValueDataComplaint">
              : <span>{content}</span>
            </div>
          </Col>
        </>
      );
    };
    return (
      <React.Fragment>
        <ModalMaterial
          className={"paddingTop"}
          width={"40%"}
          style={{}}
          teksTitle={"Detail Waybill"}
          visible={this.props.showModal}
          onCancel={this.props.handleCloseModal}
          content={
            <React.Fragment>
              <Row>
                {renderData("Waybill", detailDocumentValue.waybillCode)}
                {renderData("Tanggal Cycle", detailDocumentValue.cycle)}
                {renderData("Barcode", detailDocumentValue.barcode)}
                {renderData("Alamat", address)}
                {renderData("Nama Pemilik", detailDocumentValue.shipToName)}
                {renderData(
                  "No Telp",
                  detailDocumentValue.shipToPhoneCustomers === null ||
                    detailDocumentValue.shipToPhoneCustomers === ""
                    ? "-"
                    : detailDocumentValue.shipToPhoneCustomers
                )}
              </Row>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}
