import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as ActionToggle from "../../../core/action/toggle/index";
import * as ActionComplaint from "../../../core/action/Complaint/ActionComplaint";
import HistoryComplaintComponent from "../../../components/Content/Complaint/HistoryComplaintComponent";

interface IProps {
  actionToggle: any;
  actionComplaint: any;
  typeToggle: any;
  visible: any;
  getDateTime: any;
  getUser: any;
  getDesc: any;
  getImage: any;
  getSignature?: any;
  getImageForModal: any;
  getLocation?: { latitude?: string; longitude?: string };
}

class HistoryComplaintContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleModal = this.handleModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleLinkLocation = this.handleLinkLocation.bind(this);
  }

  handleModal(type: any) {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle(type);
    this.props.actionComplaint.changeImageHistory(
      type === "imageComplaint" ? this.props.getImage : this.props.getSignature
    );
  }

  handleCloseModal() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.clearJenisToggle();
    this.props.actionComplaint.changeImageHistory(null);
  }

  handleLinkLocation() {
    const location = this.props.getLocation;
    const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${location?.latitude},${location?.longitude}`;
    window.open(googleMapsLink, "_blank");
  }

  render() {
    return (
      <React.Fragment>
        <HistoryComplaintComponent
          handleModal={this.handleModal}
          handleCloseModal={this.handleCloseModal}
          typeToggle={this.props.typeToggle}
          visible={this.props.visible}
          getDateTime={this.props.getDateTime}
          getUser={this.props.getUser}
          getDesc={this.props.getDesc}
          getImage={this.props.getImage}
          getImageForModal={this.props.getImageForModal}
          getSignature={this.props.getSignature}
          getLocation={this.props.getLocation}
          handleLinkLocation={this.handleLinkLocation}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  getImageForModal: state.Complaint.image,
  typeToggle: state.Toggle.jenisToggle,
  visible: state.Toggle.modal,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionComplaint: bindActionCreators(ActionComplaint, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(HistoryComplaintContainer);
