import { Action } from 'redux';

const initialState = {
  ValueHeader: null,
};

export interface IAuthAction extends Action<string> {}

export interface IChangeValueHeader extends IAuthAction {
  ValueHeader: any;
}

export default function AuthReducerHeader(
  state = initialState,
  action: IAuthAction
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_VALUE_HEADER':
      const changeValueHeader = action as IChangeValueHeader;
      newState.ValueHeader = changeValueHeader.ValueHeader;

      return { ...newState };

    case 'CLEAR_VALUE_HEADER':
      newState.ValueHeader = null;

      return { ...newState };
  }
  return state;
}
