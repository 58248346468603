export function changeUsernameAuth(UsernameAuth:any)
{
    return({
        type:"CHANGE_USERNAME_AUTH",
        UsernameAuth
    })
}
export function changeCustomerId(CustomerId:any)
{
    return({
        type:"CHANGE_CUSTOMER_ID",
        CustomerId
    })
}
export function changeAccessHeader(AccessHeader:any)
{
    return({
        type:"CHANGE_ACCESS_HEADER",
        AccessHeader
    })
}
export function changeAccessContent(AccessContent:any)
{
    return({
        type:"CHANGE_ACCESS_CONTENT",
        AccessContent
    })
}
export function changeToken(Token:any)
{
    return({
        type:"CHANGE_TOKEN",
        Token
    })
}
export function changeTokenRefresh(TokenRefresh:any)
{
    return({
        type:"CHANGE_TOKEN_REFRESH",
        TokenRefresh
    })
}