import * as ActionComplaint from '../../../../core/action/Complaint/ActionComplaint';
import * as ActionToggle from '../../../../core/action/toggle/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import ButtonComplaintComponent from '../../../../components/Content/Complaint/AddComplaint/ButtonComplaintComponent';
import { connect } from 'react-redux';

interface IProps {
  typeButton: any;
  actionToggle: any;
  actionComplaint: any;
  detailData: any;
}

class ButtonComplaintContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.state = {};
    this.handleModalDetail = this.handleModalDetail.bind(this);
    this.handleChooseData = this.handleChooseData.bind(this);
  }
  handleModalDetail() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('detailDocComplaint');
    this.props.actionComplaint.changeDetailDocument(this.props.detailData);
  }
  handleChooseData() {
    this.props.actionComplaint.changeDetailDocument(this.props.detailData);
    this.props.actionComplaint.changePhoneNumber(this.props.detailData.shipToPhoneAccount);
    this.props.actionComplaint.changeComplaintStep(1);
  }
  render() {
    return (
      <React.Fragment>
        <ButtonComplaintComponent
          handleModalDetail={this.handleModalDetail}
          handleChooseData={this.handleChooseData}
          typeButton={this.props.typeButton}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionComplaint: bindActionCreators(ActionComplaint, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ButtonComplaintContainer);
