import axios from "axios";
import { store } from "../core/store/ConfigStore";

export const getSearchDocument = async (data: {
  typeSearch: string;
  valueSearch: string;
  filterDateType: string;
  startDate: string;
  endDate: string;
}) => {
  const token = store.getState().Auth.Token;
  const username = store.getState().Auth.UsernameAuth;

  const config = {
    Authorization: `bearer ${token}`,
    "Content-type": "application/json",
  };

  try {
    const endpoint =
      data.typeSearch === "waybill"
        ? `/waybill/${data.valueSearch}/${username}`
        : data.typeSearch === "barcode"
        ? `/barcode/${data.valueSearch}/${username}`
        : `/${data.valueSearch}/${data.typeSearch}/${username}`;

    let link = `${process.env.REACT_APP_URL}/Delivery/portal${endpoint}?`;
    if (data.filterDateType)
      link = link.concat(`filterDateType=${data.filterDateType}`);
    if (data.startDate) link = link.concat(`&startDate=${data.startDate}`);
    if (data.endDate) link = link.concat(`&endDate=${data.endDate}`);

    return await axios.get(link, {
      headers: config,
    });
  } catch (error) {
    return error;
  }
};
