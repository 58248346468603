export function changeLoadingFalse()
{
    return({
        type:"CHANGE_LOADING_FALSE"
    })
}
export function changeLoadingTrue()
{
    return({
        type:"CHANGE_LOADING_TRUE"
    })
}
export function changeModal()
{
    return({
        type:"CHANGE_MODAL"
    })
}
export function changeJenisToggle(jenisToggle:any)
{
    return({
        type:"CHANGE_JENIS_TOGGLE",
        jenisToggle
    })
}
export function clearJenisToggle()
{
    return({
        type:"CLEAR_JENIS_TOGGLE"
    })
}