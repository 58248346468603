export function changeUsername(Username: any) {
  return {
    type: 'CHANGE_USERNAME_FORM_LOGIN',
    Username,
  };
}

export function changePassword(Password: any) {
  return {
    type: 'CHANGE_PASSWORD_FORM_LOGIN',
    Password,
  };
}

export function changeClearUsername() {
  return {
    type: 'CHANGE_CLEAR_USERNAME',
  };
}

export function changeClearPassword() {
  return {
    type: 'CHANGE_CLEAR_PASSWORD',
  };
}
export function submitTokenRefresh()
{
    return({
        type:"REQUEST_TOKEN_REFRESH"
    })
}
export function submitLogin() {
  return {
    type: 'LOGIN_REQUESTED',
  };
}

export function submitCustomerId() {
  return {
    type: 'CUSTOMER_ID_REQUESTED',
  };
}
