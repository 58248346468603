export function clearToken() {
  return {
    type: 'CLEAR_TOKEN',
  };
}

export function clearTokenRefresh() {
  return {
    type: 'CLEAR_TOKEN_REFRESH',
  };
}

export function clearUsernameAuth() {
  return {
    type: 'CLEAR_USERNAME_AUTH',
  };
}

export function clearCustomerId() {
  return {
    type: 'CLEAR_CUSTOMER_ID',
  };
}
export function changeClearAccessControl() {
  return {
    type: 'CLEAR_ACCESS_CONTROL',
  };
}

// MENGGUNAKAN REDUCER AUTH
