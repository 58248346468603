import React from "react";
import { Button } from "antd";

const ButtonComponent = (props: any) => {
  return (
    <div>
      <Button {...props} />
    </div>
  );
};
export default ButtonComponent;
