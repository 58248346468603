import * as ActionComplaint from '../../../../core/action/Complaint/ActionComplaint';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import CreateComplaintComponent from '../../../../components/Content/Complaint/AddComplaint/CreateComplaintComponent';
import { connect } from 'react-redux';

interface IProps {
  getDetailDocument: any;
  actionComplaint: any;
  getPhoneNumber: any;
  getComplaintTitle: any;
  getComplaintCustDetail: any;
}
interface IState {
  validatePhoneNumber: any;
}

class CreateComplaintContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      validatePhoneNumber: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handPutPhoneNumber = this.handPutPhoneNumber.bind(this);
    this.handPutComplaintTitle = this.handPutComplaintTitle.bind(this);
    this.handPutComplaintDetail = this.handPutComplaintDetail.bind(this);
  }
  handleSubmit() {
    this.props.actionComplaint.createComplaint();
  }
  handPutPhoneNumber(event: any) {
    const number = parseInt(event.target.value || 1, 10);
    const regex = /^[0-9\b]+$/;
    const RegexMinChar = /^.{6,}$/;

    if (isNaN(number)) {
      return;
    }

    if (!('value' in this.props)) {
      this.props.actionComplaint.changePhoneNumber(
        event.target.value.replace(/^0+/, '')
      );

      if (event.target.value === '') {
        this.setState({
          validatePhoneNumber: 'error',
        });
      } else if (!regex.test(event.target.value)) {
        this.setState({
          validatePhoneNumber: 'nonNumeric',
        });
      } else if (!RegexMinChar.test(event.target.value)) {
        this.setState({
          validatePhoneNumber: 'minchar',
        });
      } else {
        this.setState({
          validatePhoneNumber: '',
        });
      }
    }
  }
  handPutComplaintTitle(event: any) {
    this.props.actionComplaint.changeComplaintTitle(event.target.value);
  }
  handPutComplaintDetail(event: any) {
    this.props.actionComplaint.changeCustDetail(event.target.value);
  }
  render() {
    return (
      <React.Fragment>
        <CreateComplaintComponent
          getDetailDocument={this.props.getDetailDocument}
          handPutPhoneNumber={this.handPutPhoneNumber}
          getPhoneNumber={this.props.getPhoneNumber}
          handPutComplaintTitle={this.handPutComplaintTitle}
          getComplaintTitle={this.props.getComplaintTitle}
          getComplaintCustDetail={this.props.getComplaintCustDetail}
          handPutComplaintDetail={this.handPutComplaintDetail}
          handleSubmit={this.handleSubmit}
          validateBtn={
            this.props.getPhoneNumber === null ||
            this.state.validatePhoneNumber === 'error' ||
            this.state.validatePhoneNumber === 'nonNumeric' ||
            this.state.validatePhoneNumber === 'minchar' ||
            this.props.getComplaintTitle === null ||
            this.props.getComplaintTitle === '' ||
            this.props.getComplaintCustDetail === null ||
            this.props.getComplaintCustDetail === ''
              ? true
              : false
          }
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  getDetailDocument: state.Complaint.addComplaintState.detailDocument,
  getPhoneNumber: state.Complaint.addComplaintState.phoneNumber,
  getComplaintTitle: state.Complaint.addComplaintState.complaintTitle,
  getComplaintCustDetail: state.Complaint.addComplaintState.complaintCustDetail,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionComplaint: bindActionCreators(ActionComplaint, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(CreateComplaintContainer);
