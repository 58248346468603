import { CheckOutlined, EyeFilled } from '@ant-design/icons';
import React, { Component } from 'react';

import ButtonComponent from '../../../../materials/ButtonMaterial';

interface IProps {
  typeButton: any;
  handleModalDetail: any;
  handleChooseData: any;
}

export default class ButtonComplaintComponent extends Component<IProps> {
  render() {
    return (
      <React.Fragment>
        {this.props.typeButton === 'detail' ? (
          <ButtonComponent
            onClick={this.props.handleModalDetail}
            type="primary"
            size={'middle'}
            className={'btnActionDetail'}
            icon={<EyeFilled />}
          />
        ) : (
          <ButtonComponent
            onClick={this.props.handleChooseData}
            type="primary"
            size={'middle'}
            className={'btnActionCeklist'}
            icon={<CheckOutlined />}
          />
        )}
      </React.Fragment>
    );
  }
}
