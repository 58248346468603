export const EnumAccess = {
  1: { label: "Pelacakan", value: 1 },
  2: { label: "Laporan", value: 2 },
  3: { label: "Keluhan Pelanggan", value: 3 },
  4: { label: "Waybill Digital", value: 4 },
  11: { label: "Pelacakan - Data Foto", value: 11 },
  12: { label: "Pelacakan - Data TTD", value: 12 },
  13: { label: "Pelacakan – Waybill Digital", value: 13 },
};

export const EnumAccessControl = [
  {
    label: EnumAccess[1].label,
    value: EnumAccess[1].value,
  },
  {
    label: EnumAccess[2].label,
    value: EnumAccess[2].value,
  },
  {
    label: EnumAccess[3].label,
    value: EnumAccess[3].value,
  },
  {
    label: EnumAccess[4].label,
    value: EnumAccess[4].value,
  },
  {
    label: EnumAccess[11].label,
    value: EnumAccess[11].value,
  },
  {
    label: EnumAccess[12].label,
    value: EnumAccess[12].value,
  },
  {
    label: EnumAccess[13].label,
    value: EnumAccess[13].value,
  },
];
