import React, { useState, useEffect } from "react";
import { Modal, Spin } from "antd";
import { Form, Field } from "react-final-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import InputDate from "../../../materials/InputDate";
import SelectField from "../../../materials/SelectField";
import ModalStatus from "../../../materials/ModalStatus";
import styles from "./WaybillDigital.module.css";
import Button from "../../../materials/ButtonMaterial";
import SpinMaterial from "../../../materials/SpinMaterial";
import { statusDelivery, nameFiles } from "../../../lib/constant";
import { downloadBlob, processRequest } from "../../../lib/utils";
import {
  getAllCustomerProduct,
  downloadWaybillDigital,
} from "../../../service/WaybillDigitalService";

type WaybillDigitalComponentProps = {
  GetUsername: any;
};

export default function WaybillDigitalComponent({
  GetUsername,
}: WaybillDigitalComponentProps) {
  const [dataProduct, setDataProduct] = useState<Array<any>>([]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [dateCycle, setDateCycle] = useState(null);
  const progressHandler = processRequest(setLoadingProgress);

  const { data: dataAllProduct, isLoading: isLoadingProduct } = useQuery({
    queryKey: ["dataCustomerProduct"],
    queryFn: () => getAllCustomerProduct(),
  });

  const { mutate: mutateDownloadFile, isLoading: isLoadingDownload } =
    useMutation((data) => downloadWaybillDigital(data, progressHandler), {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          downloadBlob(
            res.data,
            // @ts-ignore
            `${GetUsername}_${dateCycle ? dateCycle.format("YYYYMMDD") : null}`,
            "zip"
          );
        } else {
          ModalStatus({
            status: "error",
            title: "Terjadi Kesalahan",
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: "error",
          title: "Terjadi Kesalahan",
        });
      },
    });

  useEffect(() => {
    // @ts-ignore
    if (dataAllProduct?.data) {
      // @ts-ignore
      const data = dataAllProduct?.data;
      const products = [] as Array<any>;
      data.data.forEach((item: any) => {
        products.push({
          value: item.id,
          label: item.name,
        });
      });

      setDataProduct(products);
    }
  }, [dataAllProduct]);

  useEffect(() => {
    if (loadingProgress === 100) {
      Modal.destroyAll();
      setLoadingProgress(0);
    }
  }, [loadingProgress]);

  const handleClickDownload = (value: any) => {
    const data = {
      filterDateType: "Cycle",
      dateStart: value.dateCycle.format("YYYYMMDD"),
      dateEnd: value.dateCycle.format("YYYYMMDD"),
      customerProducts: value.products,
      deliveryStats: value.statusDelivery,
      fileNameWaybill: value.nameFiles,
    };

    // @ts-ignore
    mutateDownloadFile(data);
  };

  return (
    <SpinMaterial spinning={false} size={"large"}>
      <div className={styles.mainWrapper}>
        <Form
          validate={(values) => {
            const errors = {} as any;
            if (!values.dateCycle) {
              errors.dateCycle = "Tanggal wajib di isi";
            }
            if (!values.nameFiles) {
              errors.nameFiles = "Penamaan File wajib di isi";
            }
            if (
              !values.statusDelivery ||
              (values.statusDelivery && values.statusDelivery.length === 0)
            ) {
              errors.statusDelivery = "Status Pengiriman wajib di isi";
            }
            if (
              !values.products ||
              (values.products && values.products.length === 0)
            ) {
              errors.products = "Produk wajib di isi";
            }
            return errors;
          }}
          onSubmit={(value) => handleClickDownload(value)}
        >
          {({ handleSubmit, valid, form }) => (
            <div className={styles.wrapper}>
              <span className="titleCard" style={{ fontSize: 20 }}>
                Waybill Digital
              </span>
              <div className={styles.cardInfo}>
                <p className={styles.textInfo}>
                  Mohon untuk tidak mendownload data pada jam sibuk. Disarankan
                  untuk melakukan proses download diakhir hari atau setelah
                  tidak ada proses lagi
                </p>
              </div>
              <div className={styles.wrapperFields}>
                <Field name="dateCycle">
                  {({ input, meta }) => (
                    <InputDate
                      label="Tanggal Cycle"
                      placeholder="Masukan tanggal kirim"
                      value={input.value}
                      error={meta.error && meta.touched && meta.error}
                      onChange={(value) => {
                        input.onChange(value);
                        setDateCycle(value);
                      }}
                      onBlur={input.onBlur}
                    />
                  )}
                </Field>
                <Field name="statusDelivery">
                  {({ input, meta }) => (
                    <SelectField
                      label="Status Pengiriman"
                      placeholder="Pilih Status Pengiriman"
                      data={statusDelivery}
                      mode="multiple"
                      error={meta.error && meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input?.value || undefined}
                      allowClear
                    />
                  )}
                </Field>
              </div>
              <div className={styles.wrapperFields}>
                <Field name="products">
                  {({ input, meta }) => (
                    <SelectField
                      label="Produk"
                      placeholder="Pilih Produk"
                      error={meta.error && meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      data={dataProduct}
                      isLoading={isLoadingProduct}
                      mode="multiple"
                      onBlur={input.onBlur}
                      value={input?.value || undefined}
                      allowClear
                    />
                  )}
                </Field>
                <Field name="nameFiles">
                  {({ input, meta }) => (
                    <SelectField
                      label="Penamaan File"
                      placeholder="Pilih Penamaan File"
                      data={nameFiles}
                      error={meta.error && meta.touched && meta.error}
                      onChange={(value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      value={input?.value || undefined}
                      allowClear
                    />
                  )}
                </Field>
              </div>
              <div className={styles.wrapperAction}>
                <Button
                  className={styles.buttonAction}
                  type="primary"
                  disabled={!valid}
                  danger
                  onClick={() => {
                    form.reset();
                    setDateCycle(null);
                  }}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  className={styles.buttonAction}
                  onClick={handleSubmit}
                  disabled={!valid}
                >
                  Download
                </Button>
              </div>
            </div>
          )}
        </Form>
      </div>

      {(isLoadingDownload || loadingProgress !== 0) && (
        <Modal
          visible={isLoadingDownload || loadingProgress !== 0}
          footer={null}
          closable={false}
        >
          <div style={{ display: "flex", gap: 16 }}>
            <Spin />
            <p
              style={{
                margin: 0,
                fontSize: 18,
                color: "#7956EE",
                fontWeight: 600,
                lineHeight: "20px",
              }}
            >
              Download Data
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              marginLeft: "37px",
            }}
          >
            <p
              style={{
                color: "#9E9E9E",
                fontSize: 14,
                fontWeight: 500,
                margin: 0,
              }}
            >
              Sedang mendownload data, mohon tunggu...
            </p>
            <p
              style={{
                color: "#9E9E9E",
                fontSize: 14,
                fontWeight: 500,
                margin: 0,
              }}
            >
              {loadingProgress} %
            </p>
          </div>
        </Modal>
      )}
    </SpinMaterial>
  );
}
