/* eslint-disable no-lone-blocks */
import React from "react";
import axios from "axios";
import { Modal } from "antd";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as actionListResultSearch from "../../action/SearchDocument/ActionListResultSearch";
import * as actionPropertiesSearchResult from "../../action/SearchDocument/ActionPropertiesSearchResult";
import * as toggle from "../../action/toggle/index";
import * as ActionLogin from "../../action/Login";

import { ErrorMassage } from "../../ErrorMessage";

export function* S_searchDocument() {
  const state = yield select();
  const token = state.Auth.Token;
  const username = state.Auth.UsernameAuth;
  const config = {
    Authorization: `bearer ${token}`,
    "Content-type": "application/json",
  };
  const searchType = state.SearchDocument.searchForm.searchType;
  const searchValue = state.SearchDocument.searchForm.searchValue
    .split("\n")
    .join(";");
  try {
    yield put(toggle.changeLoadingTrue());
    yield put(actionListResultSearch.clearListResultSearch());

    const { data } = yield call(
      axios.get,
      searchType === "waybill"
        ? process.env.REACT_APP_URL +
            "/Delivery/portal/waybill/" +
            searchValue +
            "/" +
            username
        : searchType === "barcode"
        ? process.env.REACT_APP_URL +
          "/Delivery/portal/barcode/" +
          searchValue +
          "/" +
          username
        : process.env.REACT_APP_URL +
          "/Delivery/portal/" +
          searchValue +
          "/" +
          searchType +
          "/" +
          username,
      { headers: config }
    );
    yield put(
      actionPropertiesSearchResult.setSearchResultDocument("dataFound")
    );
    yield put(actionPropertiesSearchResult.setWorBSearch(searchValue));
    yield put(actionPropertiesSearchResult.setNumberType(searchValue));
    {
      data[0] === undefined
        ? yield put(actionListResultSearch.setListResultSearch([data]))
        : yield put(actionListResultSearch.setListResultSearch(data));
    }
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefresh());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      const state = yield select();
      const searchValue = state.SearchDocument.searchForm.searchValue;
      if (
        error.response.data.Message === null ||
        error.response.data.Message === undefined
      ) {
        const propsError = ErrorMassage("Hasil Pencarian Dokumen");
        const code = propsError.filter((person: any) =>
          person.Code.toString().toLowerCase().includes(error.response.status)
        );
        Modal.error({
          title: (
            <p style={{ color: "#FF0000" }}>
              {code.map((data: any) => data.TitleMassage)}
            </p>
          ),
          content: (
            <div>
              <p style={{ color: "#C0C0C0" }}>
                {code.map((data: any) => data.Massage)}
              </p>
            </div>
          ),
          onOk() {},
        });
      } else {
        yield put(
          actionPropertiesSearchResult.setSearchResultDocument("dataNotFound")
        );
        yield put(actionPropertiesSearchResult.setWorBSearch(searchValue));
      }
    }
  }
}

export function* SM_searchDocument() {
  yield takeLatest("SEARCH_DOCUMENT", S_searchDocument);
}
