import { BackTop, Steps } from 'antd';
import React, { Component } from 'react';

import { ArrowUpOutlined } from '@ant-design/icons';
import ButtonComponent from '../../../../materials/ButtonMaterial';
import CreateComplaintContainer from '../../../../containers/Content/Complaint/AddComplaint/CreateComplaintContainer';
import SearchDocForComplaintContainer from '../../../../containers/Content/Complaint/AddComplaint/SearchDocForComplainContainer';
import SpinComponent from '../../../../materials/SpinMaterial';
import SuccessComplaintContainer from '../../../../containers/Content/Complaint/SuccessComplaintContainer';

interface IProps {
  getComplaintStep: any;
  handleBack: any;
  isLoading: any;
}
export default class IndexAddComponent extends Component<IProps> {
  render() {
    const { Step } = Steps;
    const showContent = () => {
      if (this.props.getComplaintStep === 0) {
        return <SearchDocForComplaintContainer />;
      } else if (this.props.getComplaintStep === 1) {
        return <CreateComplaintContainer />;
      } else {
        return <SuccessComplaintContainer typeComplaint={'addComplaint'} textComplaintSuccess={'Keluhan berhasil ditambahkan'}/>;
      }
    };
    const showBackButton = () => {
      if (this.props.getComplaintStep !== 2) {
        return (
          <ButtonComponent
            onClick={this.props.handleBack}
            type="link"
            className={'btnBackComplaintLink'}
          >
            {'< Kembali'}
          </ButtonComponent>
        );
      }
    };
    return (
      <div>
        <SpinComponent spinning={this.props.isLoading} size={'large'}>
          {showBackButton()}
          <div className="centerContentAddComplaint">
            <p className="titleCardComplaint">KELUHAN PELANGGAN</p>
            <Steps
              className="stepComplaint"
              size="default"
              current={this.props.getComplaintStep}
            >
              <Step title="Pilih Pengiriman" />
              <Step title="Form Keluhan" />
              <Step title="Berhasil Membuat Keluhan" />
            </Steps>
          </div>
          {showContent()}
          <BackTop>
            <ButtonComponent shape="circle" icon={<ArrowUpOutlined />} />
          </BackTop>
        </SpinComponent>
      </div>
    );
  }
}
