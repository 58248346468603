import { Action } from 'redux';

const initialState = {
  PropertiesCustomerReportForm: {
    ListCustomerProduct: [],
    LoadingCustomerProduct: false,
    ListReportType: [],
    LoadingReportType: false,
  },
  CustomerReportForm: {
    CycleDateStart: null,
    CycleDateFinish: null,
    Filter: false,
    ChecboxData: true,
    CustomerProductId: [],
    ReportTypeId: 0,
    DeliveryStatus: ['Success', 'Return', 'OnProcces'],
  },
};

export interface IAuthAction extends Action<string> {}

export interface IChangeCycleDateStart extends IAuthAction {
  CycleDateStart: any;
}

export interface IChangeCycleDateFinish extends IAuthAction {
  CycleDateFinish: any;
}

export interface IChangeShowFilter extends IAuthAction {
  Filter: any;
}

export interface IChangeFilter extends IAuthAction {
  Filter: any;
}
export interface IChangeCheckbox extends IAuthAction {
  ChecboxData: any;
}

export interface IChangeCustomerProductId extends IAuthAction {
  CustomerProductId: any;
}

export interface IChangeListCustomerProduct extends IAuthAction {
  ListCustomerProduct: any;
}

export interface IChangeReportTypeId extends IAuthAction {
  ReportTypeId: any;
}
export interface IChangeListReportType extends IAuthAction {
  ListReportType: any;
}
export interface IChangeDeliveryStatus extends IAuthAction {
  DeliveryStatus: any;
}

export default function AuthReducerCustomerReportForm(
  state = initialState,
  action: IAuthAction
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_CYCLE_DATE_START':
      const changeCycleDateStartR = action as IChangeCycleDateStart;
      newState.CustomerReportForm.CycleDateStart =
        changeCycleDateStartR.CycleDateStart;

      return { ...newState };

    case 'CHANGE_CYCLE_DATE_FINISH':
      const changeCycleDateFinishR = action as IChangeCycleDateFinish;
      newState.CustomerReportForm.CycleDateFinish =
        changeCycleDateFinishR.CycleDateFinish;

      return { ...newState };

    case 'CHANGE_FILTER':
      const changeFilter = action as IChangeFilter;
      newState.CustomerReportForm.Filter = changeFilter.Filter;

      return { ...newState };

    case 'CHANGE_SHOW_FILTER':
      newState.CustomerReportForm.Filter = !newState.CustomerReportForm.Filter;

      return { ...newState };

    case 'CHANGE_CHECKBOX_DATA':
      const changeCheckbox = action as IChangeCheckbox;
      newState.CustomerReportForm.ChecboxData = changeCheckbox.ChecboxData;

      return { ...newState };

    case 'CHANGE_DELIVERY_STATUS':
      const changeDeliveryStatusR = action as IChangeDeliveryStatus;
      newState.CustomerReportForm.DeliveryStatus =
        changeDeliveryStatusR.DeliveryStatus;

      return { ...newState };

    case 'CHANGE_CUSTOMER_PRODUCT_ID':
      const changeCustomerProductIdR = action as IChangeCustomerProductId;
      newState.CustomerReportForm.CustomerProductId =
        changeCustomerProductIdR.CustomerProductId;

      return { ...newState };

    case 'CHANGE_REPORT_TYPE_ID':
      const changeReportTypeIdR = action as IChangeReportTypeId;
      newState.CustomerReportForm.ReportTypeId =
        changeReportTypeIdR.ReportTypeId;

      return { ...newState };

    case 'CHANGE_LIST_CUSTOMER_PRODUCT':
      const changeListProduct = action as IChangeListCustomerProduct;
      newState.PropertiesCustomerReportForm.ListCustomerProduct =
        changeListProduct.ListCustomerProduct;

      return { ...newState };

    case 'CHANGE_LIST_REPORT_TYPE':
      const changeListReportType = action as IChangeListReportType;
      newState.PropertiesCustomerReportForm.ListReportType =
        changeListReportType.ListReportType;

      return { ...newState };

    case 'SET_LOADING_CUSTOMER_PRODUCT':
      newState.PropertiesCustomerReportForm.LoadingCustomerProduct =
        !newState.PropertiesCustomerReportForm.LoadingCustomerProduct;

      return { ...newState };

    case 'SET_LOADING_REPORT_TYPE':
      newState.PropertiesCustomerReportForm.LoadingReportType =
        !newState.PropertiesCustomerReportForm.LoadingReportType;

      return { ...newState };

    case 'CLEAR_LOADING_CUSTOMER_PRODUCT':
      newState.PropertiesCustomerReportForm.LoadingCustomerProduct = false;

      return { ...newState };

    case 'CLEAR_LOADING_REPORT_TYPE':
      newState.PropertiesCustomerReportForm.LoadingReportType = false;

      return { ...newState };

    case 'CLEAR_CUSTOMER_REPORT':
      newState.CustomerReportForm.CycleDateStart = null;
      newState.CustomerReportForm.CycleDateFinish = null;
      newState.CustomerReportForm.Filter = false;
      return { ...newState };

    case 'CLEAR_CUSTOMER_PRODUCT':
      newState.PropertiesCustomerReportForm.ListCustomerProduct = [];
      newState.CustomerReportForm.CustomerProductId = [];
      return { ...newState };

    case 'CLEAR_REPORT_TYPE':
      newState.PropertiesCustomerReportForm.ListReportType = [];
      newState.CustomerReportForm.ReportTypeId = 0;
      return { ...newState };

    case 'CLEAR_CUSTOMER_REPORT_FORM':
      newState.CustomerReportForm.CycleDateStart = null;
      newState.CustomerReportForm.CycleDateFinish = null;
      newState.CustomerReportForm.Filter = false;
      newState.CustomerReportForm.ChecboxData = true;
      newState.CustomerReportForm.CustomerProductId = [];
      newState.CustomerReportForm.ReportTypeId = 0;
      newState.CustomerReportForm.DeliveryStatus = [
        'Success',
        'Return',
        'OnProcces',
      ];
      newState.PropertiesCustomerReportForm.ListCustomerProduct = [];
      newState.PropertiesCustomerReportForm.ListReportType = [];
      newState.PropertiesCustomerReportForm.LoadingCustomerProduct = false;
      newState.PropertiesCustomerReportForm.LoadingReportType = false;
      return { ...newState };
    case 'DEFAULT_REPORT_FORM':
      newState.CustomerReportForm.ChecboxData = true;
      newState.CustomerReportForm.CustomerProductId = [];
      newState.CustomerReportForm.ReportTypeId = 0;
      newState.CustomerReportForm.DeliveryStatus = [
        'Success',
        'Return',
        'OnProcces',
      ];
      newState.PropertiesCustomerReportForm.ListCustomerProduct = [];
      newState.PropertiesCustomerReportForm.ListReportType = [];
      newState.PropertiesCustomerReportForm.LoadingCustomerProduct = false;
      newState.PropertiesCustomerReportForm.LoadingReportType = false;
      return { ...newState };
  }
  return state;
}
