/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from "antd";
import moment from "moment";
import React, { Component } from "react";
import CardMaterial from "../../../materials/CardMaterial";
import ModalMaterial from "../../../materials/ModalMaterial";

interface IProps {
  handleModal: any;
  handleCloseModal: any;
  typeToggle: any;
  visible: any;
  getDateTime: any;
  getUser: any;
  getDesc: any;
  getImage: any;
  getSignature: any;
  getImageForModal: any;
  getLocation?: { latitude?: string; longitude?: string };
  handleLinkLocation: () => void;
}

export default class HistoryComplaintComponent extends Component<IProps> {
  render() {
    return (
      <div>
        <div>
          <Row>
            <Col span={12}>
              <span>
                <b className="textBlackStyle">
                  {moment(this.props.getDateTime).format(
                    "Do MMMM YYYY / HH:mm"
                  )}
                </b>
              </span>
            </Col>
            <Col span={12}>
              <span className="floatRight">
                <b className="textGreyStyle">{this.props.getUser}</b>
              </span>
            </Col>
          </Row>
          <span>
            <b className="textGreyStyle">Notes</b>
          </span>
          <br />
          <CardMaterial
            className={"cardHistoryComplaint"}
            extra={""}
            title={""}
            style={{}}
            content={
              <div>
                <Row>
                  <span className="descHistoryComplaint">
                    <b>{this.props.getDesc}</b>
                  </span>
                </Row>
                {this.props.getImage !== null && (
                  <div style={{ paddingRight: "10px" }} className="floatLeft">
                    <a
                      className="actionPhotoHistoryComplaint"
                      onClick={() => this.props.handleModal("imageComplaint")}
                    >
                      Lihat Foto
                    </a>
                  </div>
                )}
                {this.props.getSignature !== null && (
                  <div className="floatLeft">
                    <a
                      className="actionPhotoHistoryComplaint"
                      onClick={() =>
                        this.props.handleModal("imageComplaintTtd")
                      }
                    >
                      Lihat TTD
                    </a>
                  </div>
                )}
                {this.props.getLocation?.latitude &&
                  this.props.getLocation?.longitude && (
                    <div className="floatLeft">
                      <a
                        className="actionPhotoHistoryComplaint"
                        onClick={() => this.props.handleLinkLocation()}
                      >
                        Lihat Lokasi
                      </a>
                    </div>
                  )}
              </div>
            }
          />
          {this.props.getImage !== null && (
            <ModalMaterial
              className={"modalPhotoStyle"}
              width={"40%"}
              style={{}}
              teksTitle={
                this.props.typeToggle === "imageComplaint"
                  ? "FOTO"
                  : "TANDA TANGAN"
              }
              visible={this.props.visible}
              onCancel={this.props.handleCloseModal}
              content={
                <div>
                  <img
                    className="image-gallery-slide"
                    src={`data:image/jpeg;base64,${this.props.getImageForModal}`}
                  />
                </div>
              }
            />
          )}
        </div>
      </div>
    );
  }
}
