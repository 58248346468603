import * as AcHeader from "../../core/action/Header/index";
import * as ActionComplaint from "../../core/action/Complaint/ActionComplaint";
import * as ActionSearchDocumentForm from "../../core/action/SearchDocument/ActionSearchDocumentForm";
import * as ClearAuth from "../../core/action/Logout/index";
import * as Toggle from "../../core/action/toggle";

import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";

import HeaderComponent from "../../components/Header/HeaderComponent";
import { Modal } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import history from "../../core/utils/History";

interface IProps {
  GetUsername: any;
  GetAccessHeader: any;
  GetValueHeader: any;
  ActionClearAuth: any;
  ActionToggle: any;
  ActionHeader: any;
  ActionSearchDocumentForm: any;
  actionComplaint: any;
  isLoading: any;
}
const { confirm } = Modal;

class HeaderContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      isActive: null,
    };
    this.handleClearAuth = this.handleClearAuth.bind(this);
    this.handleModalLogout = this.handleModalLogout.bind(this);
    this.handleActiveButtonReport = this.handleActiveButtonReport.bind(this);
    this.handleActiveButtonHome = this.handleActiveButtonHome.bind(this);
    this.handleActiveWaybillDigital =
      this.handleActiveWaybillDigital.bind(this);
    this.handleActiveButtonComplaint =
      this.handleActiveButtonComplaint.bind(this);
  }
  handleClearAuth() {
    localStorage.clear();
    this.props.ActionToggle.changeJenisToggle("logout");
    this.props.ActionToggle.clearJenisToggle();
    this.props.ActionClearAuth.clearToken();
    this.props.ActionClearAuth.clearTokenRefresh();
    this.props.ActionClearAuth.clearUsernameAuth();
    this.props.ActionClearAuth.clearCustomerId();
    this.props.ActionClearAuth.changeClearAccessControl();
    this.props.ActionHeader.clearValueHeader();
    this.props.ActionSearchDocumentForm.clearSearchValue();
    this.props.actionComplaint.clearAllComplaintForm();
    this.props.actionComplaint.clearFormDetailD();
  }
  handleModalLogout() {
    const handleOk = () => {
      this.handleClearAuth();
    };
    confirm({
      title: <p>Anda yakin ingin keluar ?</p>,
      icon: <QuestionCircleOutlined />,
      // content: '',
      okText: "Ya",
      cancelText: "Tidak",
      onOk() {
        handleOk();
      },
      onCancel() {},
    });
  }
  handleActiveButtonHome() {
    this.props.ActionHeader.changeValueHeader(1);
    history.push("/");
  }
  handleActiveButtonReport() {
    this.props.ActionHeader.changeValueHeader(2);
    history.push("/report");
  }
  handleActiveButtonComplaint() {
    this.props.ActionHeader.changeValueHeader(3);
    history.push("/complaint");
  }
  handleActiveWaybillDigital() {
    this.props.ActionHeader.changeValueHeader(4);
    history.push("/waybill-digital");
  }
  componentDidMount() {
    if (this.props.GetValueHeader === 1) {
      history.push("/");
    } else if (this.props.GetValueHeader === 2) {
      history.push("/report");
    } else if (this.props.GetValueHeader === 3) {
      history.push("/complaint");
    } else if (this.props.GetValueHeader === 4) {
      history.push("/waybill-digital");
    } else {
      history.push("/notfound");
    }
  }
  render() {
    return (
      <HeaderComponent
        GetUsername={this.props.GetUsername}
        GetAccessHeader={this.props.GetAccessHeader}
        GetValueHeader={this.props.GetValueHeader}
        handleActiveButtonReport={this.handleActiveButtonReport}
        handleActiveButtonHome={this.handleActiveButtonHome}
        handleActiveButtonComplaint={this.handleActiveButtonComplaint}
        handleActiveWaybillDigital={this.handleActiveWaybillDigital}
        handleClearAuth={this.handleClearAuth}
        handleModalLogout={this.handleModalLogout}
        isLoading={this.props.isLoading}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  GetAccessHeader: state.Auth.AccessHeader,
  GetUsername: state.Auth.UsernameAuth,
  isLoading: state.Toggle.isLoading,
  GetValueHeader: state.Header.ValueHeader,
});

const mapDispatchToProps = (dispatch: any) => ({
  ActionToggle: bindActionCreators(Toggle, dispatch),
  ActionClearAuth: bindActionCreators(ClearAuth, dispatch),
  ActionHeader: bindActionCreators(AcHeader, dispatch),
  ActionSearchDocumentForm: bindActionCreators(
    ActionSearchDocumentForm,
    dispatch
  ),
  actionComplaint: bindActionCreators(ActionComplaint, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(HeaderContainer);
