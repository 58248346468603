import React, { Component } from 'react'
import { Result } from 'antd';
import SpinComponent from '../../materials/SpinMaterial';

interface IProps {
    isVisible: any;
}
export default class index extends Component<IProps>{
    render() {
        return (
            <div>
                {this.props.isVisible ? (
                    <Result
                        className='notfoundStyle'
                        status="404"
                        title="Sorry"
                        subTitle="the page you visited does not exist."
                        // extra={<Button type="primary">Back Home</Button>}
                    />
                ) : (
                    <SpinComponent size="large" className={'spinNoteFound'}/>
                )}
            </div>
        )
    }
}
