import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import IndexUpdateComponent from '../../../../components/Content/Complaint/UpdateComplaint/IndexUpdateComponent';
import * as ActionComplaint from '../../../../core/action/Complaint/ActionComplaint';

interface IProps {
    getComplaintProcess: any;
    getHistory: any;
    getDetail: any;
    getMode: any;
    actionComplaint: any;
    isLoading: any;
}

class IndexUpdateContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
  }
  handleBack() {
    this.props.actionComplaint.clearFormDetailD();
    this.props.actionComplaint.clearFormUpdate();
    this.props.actionComplaint.clearComplaintProcess();
  }
  render() {
    return (
      <React.Fragment>
        <IndexUpdateComponent
            getComplaintProcess={this.props.getComplaintProcess}
            getDetail={this.props.getDetail}
            getHistory={this.props.getHistory}
            getMode={this.props.getMode}
            handleBack={this.handleBack}
            isLoading={this.props.isLoading}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
    getComplaintProcess: state.Complaint.propertiesComplaint.complaintProcessType,
    getDetail: state.Complaint.detailComplaint.detail,
    getHistory: state.Complaint.history,
    getMode: state.Complaint.updateComplaint.mode,
    isLoading: state.Toggle.isLoading,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionComplaint: bindActionCreators(ActionComplaint,dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(IndexUpdateContainer);