import {Action} from 'redux';

const initialState = 
{
    loginForm : {
        Username : null,
        Password : null,
    }
    
}

export interface IAuthAction extends Action<string>
{

}

export interface IChangeUsername extends IAuthAction
{
    Username : any
}

export interface IChangePassword extends IAuthAction
{
    Password : any
}

export default function AuthReducerLoginForm(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "CHANGE_USERNAME_FORM_LOGIN":
            const changeUsername = action as IChangeUsername
            newState.loginForm.Username = changeUsername.Username
            
        return {...newState}
        
        case "CHANGE_PASSWORD_FORM_LOGIN":
            const changePassword = action as IChangePassword
            newState.loginForm.Password = changePassword.Password
            
        return {...newState}

        case "CHANGE_CLEAR_USERNAME":
            
            newState.loginForm.Username = null
            
        return {...newState}

        case "CHANGE_CLEAR_PASSWORD":

        newState.loginForm.Password = null

        return {...newState}
        
    }
    return state
}