import {Action} from 'redux'

const initialState = 
{
    isLoading:false,
    modal:false,
    jenisToggle:null,
}

export interface IAuthAction extends Action<string>
{

}

export interface IJenisToggle extends IAuthAction
{
    jenisToggle : any
}
export interface IValueSearch extends IAuthAction
{
    ValueSearch : any
}

export default function AuthReducer(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "CHANGE_LOADING_FALSE":
            newState.isLoading = false
            
        return {...newState}

        case "CHANGE_LOADING_TRUE":
            newState.isLoading = true
            
        return {...newState}
        
        case "CHANGE_MODAL":
            newState.modal = !state.modal
            
        return {...newState}

        case "CHANGE_JENIS_TOGGLE":
            const changeJenisToggle = action as IJenisToggle
            newState.jenisToggle = changeJenisToggle.jenisToggle
            
        return {...newState}

        case "CLEAR_JENIS_TOGGLE":
            newState.jenisToggle = null
            newState.modal = false
            
        return {...newState}
    }
    return {...newState}
}