export function setSearchResultDocument(searchResult: any) {
  return {
    type: 'SET_SEARCH_RESULT',
    searchResult,
  };
}
export function setWorBSearch(WorBSearch: any) {
  return {
    type: 'SET_WORB_SEARCH',
    WorBSearch,
  };
}

export function setNumberType(numberType: any) {
  return {
    type: 'SET_NUMBER_TYPE',
    numberType,
  };
}
export function setImageResult(image: any) {
  return {
    type: 'SET_IMAGE_RESULT',
    image,
  };
}
export function setSignatureResult(signature: any) {
  return {
    type: 'SET_SIGNATURE_RESULT',
    signature,
  };
}
export function clearSearchResultDocument() {
  return {
    type: 'CLEAR_PROPERTIES_SEARCH_RESULT',
  };
}
