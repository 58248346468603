export const statusDelivery = [
  { value: "Success", label: "Diterima" },
  { value: "Return", label: "Dikembalikan" },
];

export const nameFiles = [
  { value: "waybill", label: "Waybill" },
  { value: "barcode", label: "Barcode" },
  { value: "polis", label: "Kode Unik (No. Polis / No. Ref / No. Rek)" },
];

export const formatReports = [
  { name: "EXCEL", id: "Excel", format: "xlsx" },
  { name: "CSV", id: "CSV", format: "csv" },
  { name: "TXT", id: "TXT", format: "txt" },
];

export const productTypes = [
  { id: "Card", name: "Kartu" },
  { id: "Policy", name: "Polis" },
  { id: "Letter", name: "Surat" },
  { id: "Others", name: "Lainnya" },
];

export const optionsFilterDelivery = [
  { label: "Waybill", value: "waybill" },
  { label: "Barcode", value: "barcode" },
  { label: "No Polis", value: "NomerPolis" },
  { label: "No Rekening", value: "NomerRekening" },
  { label: "No Referensi", value: "NomerReferensi" },
];
