import {
  SM_createComplaint,
  SM_getDocumentList,
} from './Complaint/SagaAddComplaint';
import { SM_customerId, SM_login, SM_loginTokenRefresh } from './Login';
import {
  SM_getCustomerProduct,
  SM_getReportType,
  SM_report,
} from './Report/SagaReport';
import {
  SM_searchComplaintTicket,
  SM_searchComplaintWbOrTk,
} from './Complaint/SagaComplaint';
import {
  SM_detailComplaint,
} from './Complaint/SagaDetailComplaint';
import {
  SM_updateComplaint,
} from './Complaint/SagaUpdateComplaint';
import { all, fork } from 'redux-saga/effects';

import { SM_searchDocument } from './SearchDocument/SagaSearchDocument';

export default function* () {
  yield all([
    fork(SM_login),
    fork(SM_loginTokenRefresh),
    fork(SM_customerId),
    fork(SM_searchDocument),
    fork(SM_report),
    fork(SM_getCustomerProduct),
    fork(SM_getReportType),
    fork(SM_searchComplaintTicket),
    fork(SM_searchComplaintWbOrTk),
    fork(SM_getDocumentList),
    fork(SM_createComplaint),
    fork(SM_detailComplaint),
    fork(SM_updateComplaint),
  ]);
}
