import * as ActionComplaint from '../../../../core/action/Complaint/ActionComplaint'

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import IndexAddComponent from '../../../../components/Content/Complaint/AddComplaint/IndexAddComponent';
import { connect } from 'react-redux';

interface IProps {
  getComplaintStep: any;
  actionComplaint: any;
  isLoading: any;
}

class IndexComplaintContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
  }
  handleBack() {
    if (this.props.getComplaintStep===1) {
      this.props.actionComplaint.clearDetailDocumentComplaint();
      this.props.actionComplaint.changeComplaintStep(0);
    }else if(this.props.getComplaintStep===0){
      this.props.actionComplaint.clearSearchFormComplaint();
      this.props.actionComplaint.clearComplaintProcess();
    }
    this.props.actionComplaint.changeComplaintTitle(null);
    this.props.actionComplaint.changeCustDetail(null);
  }
  render() {
    return (
      <React.Fragment>
        <IndexAddComponent getComplaintStep={this.props.getComplaintStep}
        handleBack={this.handleBack}
        isLoading={this.props.isLoading}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  getComplaintStep: state.Complaint.propertiesComplaint.complaintStep,
  isLoading: state.Toggle.isLoading,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionComplaint: bindActionCreators(ActionComplaint,dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(IndexComplaintContainer);
