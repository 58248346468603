import { Modal } from 'antd';
import axios from 'axios';
import React from 'react';
import {
    call,
    put,
    select,
    takeLatest
} from 'redux-saga/effects';
import * as actionComplaint from '../../action/Complaint/ActionComplaint';
import * as ActionLogin from '../../action/Login';
import * as toggle from '../../action/toggle/index';

export function* S_updateComplaint(action:any)
{
    const state = yield select()
        const idComplaint = state.Complaint.detailComplaint.detail;
        const getUsername = state.Auth.UsernameAuth;
        const mode = state.Complaint.updateComplaint.mode;
        const desc = state.Complaint.updateComplaint.description;
        const fileImage = state.Complaint.updateComplaint.fileImage;
        const token = state.Auth.Token
        const config ={
            Authorization: `bearer ${token}`,
            'Content-type': 'application/json',
        }
    try
    {
        yield put(toggle.changeLoadingTrue())
        yield call(
            axios.put,process.env.REACT_APP_URL+'/Portal/ConfirmFeedbackForTicketComplaintRequestedByCustomer/'+idComplaint?.id+'/'+getUsername,
            {
                mode: mode,
                description: desc,
                image: fileImage,
            },
            {headers:config}
        )
        yield put(actionComplaint.clearComplaintProcess());
        yield put(actionComplaint.changeComplaintStep(0));
        yield put(actionComplaint.clearFormDetailD());
        yield put(actionComplaint.clearFormUpdate());
        yield put(actionComplaint.clearSearchFormComplaint());
        yield put(actionComplaint.clearComplaintForm());
        Modal.success({
            title: (
              <p style={{ color: '#31E116' }}>
                <b>Data berhasil diinput</b>
              </p>
            ),
            content: (
              <div>
                <p style={{ color: '#C0C0C0' }}>
                    {mode === true ? 'ticket keluhan telah selesai' : 'status telah diupdate menjadi proses'}
                </p>
              </div>
            ),
            onOk() {},
          });
        yield put(toggle.changeLoadingFalse())
    } catch (error) {
        if (error.response.status === 401) {
            yield put(ActionLogin.submitTokenRefresh());
            yield put(toggle.changeLoadingFalse());
        } else {
            Modal.error({
            title: (
                <p style={{ color: '#FF0000' }}>
                    {'Data keluhan gagal diinput'}
                </p>
            ),
            content: (
                <div>
                <p style={{ color: '#C0C0C0' }}>
                    {error?.response?.data?.Message}
                </p>
                </div>
            ),
            onOk() {},
            });
            yield put(toggle.changeLoadingFalse());
        }
    }
}

export function* SM_updateComplaint()
{
    yield takeLatest("SUBMIT_UPDATE_COMPLAINT", S_updateComplaint)
}