import { Layout } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import FooterComponent from "../../components/Footer/FooterComponent";
import HeaderContainer from "../../containers/Header/HeaderContainer";

const { Footer, Content } = Layout;

export function TemplateDashboardComponent(ComponentComposed:any) {
interface IProps
{
    jenisToggle:any
    isLoading:any
}
    class ComposedComponent extends Component<IProps> {
        render() {
            return (
                        <Layout style={{ minHeight: '100vh' }}>
                            <HeaderContainer/>
                                    <Content><ComponentComposed/></Content>
                            <Footer><FooterComponent /></Footer>
                        </Layout>
            )
        }
    }
    const mapStateToProps = (state:any) => ({
        jenisToggle: state.Toggle.jenisToggle,
        isLoading: state.Toggle.isLoading,
      })
      const withConnect = connect(mapStateToProps)
      
      return compose(
          withConnect
      )(ComposedComponent)
    // return ComposedComponent
}
