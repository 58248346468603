import { ArrowUpOutlined } from "@ant-design/icons";
import { BackTop, Steps } from "antd";
import React, { Component } from "react";
import DataWaybillContainer from "../../../../containers/Content/Complaint/DataWaybillContainer";
import HistoryComplaintContainer from "../../../../containers/Content/Complaint/HistoryComplaintContainer";
import DetailFormContainer from "../../../../containers/Content/Complaint/DetailComplaint/DetailFormContainer";
import ButtonComponent from "../../../../materials/ButtonMaterial";
import SpinComponent from "../../../../materials/SpinMaterial";

const { Step } = Steps;

interface IProps {
  getDetail: any;
  getHistory: any;
  handleBack: any;
  isLoading: any;
}

export default class IndexDetailComponent extends Component<IProps> {
  render() {
    const showBackButton = () => {
      return (
        <ButtonComponent
          onClick={this.props.handleBack}
          type="link"
          className={"btnBackComplaintLink"}
        >
          {"< Kembali"}
        </ButtonComponent>
      );
    };
    return (
      <div>
        <SpinComponent spinning={this.props.isLoading} size={"large"}>
          {showBackButton()}
          <div className="centerContentAddComplaint">
            <p className="titleCardComplaint">DETAIL KELUHAN</p>
          </div>
          <DataWaybillContainer getDetailDocument={this.props.getDetail} />
          <DetailFormContainer />
          {this.props.getHistory.length !== 0 && (
            <div className="leftContentHistoryComplaint">
              <p className="titleCardComplaint">RIWAYAT KELUHAN</p>
            </div>
          )}
          <Steps
            current={this.props.getHistory.length}
            progressDot
            direction="vertical"
            className="stepHistoryComplaint"
          >
            {this.props.getHistory.map((data: any) => (
              <Step
                description={
                  <HistoryComplaintContainer
                    getDateTime={data.date}
                    getDesc={data.description}
                    getUser={data.userActor}
                    getImage={data.image}
                    getSignature={data.signature}
                    getLocation={{
                      latitude: data.latitude || "",
                      longitude: data.longitude || "",
                    }}
                  />
                }
              />
            ))}
          </Steps>
          <BackTop>
            <ButtonComponent shape="circle" icon={<ArrowUpOutlined />} />
          </BackTop>
        </SpinComponent>
      </div>
    );
  }
}
