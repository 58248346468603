import React from "react";
import { Select, Form } from "antd";
import styles from "./SelectField.module.css";

type SelectFieldProps = {
  label?: string;
  placeholder?: string;
  data?: Array<any>;
  mode?: "multiple" | "tags" | undefined;
  error?: string;
  onChange?: (value: any) => void;
  onBlur?: () => void;
  value?: any;
  isLoading?: boolean;
  allowClear?: boolean;
  style?: any;
};

export default function SelectField({
  label,
  placeholder,
  data = [],
  mode = undefined,
  error,
  onChange = () => undefined,
  onBlur = () => undefined,
  value,
  isLoading,
  allowClear = false,
  style,
}: SelectFieldProps) {
  return (
    <div className={styles.wrapperField}>
      {label && <p className={styles.labelField}>{label}</p>}
      <Form.Item
        style={{ margin: 0 }}
        validateStatus={error ? "error" : undefined}
      >
        <Select
          placeholder={placeholder}
          options={data}
          style={{ borderRadius: 100, ...style }}
          mode={mode}
          onChange={(value) => onChange(value)}
          onBlur={onBlur}
          value={value}
          loading={isLoading}
          allowClear={allowClear}
        />
      </Form.Item>
      {error && <p className={styles.labelError}>{error}</p>}
    </div>
  );
}
