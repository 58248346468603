import * as Report from '../../../core/action/Report/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import ReportComponent from '../../../components/Content/Report/ReportComponent';
import { connect } from 'react-redux';
import moment from 'moment';

interface IProps {
  isLoading: any;
  GetCycleDateStart: any;
  GetCycleDateFinish: any;
  GetShowFilter: any;
  GetDeliveryStatus: any;
  GetCustomerProduct: any;
  GetReportType: any;
  getChecboxData: any;
  ListCustomerProduct: any;
  ListReportType: any;
  LoadingCustomerProduct: any;
  LoadingReportType: any;
  actionReport: any;
}
interface IState {
  ValidateCycleDateStart: any;
  ValidateCycleDateStart2: any;
  ValidateCycleDateFinish: any;
  ValidateCycleDateFinish2: any;
  ValidateDeliveryStatus: any;
  ValidateReportType: any;
  ValidateCustomerProduct: any;
}
class ReportContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateCycleDateStart: null,
      ValidateCycleDateStart2: null,
      ValidateCycleDateFinish: null,
      ValidateCycleDateFinish2: null,
      ValidateDeliveryStatus: null,
      ValidateReportType: null,
      ValidateCustomerProduct: null,
    };
    this.handlePutCycleDateStart = this.handlePutCycleDateStart.bind(this);
    this.handlePutCycleDateFinish = this.handlePutCycleDateFinish.bind(this);
    this.handlePutDeliveryStatus = this.handlePutDeliveryStatus.bind(this);
    this.handleBlurDeliveryStatus = this.handleBlurDeliveryStatus.bind(this);
    this.handleValidateCycleDateStart =
      this.handleValidateCycleDateStart.bind(this);
    this.handleValidateCycleDateFinish =
      this.handleValidateCycleDateFinish.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleShowFilter = this.handleShowFilter.bind(this);
    this.handlePutCustomerProduct = this.handlePutCustomerProduct.bind(this);
    this.handleBlurCustomerProduct = this.handleBlurCustomerProduct.bind(this);
    this.handleGetCustomerProduct = this.handleGetCustomerProduct.bind(this);
    this.handleResetCustomerProduct =
      this.handleResetCustomerProduct.bind(this);
    this.handlePutReportType = this.handlePutReportType.bind(this);
    this.handleBlurReportType = this.handleBlurReportType.bind(this);
    this.handleGetReportType = this.handleGetReportType.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  handleValidateCycleDateStart(event: any) {
    this.setState({
      ValidateCycleDateStart2: event,
    });

    if (event === true) {
      if (
        this.props.GetCycleDateStart === null ||
        this.props.GetCycleDateStart === undefined ||
        this.props.GetCycleDateStart === 'Invalid date'
      ) {
        this.setState({
          ValidateCycleDateStart: 'error',
        });
      } else {
        this.setState({
          ValidateCycleDateStart: '',
        });
      }
    } else {
    }
  }

  handlePutCycleDateStart(event: any) {
    this.props.actionReport.changeCycleDateStart(event);
    const day =
      Date.parse(moment(event).format('DD-MM-YYYY HH:mm:ss')) -
      this.props.GetCycleDateFinish;

    var periods = {
      month: 30 * 24 * 60 * 60 * 1000,
      week: 7 * 24 * 60 * 60 * 1000,
      day: 60 * 60 * 24 * 1000,
      hour: 60 * 60 * 1000,
      minute: 60 * 1000,
    };
    if (this.props.GetCycleDateFinish !== null) {
      if (day > periods.month) {
        this.props.actionReport.changeCycleDateFinish(event);
      } else if (day > periods.week) {
        this.props.actionReport.changeCycleDateFinish(event);
      } else if (day > periods.day) {
        this.props.actionReport.changeCycleDateFinish(event);
      } else if (day > periods.hour) {
        this.props.actionReport.changeCycleDateFinish(event);
      } else if (day > periods.minute) {
        this.props.actionReport.changeCycleDateFinish(event);
      } else {
      }
    } else {
    }

    if (event === '') {
      this.setState({
        ValidateCycleDateStart: 'error',
      });
    } else {
      this.setState({
        ValidateCycleDateStart: '',
      });
    }
  }

  handlePutCycleDateFinish(event: any) {
    this.props.actionReport.changeCycleDateFinish(event);

    if (event === '') {
      this.setState({
        ValidateCycleDateFinish: 'error',
      });
    } else {
      this.setState({
        ValidateCycleDateFinish: '',
      });
    }
  }

  handleValidateCycleDateFinish(event: any) {
    if (event === true) {
      if (
        this.props.GetCycleDateFinish === null ||
        this.props.GetCycleDateFinish === undefined ||
        this.props.GetCycleDateFinish === 'Invalid date'
      ) {
        this.setState({
          ValidateCycleDateFinish: 'error',
        });
      } else {
        this.setState({
          ValidateCycleDateFinish: '',
        });
      }
    } else {
    }
  }

  handlePutDeliveryStatus(event: any) {
    this.props.actionReport.changeDeliveryStatus(event);

    if (event === null || event.length === 0 || event === []) {
      this.setState({
        ValidateDeliveryStatus: 'error',
      });
    } else {
      this.setState({
        ValidateDeliveryStatus: null,
      });
    }
  }

  handleBlurDeliveryStatus() {
    if (
      this.props.GetDeliveryStatus === null ||
      this.props.GetDeliveryStatus.length === 0 ||
      this.props.GetDeliveryStatus === []
    ) {
      this.setState({
        ValidateDeliveryStatus: 'error',
      });
    } else {
      this.setState({
        ValidateDeliveryStatus: null,
      });
    }
  }

  handleGetCustomerProduct() {
    this.props.actionReport.getCustomerProduct();
  }

  handlePutCustomerProduct(event: any) {
    const searchSelectAll = event.toString().search('SetAllProduct');
    if (searchSelectAll >= 0) {
      this.props.actionReport.changeCustomerProductId(
        this.props.ListCustomerProduct.map((data: any, index: any) => data.id)
      );
    } else {
      this.props.actionReport.changeCustomerProductId(event);
    }
    if (
      this.props.GetCustomerProduct.length === 0 ||
      this.props.GetCustomerProduct === []
    ) {
      this.setState({
        ValidateCustomerProduct: 'error',
      });
    } else {
      this.setState({
        ValidateCustomerProduct: null,
      });
    }
  }

  handleBlurCustomerProduct() {
    if (
      this.props.GetCustomerProduct.length === 0 ||
      this.props.GetCustomerProduct === []
    ) {
      this.setState({
        ValidateCustomerProduct: 'error',
      });
    } else {
      this.setState({
        ValidateCustomerProduct: null,
      });
    }
  }

  handleResetCustomerProduct() {
    // this.props.actionReport.clearCustomerProduct();
    // this.setState({
    //   ValidateCustomerProduct: null,
    // });
  }

  handleGetReportType() {
    this.props.actionReport.getReportType();
  }

  handlePutReportType(event: any) {
    this.props.actionReport.changeReportTypeId(event);

    if (event === null) {
      this.setState({
        ValidateReportType: 'error',
      });
    } else {
      this.setState({
        ValidateReportType: null,
      });
    }
  }

  handleBlurReportType() {
    if (this.props.GetReportType === null) {
      this.setState({
        ValidateReportType: 'error',
      });
    } else {
      this.setState({
        ValidateReportType: null,
      });
    }
  }

  handleCheckbox(event: any) {
    this.props.actionReport.changeCheckbox(event.target.checked);
  }

  handleDownload() {
    this.props.actionReport.downloadCustomerReport();
  }

  handleReset() {
      this.props.actionReport.clearCustomerReport();
      this.setState({
        ValidateCycleDateStart: null,
        ValidateCycleDateFinish: null,
        ValidateDeliveryStatus: null,
        ValidateCustomerProduct: null,
        ValidateReportType: null,
      });
  }

  async handleShowFilter() {
    this.props.actionReport.changeShowFilter();
    await this.props.actionReport.getCustomerProduct();
    if (this.props.GetShowFilter === false) {
      this.props.actionReport.defaultReportForm();
      this.setState({
        ValidateDeliveryStatus: null,
        ValidateCustomerProduct: null,
        ValidateReportType: null,
      });
    }
    this.props.actionReport.changeCustomerProductId(
      this.props.ListCustomerProduct.map((data: any) => data.id)
    );
  }

  componentDidMount() {
    this.props.actionReport.getCustomerProduct();
    this.props.actionReport.changeReportTypeId(0);
    this.props.actionReport.changeFilter(false);
  }

  render() {
    return (
      <ReportComponent
        isLoading={this.props.isLoading}
        getCycleDateStart={this.props.GetCycleDateStart}
        getCycleDateFinish={this.props.GetCycleDateFinish}
        getDeliveryStatus={this.props.GetDeliveryStatus}
        getCustomerProduct={this.props.GetCustomerProduct}
        getReportType={this.props.GetReportType}
        ListCustomerProduct={this.props.ListCustomerProduct}
        ListReportType={this.props.ListReportType}
        loadingCustomerProduct={this.props.LoadingCustomerProduct}
        loadingReportType={this.props.LoadingReportType}
        handleGetCustomerProduct={this.handleGetCustomerProduct}
        handleGetReportType={this.handleGetReportType}
        handlePutCycleDateStart={this.handlePutCycleDateStart}
        handlePutCycleDateFinish={this.handlePutCycleDateFinish}
        handlePutCustomerProduct={this.handlePutCustomerProduct}
        handlePutReportType={this.handlePutReportType}
        handlePutDeliveryStatus={this.handlePutDeliveryStatus}
        handleBlurDeliveryStatus={this.handleBlurDeliveryStatus}
        handleBlurCustomerProduct={this.handleBlurCustomerProduct}
        handleBlurReportType={this.handleBlurReportType}
        handleValidateCycleDateStart={this.handleValidateCycleDateStart}
        handleValidateCycleDateFinish={this.handleValidateCycleDateFinish}
        validateCycleDateStart={
          this.state.ValidateCycleDateStart === 'error' ? 'error' : ''
        }
        validateCycleDateFinish={
          this.state.ValidateCycleDateFinish === 'error' ? 'error' : ''
        }
        validateDeliveryStatus={
          this.state.ValidateDeliveryStatus === 'error' ? 'error' : ''
        }
        validateCustomerProduct={
          this.state.ValidateCustomerProduct === 'error' ? 'error' : ''
        }
        validateReportType={
          this.state.ValidateReportType === 'error' ? 'error' : ''
        }
        validateButton={
          this.props.GetShowFilter === false
            ? this.props.GetCycleDateStart === null ||
              this.props.GetCycleDateStart === '' ||
              this.props.GetCycleDateFinish === null ||
              this.props.GetCycleDateFinish === ''
              ? true
              : false
            : this.props.GetCycleDateStart === null ||
              this.props.GetCycleDateStart === '' ||
              this.props.GetCycleDateFinish === null ||
              this.props.GetCycleDateFinish === '' ||
              this.props.GetDeliveryStatus === null ||
              this.props.GetDeliveryStatus === '' ||
              this.props.GetDeliveryStatus === [] ||
              this.props.GetDeliveryStatus.length === 0 ||
              this.props.GetCustomerProduct.length === 0 ||
              this.props.GetCustomerProduct === [] ||
              this.props.GetReportType === null
            ? true
            : false
        }
        validateButtonReset={
          this.props.GetShowFilter === false
            ? this.props.GetCycleDateStart === null &&
              this.props.GetCycleDateFinish === null
              ? true
              : false
            : this.props.GetCycleDateStart === null ||
              this.props.GetCycleDateFinish === null ||
              this.props.GetDeliveryStatus === null ||
              this.props.GetCustomerProduct === [] ||
              this.props.GetCustomerProduct.length === 0 ||
              this.props.GetReportType === null
            ? true
            : false
        }
        helpCycleDateStart={
          this.state.ValidateCycleDateStart === 'error'
            ? 'Tanggal cycle wajib diisi'
            : ''
        }
        helpCycleDateFinish={
          this.state.ValidateCycleDateFinish === 'error'
            ? 'Tanggal cycle wajib diisi'
            : ''
        }
        helpDeliveryStatus={
          this.state.ValidateDeliveryStatus === 'error'
            ? 'Status pengiriman wajib diisi'
            : ''
        }
        helpValidateCustomerProduct={
          this.state.ValidateCustomerProduct === 'error'
            ? 'Produk wajib diisi'
            : ''
        }
        helpValidateReportType={
          this.state.ValidateReportType === 'error'
            ? 'Jenis laporan wajib diisi'
            : ''
        }
        handleDownload={this.handleDownload}
        handleReset={this.handleReset}
        handleResetCustomerProduct={this.handleResetCustomerProduct}
        handleCheckbox={this.handleCheckbox}
        handleShowFilter={this.handleShowFilter}
        getShowFilter={this.props.GetShowFilter}
        getChecboxData={this.props.getChecboxData}
      />
    );
  }
}
const mapStateToProps = (state: any) => ({
  GetCycleDateStart: state.Report.CustomerReportForm.CycleDateStart,
  GetCycleDateFinish: state.Report.CustomerReportForm.CycleDateFinish,
  GetShowFilter: state.Report.CustomerReportForm.Filter,
  GetDeliveryStatus: state.Report.CustomerReportForm.DeliveryStatus,
  GetCustomerProduct: state.Report.CustomerReportForm.CustomerProductId,
  GetReportType: state.Report.CustomerReportForm.ReportTypeId,
  getChecboxData: state.Report.CustomerReportForm.ChecboxData,
  ListCustomerProduct:
    state.Report.PropertiesCustomerReportForm.ListCustomerProduct,
  ListReportType: state.Report.PropertiesCustomerReportForm.ListReportType,
  LoadingCustomerProduct:
    state.Report.PropertiesCustomerReportForm.LoadingCustomerProduct,
  LoadingReportType:
    state.Report.PropertiesCustomerReportForm.LoadingReportType,
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionReport: bindActionCreators(Report, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ReportContainer);
