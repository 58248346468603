import { Form, Input } from "antd";
import React, { Component } from "react";

interface IProps {
  style?: any;
  disabled?: any;
  className?: any;
  placeholder?: any;
  maxLength?: any;
  value?: any;
  onChange?: any;
  onBlur?: any;
  addonBefore?: any;
  prefix?: any;
  size?: any;
  type?: any;
  validatestatus: any;
  help: any;
  validateType: any;
  onPressEnter?: any;
  formStyle?: any;
  rows?: any;
}

const { TextArea } = Input;

export default class FormInputTextMaterial extends Component<IProps> {
  render() {
    return this.props.validateType === true ? (
      <Form.Item
        validateStatus={this.props.validatestatus}
        help={this.props.help}
        style={this.props.formStyle}
      >
        {this.props.type === "password" ? (
          <Input.Password {...this.props}></Input.Password>
        ) : this.props.type === "textArea" ? (
          <TextArea {...this.props}></TextArea>
        ) : (
          <Input {...this.props}></Input>
        )}
      </Form.Item>
    ) : (
      <Input {...this.props} />
    );
  }
}
