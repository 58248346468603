export function changeValueHeader(ValueHeader: any) {
  return {
    type: 'CHANGE_VALUE_HEADER',
    ValueHeader,
  };
}
export function clearValueHeader() {
  return {
    type: 'CLEAR_VALUE_HEADER',
  };
}
