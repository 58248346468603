import React, { Component } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Navigation from "../navigation";
import history from "../core/utils/History";
import { Provider } from "react-redux";
import { store, persistor } from "../core/store/ConfigStore";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";

export default class App extends Component {
  render() {
    const queryClient = new QueryClient();
    return (
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <PersistGate persistor={persistor}>
            <ConnectedRouter history={history}>
              <Navigation />
            </ConnectedRouter>
          </PersistGate>
        </QueryClientProvider>
      </Provider>
    );
  }
}
