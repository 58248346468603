import * as AcHeader from '../../action/Header/index';
import * as ClearAuth from '../../action/Logout/index';
import * as actionComplaint from '../../action/Complaint/ActionComplaint'
import * as clear from '../../action/Login/index';
import * as customer from '../../action/Login/index';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeAccessContent,
  changeAccessHeader,
  changeCustomerId,
  changeToken,
  changeTokenRefresh,
  changeUsernameAuth,
} from '../../action/Auth';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import history from '../../utils/History';
import { notification } from "antd";

export function* S_loginProcess() {
  try {
    yield put(toggle.changeLoadingTrue());
    const state = yield select();
    const usernameLogin = state.Login.loginForm.Username;
    const passwordLogin = state.Login.loginForm.Password;

    const response = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Login',
      {
        username: usernameLogin,
        password: passwordLogin,
      }
    );
    
    yield put(changeToken(response.data.token));
    yield put(changeTokenRefresh(response.data.tokenRefresh));
    yield put(changeUsernameAuth(usernameLogin));
    yield put(toggle.changeLoadingFalse());

    if (response !== null) {
      Modal.success({
        title: (
          <p style={{ color: '#31E116' }}>
            <b>Berhasil masuk</b>
          </p>
        ),
        content: (
          <div>
            <p style={{ color: 'black' }}>
              Anda sekarang adalah {usernameLogin}
            </p>
          </div>
        ),
        onOk() {},
      });
      yield put(customer.submitCustomerId());
    }
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    if (error.response.status === 500) {
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            Nama Pengguna atau Sandi anda salah
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>Silahkan coba lagi</p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}
export function* S_loginTokenRefresh() {
  const openNotification = () => {
    notification.warning({
      message: 'Sesi berakhir',
      description:
        'Silahkan login kembali',
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };
  try {
    
    const state = yield select();
    const token = state.Auth.Token;
    const tokenRefresh = state.Auth.TokenRefresh;

    const {data} = yield call(
      axios.post,
      'https://mobile.dev.quadrant-si.id/smart-courier/Token/refresh/'+tokenRefresh,
      `"${token}"`,
      { headers: { 'Content-Type': 'application/json' } }
    );
    
    yield put(changeToken(data.token));
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    if (error.response.status === 500) {
      yield put(toggle.clearJenisToggle());
      yield put(ClearAuth.clearToken());
      yield put(ClearAuth.clearTokenRefresh());
      yield put(ClearAuth.clearUsernameAuth());
      yield put(ClearAuth.clearCustomerId());
      yield put(ClearAuth.changeClearAccessControl())
      yield put(AcHeader.clearValueHeader());
      yield put(actionComplaint.clearAllComplaintForm());
      openNotification();
    }
  }
}
export function* S_customerIdProcess() {
  try {
    const state = yield select();
    const token = state.Auth.Token;
    const usernameLogin = state.Login.loginForm.Username;
    const config = {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    };
    const {data} = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Profile/initial/' + usernameLogin,
      { headers: config }
    );
    const headerValue = data.accessHeader[0];
    yield put(changeAccessHeader(data.accessHeader));
    yield put(changeAccessContent(data.accessContent));
    yield put(changeCustomerId(data.profile.customerId));
    yield put(AcHeader.changeValueHeader(headerValue));
    if (headerValue === 1) {
      history.push('/');
    }
    else if (headerValue === 2) {
      history.push('/report');
    }
    else if (headerValue === 3) {
      history.push('/complaint');
    }
    else {
      history.push('/notfound')
    }
    yield put(clear.changeClearUsername());
    yield put(clear.changeClearPassword());
  } catch (error) {
    if (error.response.status === 500) {
    }
  }
}

export function* SM_login() {
  yield takeLatest('LOGIN_REQUESTED', S_loginProcess);
}
export function* SM_loginTokenRefresh() {
  yield takeLatest('REQUEST_TOKEN_REFRESH', S_loginTokenRefresh);
}
export function* SM_customerId() {
  yield takeLatest('CUSTOMER_ID_REQUESTED', S_customerIdProcess);
}
