import { Col, Row } from "antd";
import React, { Component } from "react";
import CardMaterial from "../../../materials/CardMaterial";

interface IProps {
  getDetailDocument: any;
  getComplaintProcess: any;
}

export default class DataWaybillComponent extends Component<IProps> {
  render() {
    const detailDocumentValue =
      this.props.getDetailDocument === null ? "" : this.props.getDetailDocument;

    const address =
      detailDocumentValue?.shipToAddress === null ||
      detailDocumentValue?.shipToAddress === undefined
        ? ""
        : `${Object.values(JSON.parse(detailDocumentValue?.shipToAddress)).join(
            " "
          )}`;
    const renderData = (label: any, content: any) => {
      return (
        <>
          <Col span={6}>
            <div className="detailLabelDataComplaint">
              <span>{label}</span>
            </div>
          </Col>
          <Col span={18}>
            <div className="detailValueDataComplaint">
              <span style={{ wordWrap: "break-word" }}>
                {": " + content === null || content === undefined
                  ? "-"
                  : content}
              </span>
            </div>
          </Col>
        </>
      );
    };
    return (
      <div
        className={
          this.props.getComplaintProcess === "detailComplaint" ||
          this.props.getComplaintProcess === "updateComplaint"
            ? "spacingCardComplaint"
            : ""
        }
      >
        <CardMaterial
          className={"cardComplaintForm"}
          extra={""}
          title={<span className={"titleCardComplaintForm"}>Data Waybill</span>}
          style={{}}
          content={
            <div>
              <Row>
                {renderData("Waybill", detailDocumentValue?.waybillCode)}
                {renderData("Tanggal Cycle", detailDocumentValue?.cycle)}
                {renderData("Barcode", detailDocumentValue?.barcode)}
                {renderData("Alamat", address)}
                {renderData("Nama Pemilik", detailDocumentValue?.shipToName)}
                {renderData(
                  "No Telp",
                  detailDocumentValue?.shipToPhoneCustomers === null ||
                    detailDocumentValue?.shipToPhoneCustomers === ""
                    ? "-"
                    : detailDocumentValue?.shipToPhoneCustomers
                )}
              </Row>
            </div>
          }
        />
      </div>
    );
  }
}
