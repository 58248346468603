export function changeCycleDateStart(CycleDateStart: any) {
  return {
    type: 'CHANGE_CYCLE_DATE_START',
    CycleDateStart,
  };
}

export function changeCycleDateFinish(CycleDateFinish: any) {
  return {
    type: 'CHANGE_CYCLE_DATE_FINISH',
    CycleDateFinish,
  };
}

export function changeFilter(Filter: any) {
  return {
    type: 'CHANGE_FILTER',
    Filter,
  };
}

export function changeShowFilter() {
  return {
    type: 'CHANGE_SHOW_FILTER',
  };
}

export function changeListCustomerProduct(ListCustomerProduct: any) {
  return {
    type: 'CHANGE_LIST_CUSTOMER_PRODUCT',
    ListCustomerProduct,
  };
}

export function changeCustomerProductId(CustomerProductId: any) {
  return {
    type: 'CHANGE_CUSTOMER_PRODUCT_ID',
    CustomerProductId,
  };
}

export function changeListReportFile(ListReportType: any) {
  return {
    type: 'CHANGE_LIST_REPORT_TYPE',
    ListReportType,
  };
}

export function changeReportTypeId(ReportTypeId: any) {
  return {
    type: 'CHANGE_REPORT_TYPE_ID',
    ReportTypeId,
  };
}

export function changeDeliveryStatus(DeliveryStatus: any) {
  return {
    type: 'CHANGE_DELIVERY_STATUS',
    DeliveryStatus,
  };
}
export function changeCheckbox(ChecboxData: any) {
  return {
    type: 'CHANGE_CHECKBOX_DATA',
    ChecboxData,
  };
}

export function setLoadingCustomerProduct() {
  return {
    type: 'SET_LOADING_CUSTOMER_PRODUCT',
  };
}

export function setLoadingReportType() {
  return {
    type: 'SET_LOADING_REPORT_TYPE',
  };
}

export function clearLoadingCustomerProduct() {
  return {
    type: 'CLEAR_LOADING_CUSTOMER_PRODUCT',
  };
}

export function clearLoadingReportType() {
  return {
    type: 'CLEAR_LOADING_REPORT_TYPE',
  };
}

export function clearCustomerReport() {
  return {
    type: 'CLEAR_CUSTOMER_REPORT',
  };
}

export function getCustomerProduct() {
  return {
    type: 'GET_CUSTOMER_PRODUCT',
  };
}

export function getReportType() {
  return {
    type: 'GET_REPORT_TYPE',
  };
}

export function defaultReportForm() {
  return {
    type: 'DEFAULT_REPORT_FORM',
  };
}
export function clearCustomerProduct() {
  return {
    type: 'CLEAR_CUSTOMER_PRODUCT',
  };
}

export function clearReportType() {
  return {
    type: 'CLEAR_REPORT_TYPE',
  };
}

export function clearCustomerReportForm() {
  return {
    type: 'CLEAR_CUSTOMER_REPORT_FORM',
  };
}

export function downloadCustomerReport() {
  return {
    type: 'DOWNLOAD_CUSTOMER_REPORT',
  };
}
