/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import "../../styles/HeaderStyle/index.css";

import { Affix, Avatar, Button, Col, Dropdown, Layout, Menu, Row } from "antd";
import { ExportOutlined, UserOutlined } from "@ant-design/icons";
import React, { Component } from "react";

import { EnumAccessControl } from "../../core/enumerable/General";
import iconHeaderCCC from "../../image/iconHeaderCCC.png";

const { Header } = Layout;

interface IProps {
  handleClearAuth: any;
  handleModalLogout: any;
  handleActiveButtonReport: any;
  handleActiveButtonHome: any;
  handleActiveButtonComplaint: any;
  handleActiveWaybillDigital: any;
  GetUsername: any;
  GetAccessHeader: any;
  GetValueHeader: any;
  isLoading: any;
}

export default class HeaderComponent extends Component<IProps> {
  render() {
    const getHeader =
      this.props.GetAccessHeader.length === 0
        ? [0]
        : this.props.GetAccessHeader;
    const menu = (
      <Menu>
        <Menu.Item>
          <Button type="text">
            <UserOutlined />
            {this.props.GetUsername}
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button type="text" onClick={this.props.handleModalLogout}>
            <ExportOutlined />
            Keluar
          </Button>
        </Menu.Item>
      </Menu>
    );
    return (
      <div>
        <Affix offsetTop={0}>
          <Header className="Gradient">
            <Row>
              <Col span={24}>
                <img className="iconCCC" src={iconHeaderCCC} />
                <span className="titleHeader">
                  <b>Courier</b>Command<b>Center</b> Portal
                </span>
                <div className="floatRight">
                  {getHeader.map(
                    (value: any) =>
                      value === 1 && (
                        <Button
                          className={
                            this.props.GetValueHeader === 1
                              ? "btn-Header-isActive"
                              : "btn-Header"
                          }
                          onClick={this.props.handleActiveButtonHome}
                        >
                          {EnumAccessControl.map(
                            (data: any) => data.value === value && data.label
                          )}
                        </Button>
                      )
                  )}
                  {getHeader.map(
                    (value: any) =>
                      value === 2 && (
                        <Button
                          className={
                            this.props.GetValueHeader === 2
                              ? "btn-Header-isActive"
                              : "btn-Header"
                          }
                          onClick={this.props.handleActiveButtonReport}
                        >
                          {EnumAccessControl.map(
                            (data: any) => data.value === value && data.label
                          )}
                        </Button>
                      )
                  )}
                  {getHeader.map(
                    (value: any) =>
                      value === 4 && (
                        <Button
                          className={
                            this.props.GetValueHeader === 4
                              ? "btn-Header-isActive"
                              : "btn-Header"
                          }
                          onClick={this.props.handleActiveWaybillDigital}
                        >
                          {EnumAccessControl.map(
                            (data: any) => data.value === value && data.label
                          )}
                        </Button>
                      )
                  )}
                  {getHeader.map(
                    (value: any) =>
                      value === 3 && (
                        <Button
                          className={
                            this.props.GetValueHeader === 3
                              ? "btn-Header-isActive"
                              : "btn-Header"
                          }
                          onClick={this.props.handleActiveButtonComplaint}
                        >
                          {EnumAccessControl.map(
                            (data: any) => data.value === value && data.label
                          )}
                        </Button>
                      )
                  )}

                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Button type="text" className="ant-dropdown-link">
                      <Avatar className="avatarProfile">
                        <div className="spacingBottom">
                          <UserOutlined className="iconProfile" />
                        </div>
                      </Avatar>
                    </Button>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </Header>
        </Affix>
      </div>
    );
  }
}
